<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>{{ $t('menu.sumAdjustment') }}</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <app-table
          server-side
          model="task"
          app="audit"
          :headers="adjustmentLinesFromSubtasksHeaders"
          hide-edit
          hide-delete
          hide-default-footer
          :is-selecteable="false"
          :server-items="adjustmentLinesFromSubtasks"
          :server-items-length="adjustmentLinesFromSubtasks.length"
          @server="getAdjustmentLinesFromSubtasks"
        >
          <template v-slot:foot>
            <tfoot>
              <tr>
                <td></td>
                <td></td>
                <td style="text-align: right">
                  <strong>Total</strong>
                </td>
                <td></td>
                <td>{{ adjustmentLinesFromSubtasksTotalDebit }}</td>
                <td>{{ adjustmentLinesFromSubtasksTotalCredit }}</td>
                <td></td>
              </tr>
            </tfoot>
          </template>
        </app-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import AppTable from '@components/AppTable.vue'
import { defaultTableParams } from '@utils/app-table'
import { accounting } from '@utils/number-format'
import _ from 'lodash'

export default {
  name: 'app-sum-adjustment',
  components: {
    AppTable
  },
  props: {
    mode: {
      type: String,
      required: true
    },
    view: {
      type: Boolean
    }
  },
  data() {
    return {
      adjustmentLinesFromSubtasks: [],
      loading: false
    }
  },
  computed: {
    adjustmentLinesFromSubtasksHeaders() {
      return [
        {
          text: this.$t('fields.accountCode'),
          value: 'chart_of_account_detail.code',
          groupable: false,
          sortable: true,
          hideFilter: true
        },
        {
          text: this.$t('fields.accountName'),
          value: 'chart_of_account_detail.name',
          groupable: false,
          sortable: true,
          hideFilter: true
        },
        {
          text: this.$t('fields.referenceTask'),
          value: 'task_adjustment__task__reference',
          groupable: false,
          sortable: true,
          hideFilter: true
        },
        {
          text: this.$t('fields.adjRef'),
          value: 'task_adjustment__ref',
          groupable: false,
          sortable: true,
          hideFilter: true
        },
        {
          text: this.$t('fields.adjDebit'),
          value: 'debit',
          groupable: false,
          sortable: true,
          hideFilter: true
        },
        {
          text: this.$t('fields.adjCredit'),
          value: 'credit',
          groupable: false,
          sortable: true,
          hideFilter: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note',
          groupable: false,
          sortable: true,
          hideFilter: true
        }
      ]
    },
    adjustmentLinesFromSubtasksTotalDebit() {
      return this.privateCalcAdjustmentTotalDebit(
        this.adjustmentLinesFromSubtasks
      )
    },
    adjustmentLinesFromSubtasksTotalCredit() {
      return this.privateCalcAdjustmentTotalCredit(
        this.adjustmentLinesFromSubtasks
      )
    }
  },
  methods: {
    accountingFormat: accounting,
    async getAdjustmentLinesFromSubtasks(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `task/task/${this.$route.params.id}/adjustment/line_from_subtask/`,
          hideSuccessAlert: true,
          params: params
        })
        this.adjustmentLinesFromSubtasks = data
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    privateCalcAdjustmentTotalDebit(lines) {
      return _.sumBy(lines, (line) => parseFloat(line.debit))
    },
    privateCalcAdjustmentTotalCredit(lines) {
      return _.sumBy(lines, (line) => parseFloat(line.credit))
    }
  }
}
</script>
