<template>
  <app-menu-form
    :headers="timesheetItemHeader"
    :server-items="timesheetItemItems"
    :client-items="timesheetItemItems"
    :loading="loading"
    :server-items-length="timesheetItemServerItemsLength"
    :hide-edit="mode !== 'edit'"
    :hide-delete="mode !== 'edit'"
    :events="eventHandlers"
  >
    <template v-slot:top>
      <app-menu-form-top
        title="Timesheet"
        :mode="mode"
        :dialog="dialogTimesheetItem"
        @show-dialog="dialogTimesheetItem = $event"
        @on-delete="onDeleteTimesheetItemItem"
        :selected-length="selected.length"
        dialog-max-width="900px"
      >
        <template v-slot:dialogForm>
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6">
                    <app-input
                      ref="timesheet_audit_plan_member"
                      name="name"
                      type="select"
                      :label="$t('fields.name')"
                      :view="view"
                      v-model="editedTimesheetItemItem.audit_plan_member"
                      :items="memberTimesheetItems"
                    ></app-input>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <app-input
                      ref="timesheet_assigned_by"
                      name="assigned_by"
                      type="select"
                      :label="$t('fields.assignedBy')"
                      :view="view"
                      v-model="editedTimesheetItemItem.assigned_by"
                      :items="memberTimesheetItems"
                    ></app-input
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <app-input
                      ref="timesheet_task_standard_man_day"
                      name="task_standard_man_day"
                      type="select-server"
                      :label="$t('fields.taskPosition')"
                      :view="view"
                      v-model="editedTimesheetItemItem.task_standard_man_day"
                      :binds="{
                        apiUrl: `task/task/${$route.params.id}/standard-man-day/?active=true`,
                        itemText: 'position.name'
                      }"
                    ></app-input
                  ></v-col>
                  <v-col cols="12" sm="6">
                    <app-input
                      name="expectedManDay"
                      :label="$t('fields.assignedManDay')"
                      :view="view"
                      :handleBlur="
                        () =>
                          onTimeHrsBlur(
                            'editedTimesheetItemItem',
                            'expected_man_day',
                            editedTimesheetItemItem.expected_man_day
                          )
                      "
                      :handleKeyDown="onTimeHrsKeyDow"
                      v-model="editedTimesheetItemItem.expected_man_day"
                    ></app-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <app-date-time-picker
                      name="date"
                      :label="$t('fields.assignedDate')"
                      v-model="editedTimesheetItemItem.date"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <app-input
                      name="actualManDay"
                      :label="$t('fields.actualManDay')"
                      :view="view"
                      :handleBlur="
                        () =>
                          onTimeHrsBlur(
                            'editedTimesheetItemItem',
                            'actual_man_day',
                            editedTimesheetItemItem.actual_man_day
                          )
                      "
                      :handleKeyDown="onTimeHrsKeyDow"
                      v-model="editedTimesheetItemItem.actual_man_day"
                    ></app-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <app-date-time-picker
                      name="actual_start_date"
                      :label="$t('fields.actualStartDate')"
                      v-model="editedTimesheetItemItem.actual_start_date"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <app-input
                      name="note"
                      :label="$t('fields.note')"
                      :view="view"
                      v-model="editedTimesheetItemItem.note"
                    ></app-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeTimesheetItem">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="saveTimesheetItem">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
        <template v-slot:foot>
          <tfoot>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                {{ hoursMinutesFormat(timesheetSumAssignedManDay) }}
              </td>
              <td></td>
              <td></td>
              <td>
                {{ hoursMinutesFormat(timesheetSumActualManDay) }}
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tfoot>
        </template>
      </app-menu-form-top>
    </template>
  </app-menu-form>
</template>

<script>
import AppInput from '@components/AppInput.vue'
import AppDateTimePicker from '@components/AppDateTimePicker.vue'
import AppMenuForm from '@components/AppMenuForm.vue'
import AppMenuFormTop from '@components/view/AppMenuFormTop.vue'
import { defaultTableParams } from '@utils/app-table'
import { hoursMinutes } from '@utils/number-format'
import { onTimeHrsKeyDow, onTimeHrsBlur, timeHrsAutoConvert } from '@utils/time'

export default {
  name: 'app-timesheet',
  components: {
    AppInput,
    AppDateTimePicker,
    AppMenuForm,
    AppMenuFormTop
  },
  props: {
    mode: {
      type: String,
      required: true
    },
    view: {
      type: Boolean
    },
    memberTimesheetItems: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selected: [],
      dialogTimesheetItem: false,
      editedTimesheetItemItem: {
        actual_start_date: '',
        date: ''
      },
      timesheetItemItems: [],
      timesheetItemServerItemsLength: 0,
      timesheetSumAssignedManDay: 0,
      timesheetSumActualManDay: 0,
      apiMethod: 'post',
      loading: false
    }
  },
  computed: {
    timesheetItemHeader() {
      return [
        {
          text: this.$t('fields.team'),
          value: 'audit_plan_member.team_id.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.assignedBy'),
          value: 'assigned_by.contact_id.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.taskPosition'),
          value: 'task_standard_man_day.position.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.name'),
          value: 'audit_plan_member.contact_id.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.projectPosition'),
          value: 'audit_plan_member.position.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.assignedManDay'),
          value: 'expected_man_day_converted',
          hideFilter: true
        },
        {
          text: this.$t('fields.weight'),
          value: 'weight',
          hideFilter: true,
          sortable: false,
          percentFormat: true
        },
        {
          text: this.$t('fields.assignedDate'),
          value: 'date',
          hideFilter: true,
          dateTimeFormat: true
        },
        {
          text: this.$t('fields.actualManDay'),
          value: 'actual_man_day_converted',
          hideFilter: true
        },
        {
          text: this.$t('fields.actualStartDate'),
          value: 'actual_start_date',
          hideFilter: true,
          dateTimeFormat: true
        },
        {
          text: this.$t('fields.actualEndDate'),
          value: 'actual_end_date',
          hideFilter: true,
          dateTimeFormat: true
        },
        {
          text: this.$t('fields.variance'),
          value: 'variance',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.taskPerformance'),
          value: 'task_performance',
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.workProgress'),
          value: 'work_progress',
          hideFilter: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note',
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    eventHandlers() {
      return {
        server: this.getTimesheetItemItems,
        delete: this.onDeleteTimesheetItemItem,
        edit: this.onEditTimesheetItem
      }
    }
  },
  methods: {
    hoursMinutesFormat: hoursMinutes,
    onTimeHrsKeyDow: onTimeHrsKeyDow,
    onTimeHrsBlur: onTimeHrsBlur,
    timeHrsAutoConvert: timeHrsAutoConvert,
    async getTimesheetItemItems(options = null) {
      if (
        this.$route.params.id === null ||
        this.$route.params.id === undefined
      ) {
        return
      }
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/timesheet/`,
          hideSuccessAlert: true,
          params: params
        })
        this.timesheetItemItems = data.results.map((item) => ({
          ...item,
          expected_man_day_converted: this.timeHrsAutoConvert(
            item.expected_man_day
          ),
          actual_man_day_converted: this.timeHrsAutoConvert(item.actual_man_day)
        }))
        this.timesheetItemServerItemsLength = data.count
        this.timesheetSumAssignedManDay = data.sum_assigned_man_day
        this.timesheetSumActualManDay = data.sum_actual_man_day
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onDeleteTimesheetItemItem(item) {
      this.onDeleteTimesheetItem(item)
    },
    onDeleteTimesheetItem(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/timesheet`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getTimesheetItemItems()
        })
      })
      this.loading = false
    },
    closeTimesheetItem() {
      this.dialogTimesheetItem = false
      this.$nextTick(() => {
        this.editedTimesheetItemItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
      this.editId = null
      this.apiMethod = 'post'
      this.loading = false
    },
    async saveTimesheetItem() {
      this.loading = true

      const data = {}
      data.audit_plan_header_id = this.$route.query.audit_plan_id

      if (
        this.editedTimesheetItemItem.audit_plan_member !== null &&
        this.editedTimesheetItemItem.audit_plan_member !== undefined
      ) {
        data.audit_plan_member_id =
          this.editedTimesheetItemItem.audit_plan_member
      }
      if (this.editedTimesheetItemItem.assigned_by) {
        data.assigned_by_id = this.editedTimesheetItemItem.assigned_by
      }

      data.expected_man_day = this.timeHrsAutoConvert(
        this.editedTimesheetItemItem.expected_man_day,
        true
      )
      data.actual_man_day = this.timeHrsAutoConvert(
        this.editedTimesheetItemItem.actual_man_day,
        true
      )
      data.date = this.editedTimesheetItemItem.date
      data.actual_start_date = this.editedTimesheetItemItem.actual_start_date
      if (this.editedTimesheetItemItem.task_standard_man_day) {
        data.task_standard_man_day_id =
          this.editedTimesheetItemItem.task_standard_man_day.id
      }
      data.note = this.editedTimesheetItemItem.note

      await this.$api({
        method: this.apiMethod,
        url: `audit/task/${this.$route.params.id}/timesheet/${
          this.editId ? this.editId + '/' : ''
        }`,
        data
      })
      this.loading = false
      this.closeTimesheetItem()
      this.getTimesheetItemItems()
    },
    onEditTimesheetItem(item) {
      this.editedTimesheetItemItem = Object.assign({}, item)
      if (item.audit_plan_member) {
        this.editedTimesheetItemItem.audit_plan_member =
          item.audit_plan_member.id
      }
      if (item.assigned_by) {
        this.editedTimesheetItemItem.assigned_by = item.assigned_by.id
      }
      this.editedTimesheetItemItem.task_standard_man_day =
        item.task_standard_man_day
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialogTimesheetItem = true
      this.editedTimesheetItemItem.expected_man_day = this.timeHrsAutoConvert(
        this.editedTimesheetItemItem.expected_man_day
      )
      this.editedTimesheetItemItem.actual_man_day = this.timeHrsAutoConvert(
        this.editedTimesheetItemItem.actual_man_day
      )
      if (
        this.editedTimesheetItemItem.task_standard_man_day &&
        this.$refs.timesheet_task_standard_man_day
      ) {
        this.$refs.timesheet_task_standard_man_day.setDefaultServerItems(
          item.task_standard_man_day
        )
      }
      // if (
      //   this.editedTimesheetItemItem.audit_plan_member &&
      //   this.$refs.timesheet_audit_plan_member
      // ) {
      //   const opt = this.memberTimesheetItems.find(
      //     (opt) => opt.value === item.audit_plan_member.id
      //   )
      //   if (opt) {
      //     this.$refs.timesheet_audit_plan_member.setDefaultServerItems(opt)
      //   }
      // }
      // if (
      //   this.editedTimesheetItemItem.assigned_by &&
      //   this.$refs.timesheet_assigned_by
      // ) {
      //   const opt = this.memberTimesheetItems.find(
      //     (opt) => opt.value === item.audit_plan_member.id
      //   )
      //   if (opt) {
      //     this.$refs.timesheet_assigned_by.setDefaultServerItems(opt)
      //   }
      // }
    }
    // onTimeHrsKeyDow(event) {
    //   const specialKeys = [
    //     'Backspace',
    //     'Delete',
    //     'Tab',
    //     'Escape',
    //     'Enter',
    //     'ArrowLeft',
    //     'ArrowRight'
    //   ]

    //   if (specialKeys.includes(event.key)) {
    //     return
    //   }

    //   const isDigit = event.key >= '0' && event.key <= '9'
    //   const isColon =
    //     event.key === ':' && event.target.value.indexOf(':') === -1

    //   if (!isDigit && !isColon) {
    //     event.preventDefault()
    //   }
    // },
    // onTimeHrsBlur(objName, field, value) {
    //   this.$set(this[objName], field, this.timeHrsAutoConvert(value))
    // },
    // timeHrsAutoConvert(value, return_int = false) {
    //   let _value = value

    //   if (_value || _value == 0) {
    //     _value = value.toString()
    //     if (_value.includes(':')) {
    //       const [hours, minutes] = _value.split(':').map(Number)
    //       _value = `${hours.toString().padStart(2, '0')}:${Math.min(minutes, 59)
    //         .toString()
    //         .padStart(2, '0')}`
    //     } else {
    //       const totalMinutes = parseInt(_value, 10)
    //       const hours = Math.floor(totalMinutes / 60)
    //         .toString()
    //         .padStart(2, '0')
    //       const minutes = (totalMinutes % 60).toString().padStart(2, '0')
    //       _value = `${hours}:${minutes}`
    //     }

    //     if (return_int) {
    //       if (_value.indexOf(':') > -1) {
    //         const [hours, minutes] = _value.split(':').map(Number)
    //         _value = hours * 60 + minutes
    //       } else {
    //         _value = 0
    //       }
    //     }
    //   }

    //   return _value
    // }
  }
}
</script>
