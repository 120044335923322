<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Adjustment</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <slot name="actions">
        <slot name="prefix-actions" />
        <v-btn
          color="primary"
          dark
          v-show="mode === 'edit'"
          @click="onEditAdjustment()"
        >
          {{ $t('btn.newItem') }}
        </v-btn>
      </slot>
    </v-toolbar>
    <v-dialog v-model="dialogAdjustment">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="3" class="app-input--approve-wrapper">
                <app-input
                  name="adjustment_ref"
                  type="text"
                  :label="$t('fields.ref')"
                  v-model="editingAdjustment.ref"
                />
              </v-col>
              <v-col cols="3" class="app-input--approve-wrapper">
                <app-input
                  name="adjustment_adj_type"
                  type="select"
                  :label="$t('fields.adjType')"
                  v-model="editingAdjustment.adj_type"
                  :items="adjustmentTypeItems"
                />
              </v-col>
              <v-col cols="4" class="app-input--approve-wrapper">
                <app-input
                  name="adjustment_adj_note"
                  type="text"
                  :label="$t('fields.adjNote')"
                  v-model="editingAdjustment.adj_note"
                />
              </v-col>
              <v-col cols="2" style="text-align: right">
                <v-btn
                  color="primary"
                  dark
                  v-show="mode === 'edit'"
                  @click="onEditAdjustmentLine()"
                >
                  {{ $t('btn.newItem') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <app-table
                  model="task"
                  app="audit"
                  :headers="adjustmentLineHeaders"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :is-selecteable="false"
                  :client-items="editingAdjustment.lines"
                  @edit="onEditAdjustmentLine"
                  @delete="onDeleteAdjustmentLine"
                >
                  <template v-slot:foot>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td style="text-align: right">
                          <strong>Total</strong>
                        </td>
                        <td>
                          {{ accountingFormat(editingAdjustmentTotalDebit) }}
                        </td>
                        <td>
                          {{ accountingFormat(editingAdjustmentTotalCredit) }}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </template>
                </app-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCloseAdjustment">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onSaveAdjustment">
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAdjustmentLine" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="app-input--approve-wrapper">
                <app-input
                  v-model="editingAdjustmentLine.chart_of_account_detail"
                  name="adjustment_line_chart_of_account_detail"
                  type="select-server"
                  :label="$t('fields.chartOfAccount')"
                  :view="view"
                  :binds="{
                    apiUrl: `chart-of-account/chart-of-account-detail/?state=approved&active=true&audit_id=${$route.query.audit_id}`,
                    itemText: (item) => `${item.code} ${item.name}`
                  }"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="app-input--approve-wrapper">
                <app-input
                  name="adjustment_line_debit"
                  type="number"
                  :label="$t('fields.adjDebit')"
                  v-model="editingAdjustmentLine.debit"
                />
              </v-col>
              <v-col cols="6" class="app-input--approve-wrapper">
                <app-input
                  name="adjustment_line_creebit"
                  type="number"
                  :label="$t('fields.adjCredit')"
                  v-model="editingAdjustmentLine.credit"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="app-input--approve-wrapper">
                <app-input
                  name="adjustment_line_note"
                  type="text"
                  :label="$t('fields.note')"
                  v-model="editingAdjustmentLine.note"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCloseAdjustmentLine">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onSaveAdjustmentLine">
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <v-row v-for="(adjustment, index) in adjustments" :key="index">
        <v-col cols="12">
          <v-card outlined>
            <v-card-text>
              <v-row>
                <v-col cols="2" xs="12">
                  <app-input
                    name="reference"
                    type="text"
                    :label="$t('fields.ref')"
                    v-model="adjustments[index].ref"
                    view
                  />
                </v-col>
                <v-col cols="2" xs="12">
                  <app-input
                    name="reference"
                    type="select"
                    :label="$t('fields.adjType')"
                    v-model="adjustments[index].adj_type"
                    :items="adjustmentTypeItems"
                    view
                  />
                </v-col>
                <v-col cols="2" xs="12">
                  <app-input
                    name="reference"
                    type="text"
                    :label="$t('fields.adjNote')"
                    v-model="adjustments[index].adj_note"
                    view
                  />
                </v-col>

                <v-col cols="2" style="text-align: right">
                  <v-btn
                    color="primary"
                    dark
                    v-show="
                      mode === 'edit' &&
                      adjustment.is_before_audit === true &&
                      adjustment.is_after_audit === true
                    "
                    @click="onCreateCustomerAudit(adjustment)"
                  >
                    {{ $t('btn.createCustomerAudit') }}
                  </v-btn>
                </v-col>
                <v-col cols="2" style="text-align: right">
                  <v-icon
                    v-show="mode === 'edit'"
                    @click="onEditAdjustment(adjustment)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    v-show="
                      mode === 'edit' && adjustment.is_after_audit === true
                    "
                    class="ml-2"
                    @click="onDeleteAdjustment([adjustment])"
                  >
                    mdi-delete
                  </v-icon>
                </v-col>
                <v-col cols="2" style="text-align: right">
                  <v-span
                    v-show="
                      adjustment.is_before_audit === false &&
                      adjustment.is_after_audit === true
                    "
                    style="font-size: 20px; color: red"
                  >
                    Customer Audit</v-span
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <app-table
                    model="task"
                    app="audit"
                    :headers="adjustmentHeaders"
                    :hide-edit="mode !== 'edit'"
                    :hide-delete="mode !== 'edit'"
                    :loading="loading"
                    :is-selecteable="false"
                    :client-items="adjustments[index].lines"
                  >
                    <template v-slot:foot>
                      <tfoot>
                        <tr>
                          <td></td>
                          <td></td>
                          <td style="text-align: right">
                            <strong>Total</strong>
                          </td>
                          <td>
                            {{
                              accountingFormat(
                                calcAdjustmentTotalDebit(adjustments[index])
                              )
                            }}
                          </td>
                          <td>
                            {{
                              accountingFormat(
                                calcAdjustmentTotalCredit(adjustments[index])
                              )
                            }}
                          </td>
                          <td></td>
                        </tr>
                      </tfoot>
                    </template>
                  </app-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AppTable from '@components/AppTable.vue'
import AppInput from '@components/AppInput.vue'
import { accounting } from '@utils/number-format'
import _ from 'lodash'
import { defaultTableParams } from '@utils/app-table'

export default {
  name: 'app-adjustment',
  components: {
    AppTable,
    AppInput
  },
  props: {
    mode: {
      type: String,
      required: true
    },
    view: {
      type: Boolean
    }
  },
  data() {
    return {
      dialogAdjustment: false,
      editingAdjustment: {},
      dialogAdjustmentLine: false,
      editingAdjustmentLine: {},
      adjustments: [],
      adjustmentsCount: 0,
      loading: false
    }
  },
  watch: {
    mode: {
      immediate: true,
      handler() {
        this.getAdjustments()
      }
    }
  },
  computed: {
    adjustmentTypeItems() {
      return [
        { label: 'Adjustment', value: 'Adjustment' },
        { label: 'Reclassification', value: 'Reclassification' }
      ]
    },
    adjustmentLineHeaders() {
      return [
        {
          text: this.$t('fields.accountCode'),
          value: 'chart_of_account_detail.code',
          groupable: false,
          hideFilter: true,
          sortable: false
        },
        {
          text: this.$t('fields.accountName'),
          value: 'chart_of_account_detail.name',
          groupable: false,
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.adjDebit'),
          value: 'debit',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.adjCredit'),
          value: 'credit',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note',
          groupable: false,
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    editingAdjustmentTotalDebit() {
      return this.calcAdjustmentTotalDebit(this.editingAdjustment)
    },
    editingAdjustmentTotalCredit() {
      return this.calcAdjustmentTotalCredit(this.editingAdjustment)
    },
    adjustmentHeaders() {
      return [
        {
          text: this.$t('fields.accountCode'),
          value: 'chart_of_account_detail.code',
          groupable: false,
          hideFilter: true,
          sortable: false
        },
        {
          text: this.$t('fields.accountName'),
          value: 'chart_of_account_detail.name',
          groupable: false,
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.adjRef'),
          value: 'task_adjustment__ref',
          groupable: false,
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.adjDebit'),
          value: 'debit',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.adjCredit'),
          value: 'credit',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note',
          groupable: false,
          sortable: false,
          hideFilter: true
        }
      ]
    },
    editingAdjustmentIsBalanced() {
      return this.calcAdjustmentIsBalanced(this.editingAdjustment)
    }
  },
  methods: {
    accountingFormat: accounting,
    onEditAdjustment(item) {
      this.editingAdjustment = Object.assign(
        {
          lines: []
        },
        item
      )
      this.dialogAdjustment = true
    },
    onEditAdjustmentLine(line) {
      if (line) {
        this.editingAdjustmentLine = line
      } else {
        this.editingAdjustmentLine = Object.assign({
          debit: 0,
          credit: 0
        })
      }
      this.dialogAdjustmentLine = true
    },
    onDeleteAdjustmentLine(items) {
      for (const item of items) {
        const i = this.editingAdjustment.lines.findIndex(
          (line) => line === item
        )
        if (i >= 0) {
          this.editingAdjustment.lines.splice(i, 1)
        }
      }
    },
    onCloseAdjustment() {
      this.dialogAdjustment = false
      this.$nextTick(() => {
        this.editingAdjustment = {}
      })
      this.loading = false
    },
    async onSaveAdjustment() {
      if (!this.editingAdjustment.adj_type) {
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: 'Please select Adjustment Type'
        })
        return
      }
      if (
        !this.editingAdjustment.lines ||
        !this.editingAdjustment.lines.length
      ) {
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: 'Adjustment Lines must be filled'
        })
        return
      }

      if (!this.editingAdjustmentIsBalanced) {
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: 'Unbalance Total Debit and Total Credit'
        })
        return
      }

      try {
        this.loading = true
        const data = {
          ref: this.editingAdjustment.ref,
          adj_type: this.editingAdjustment.adj_type,
          adj_note: this.editingAdjustment.adj_note,
          lines: this.editingAdjustment.lines.map((line) => ({
            chart_of_account_detail_id: line.chart_of_account_detail.id,
            debit: line.debit,
            credit: line.credit,
            note: line.note
          }))
        }

        if (this.editingAdjustment.id) {
          await this.$api({
            method: 'put',
            url: `task/task/${this.$route.params.id}/adjustment/${this.editingAdjustment.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `task/task/${this.$route.params.id}/adjustment/`,
            data
          })
        }

        this.onCloseAdjustment()
        await this.getAdjustments()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onCloseAdjustmentLine() {
      this.dialogAdjustmentLine = false
      this.$nextTick(() => {
        this.editingAdjustmentLine = {}
      })
    },
    async onSaveAdjustmentLine() {
      if (!this.editingAdjustmentLine.chart_of_account_detail) {
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: 'Please select Chart of Account'
        })
        return
      }
      if (
        this.editingAdjustmentLine.debit === null ||
        this.editingAdjustmentLine.credit === null
      ) {
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: 'Debit or Credit must be filled'
        })
        return
      }
      if (
        this.editingAdjustmentLine.debit === 0 &&
        this.editingAdjustmentLine.credit === 0
      ) {
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: 'Debit or Credit must be greater than 0'
        })
        return
      }
      const i = this.editingAdjustment.lines.findIndex(
        (line) => line === this.editingAdjustmentLine
      )
      if (i >= 0) {
        this.editingAdjustment.lines[i] = this.editingAdjustmentLine
      } else {
        this.editingAdjustment.lines.push(this.editingAdjustmentLine)
      }

      this.onCloseAdjustmentLine()
    },
    onCreateCustomerAudit(item) {
      this.loading = true
      return this.$api({
        method: 'post',
        url: `task/task/${this.$route.params.id}/adjustment/create_customer_audit/`,
        data: {
          id: item.id
        }
      }).then(() => {
        this.getAdjustments()
      })
    },
    onDeleteAdjustment(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task/${this.$route.params.id}/adjustment/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getAdjustments()
        })
      })
      this.loading = false
    },
    calcAdjustmentTotalDebit(adjustment) {
      if (!adjustment || !adjustment.lines || !adjustment.lines.length) {
        return 0
      }
      return this.privateCalcAdjustmentTotalDebit(adjustment.lines)
    },
    calcAdjustmentTotalCredit(adjustment) {
      if (!adjustment || !adjustment.lines || !adjustment.lines.length) {
        return 0
      }
      return this.privateCalcAdjustmentTotalCredit(adjustment.lines)
    },
    privateCalcAdjustmentTotalDebit(lines) {
      return _.sumBy(lines, (line) => parseFloat(line.debit))
    },
    privateCalcAdjustmentTotalCredit(lines) {
      return _.sumBy(lines, (line) => parseFloat(line.credit))
    },
    calcAdjustmentIsBalanced(adjustment) {
      return (
        this.calcAdjustmentTotalDebit(adjustment) ===
        this.calcAdjustmentTotalCredit(adjustment)
      )
    },
    async getAdjustments() {
      this.loading = true
      try {
        let params = defaultTableParams({
          page: 1,
          itemsPerPage: 100
        })
        const { data } = await this.$api({
          method: 'get',
          url: `task/task-adjustment/?task_id=${this.$route.params.id}`,
          params: params,
          hideSuccessAlert: true
        })
        this.adjustments = data.results
        this.adjustmentsCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
