<template>
  <v-card flat>
    <v-card-text>
      <app-table
        server-side
        app="audit"
        model="taskactivityprofile"
        :headers="taskActivityProfileHeader"
        :hide-edit="mode !== 'edit'"
        :hide-delete="mode !== 'edit'"
        :serverItems="taskActivityProfileItems"
        :clientItems="taskActivityProfileItems"
        :loading="loading"
        :server-items-length="taskActivityProfileItemsLength"
        v-model="taskActivityProfileSelected"
        @server="getTaskActivityProfileItems"
        @client="getTaskActivityProfileClientItems"
        @delete="onDeleteTaskActivityProfileItem"
        @edit="onEditTaskActivityProfile"
      >
        <template v-slot:top>
          <div class="app-list-view">
            <div class="app-list-view--header">
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ $t('menu.activityProfile') }}
                </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>

                <v-dialog v-model="dialogTaskActivityProfile" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <slot name="actions">
                      <slot name="prefix-actions" />
                      <v-btn
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        v-show="mode === 'edit'"
                      >
                        New Item
                      </v-btn>
                      <v-btn
                        v-show="
                          taskActivityProfileSelected.length && mode === 'edit'
                        "
                        color="error"
                        @click="
                          onDeleteTaskActivityProfile(
                            taskActivityProfileSelected
                          )
                        "
                      >
                        {{ $t('btn.delete') }}
                      </v-btn>
                      <slot name="suffix-actions" />
                    </slot>
                  </template>
                  <v-card>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <app-input
                              v-model="editedItem.activity_profile_id"
                              name="documentTemplate"
                              rules="required"
                              type="select-server"
                              :label="$t('fields.activityProfile')"
                              :view="view"
                              :binds="{
                                apiUrl:
                                  'activity/activity-profile-header/?active=true'
                              }"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="closeTaskActivityProfile"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="saveTaskActivityProfile"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </div>
          </div>
        </template>
      </app-table>
    </v-card-text>
  </v-card>
</template>

<script>
import AppTable from '@components/AppTable.vue'
import AppInput from '@components/AppInput.vue'

export default {
  name: 'app-task-activity-profile',
  components: {
    AppTable,
    AppInput
  },
  props: {
    mode: {
      type: String,
      required: true
    },
    view: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogTaskActivityProfile: false,
      loading: false,
      editedItem: {
        document_template_id: null,
        questionAnswer: [],
        text_answer: null,
        answerScore: 0,
        questionAnswerDrop: null,
        id: null
      },
      taskActivityProfileItems: [],
      taskActivityProfileClientItems: [],
      taskActivityProfileSelected: [],
      taskActivityProfileItemsLength: 0,
      apiMethod: 'post'
    }
  },
  computed: {
    taskActivityProfileHeader() {
      return [
        {
          text: this.$t('fields.activityProfile'),
          value: 'activity_profile_id.name'
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    }
  },
  methods: {
    getTaskActivityProfileItems(options = null) {
      this.loading = true

      if (options) {
        const offset = (options.page - 1) * options.itemsPerPage
        var ordering = options.sortBy
          .reduce(
            (acc, value, index) => [
              ...acc,
              `${options.sortDesc[index] ? '-' : ''}${value.replace('.', '__')}`
            ],
            []
          )
          .join(',')

        if (options.sortBy.length == 0) {
          ordering = 'activity_profile_id'
        }

        this.params = {
          limit: options.itemsPerPage,
          offset: offset,
          search: options.search,
          ordering: ordering,
          ...options.multiSearch,
          task_id: this.$route.params.id
        }
      } else {
        this.params = {
          task_id: this.$route.params.id,
          ordering: 'activity_profile_id'
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: 'task/task-activity-profile/',
          params: this.params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.taskActivityProfileItemsLength = data.count
          this.taskActivityProfileItems = data.results
          this.taskActivityProfileClientItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getTaskActivityProfileClientItems(multiSearch) {
      this.taskActivityProfileClientItems =
        this.taskActivityProfileItems.filter((item) => {
          return Object.entries(multiSearch).every(([key, value]) => {
            if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
              return item[key.slice(0, key.lastIndexOf('.'))][
                key.slice(key.lastIndexOf('.') + 1)
              ]
                .toString()
                .toUpperCase()
                .includes(value.toString().toUpperCase())
            } else {
              return item[key]
                .toString()
                .toUpperCase()
                .includes(value.toString().toUpperCase())
            }
          })
        })
    },
    onDeleteTaskActivityProfileItem(item) {
      this.onDeleteTaskActivityProfile(item)
    },
    onDeleteTaskActivityProfile(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task-activity-profile/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
        })
      })
    },
    onEditTaskActivityProfile(item) {
      this.editedItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialogTaskActivityProfile = true
    },
    closeTaskActivityProfile() {
      this.editedItem = {}
      this.dialogTaskActivityProfile = false
    },
    saveTaskActivityProfile() {
      this.loading = true
      this.editedItem.audit_plan_header_id = this.$route.params.audit_plan_id

      this.editedItem.activity_profile_id =
        this.editedItem.activity_profile_id.id
      this.editedItem.task_id = this.$route.params.id

      const data = this.editedItem

      this.$api({
        method: this.apiMethod,
        url: `task/task-activity-profile/${
          this.editId ? this.editId + '/' : ''
        }`,
        data
      })

      this.loading = false
      this.apiMethod = 'post'
      this.editId = null
      this.closeTaskActivityProfile()
      this.getTaskActivityProfileItems()
    }
  }
}
</script>
