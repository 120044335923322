<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Debit Sum</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-btn
        color="primary"
        dark
        v-show="mode === 'edit'"
        @click="onDebitSumBiasClick"
      >
        Bias
      </v-btn>
      <v-btn
        color="primary"
        dark
        v-show="mode === 'edit'"
        @click="onDebitSumNonBiasClick"
      >
        Non-bias
      </v-btn>
      <v-btn
        color="error"
        dark
        v-show="mode === 'edit'"
        @click="onResetSumBiasClick"
      >
        Reset
      </v-btn>
      <v-spacer></v-spacer>
      <slot name="actions">
        <slot name="prefix-actions" />
        <v-btn
          color="primary"
          dark
          v-show="mode === 'edit'"
          @click="onEditDebitSummary()"
        >
          {{ $t('btn.newItem') }}
        </v-btn>
        <v-btn
          color="primary"
          dark
          v-show="mode === 'edit'"
          @click="onImportDebitSummary()"
        >
          {{ $t('btn.import') }}
        </v-btn>
        <v-btn
          v-show="debitSummarySelected.length && mode === 'edit'"
          color="error"
          @click="onDeleteDebitSummary(debitSummarySelected)"
        >
          {{ $t('btn.delete') }}
        </v-btn>
      </slot>
    </v-toolbar>

    <!-- Debit Sum Dialog -->
    <v-dialog v-model="dialogDebitSum" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="partner_code"
                  type="text"
                  :label="$t('fields.partnerCode')"
                  v-model="editingDebitSum.partner_code"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="partner_name"
                  type="text"
                  :label="$t('fields.partnerName')"
                  v-model="editingDebitSum.partner_name"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="partner_branch"
                  type="text"
                  :label="$t('fields.partnerBranch')"
                  v-model="editingDebitSum.partner_branch"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="partner_address"
                  type="text"
                  :label="$t('fields.partnerAddress')"
                  v-model="editingDebitSum.partner_address"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="partner_attn_person"
                  type="text"
                  :label="$t('fields.partnerAttnPerson')"
                  v-model="editingDebitSum.partner_attn_person"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="balance"
                  type="number"
                  :label="$t('fields.balance')"
                  v-model="editingDebitSum.balance"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="note"
                  type="text"
                  :label="$t('fields.note')"
                  v-model="editingDebitSum.note"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="branch"
                  type="text"
                  :label="$t('fields.branch')"
                  v-model="editingDebitSum.branch"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  v-model="editingDebitSum.business_unit"
                  name="business_unit"
                  type="select-server"
                  :label="$t('fields.businessUnit')"
                  :view="view"
                  :binds="{
                    apiUrl: `contact/business-unit/?active=true&contact_id=${contactId.id}`
                  }"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <app-input
                  name="actual"
                  type="number"
                  :label="$t('fields.actual')"
                  v-model="editingDebitSum.actual"
                  :view="view"
                />
              </v-col>
              <v-col cols="2">
                <v-btn
                  :disabled="!editingDebitSum.id || !editingDebitSum.subtask"
                  color="primary"
                  dark
                  @click="onSumDetailFromDetailClick"
                >
                  Actual detail
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  v-model="editingDebitSum.status"
                  name="status"
                  type="select-server"
                  :label="$t('fields.status')"
                  :view="view"
                  :binds="{ apiUrl: 'audit/task-debit-status/' }"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  v-model="editingDebitSum.dummy"
                  name="dummy"
                  type="switch"
                  :label="$t('fields.dummy')"
                  :view="view"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCloseDebitSummary">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onSaveDebitSummary">
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Import Dialog -->
    <v-dialog v-model="dialogDebitSumImport" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <app-xlsx-import @change="onDebitSumImportFileChange" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <v-btn color="blue darken-1" text :href="debitSumTemplateUrl">
                  Download Template
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                {{ tempDebitSumImportStatus }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCloseImportDebitSummary">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onImportDebitSummaryDo">
            {{ $t('btn.import') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Bias Dialog -->
    <v-dialog v-model="dialogDebitSumBias" max-width="700px">
      <v-card>
        <v-toolbar flat>
          <slot name="actions">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              v-show="mode === 'edit'"
              @click="onEditDebitSumBias()"
            >
              {{ $t('btn.newItem') }}
            </v-btn>
          </slot>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <app-table
                  server-side
                  model="task"
                  app="audit"
                  :headers="debitSummaryBiasHeaders"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :is-selecteable="true"
                  :server-items="debitSummaryBiases"
                  :server-items-length="debitSummaryBiasesCount"
                  @server="getDebitSumBiases"
                  @edit="onEditDebitSumBias"
                  @delete="onDeleteDebitSumBias"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCloseDebitSumBias">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onSaveDebitSumBias">
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditDebitSumBias" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="field"
                  rules="required"
                  type="select-server"
                  :label="$t('fields.field')"
                  :view="view"
                  :binds="{ apiUrl: 'audit/task-debit-summary-bias-field/' }"
                  v-model="editingDebitSumBias.field"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="operator"
                  rules="required"
                  type="select-server"
                  :label="$t('fields.operator')"
                  :view="view"
                  :binds="{ apiUrl: 'audit/task-debit-bias-operator/' }"
                  v-model="editingDebitSumBias.operator"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5">
                <app-input
                  name="value1"
                  type="text"
                  :label="$t('fields.value1')"
                  :view="view"
                  v-model="editingDebitSumBias.value1"
                />
              </v-col>
              <v-col cols="5">
                <app-input
                  name="value2"
                  type="text"
                  :label="$t('fields.value2')"
                  :disabled="
                    !['Between', 'Not Between'].includes(
                      editingDebitSumBias.operator
                    )
                  "
                  :rules="{
                    required: (value) => {
                      if (
                        !['Between', 'Not Between'].includes(
                          editingDebitSumBias.operator
                        )
                      ) {
                        return true
                      }
                      return value || 'You must enter a value2.'
                    }
                  }"
                  v-model="editingDebitSumBias.value2"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="reason"
                  type="text"
                  :label="$t('fields.reason')"
                  :view="view"
                  v-model="editingDebitSumBias.reason"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onEditDebitSumBiasClose">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onEditDebitSumBiasSave">
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDebitSumNonBias" max-width="600px">
      <v-card>
        <v-toolbar flat>
          <slot name="actions">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              v-show="mode === 'edit'"
              @click="onEditDebitSumNonBias"
            >
              {{ $t('btn.newItem') }}
            </v-btn>
          </slot>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <app-table
                  server-side
                  model="task"
                  app="audit"
                  :headers="debitSummaryNonBiasHeaders"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :is-selecteable="true"
                  :server-items="debitSummaryNonBiases"
                  :server-items-length="debitSummaryNonBiasesCount"
                  @server="getDebitSumNonBiases"
                  @edit="onEditDebitSumNonBias"
                  @delete="onDeleteDebitSumNonBias"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onDebitSumNonBiasClose">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onDebitSumNonBiasSave">
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditDebitSumNonBias" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="sampling_size"
                  rules="required"
                  type="number"
                  :label="$t('fields.samplingSize')"
                  :view="view"
                  v-model="editingDebitSumNonBias.sampling_size"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="sampling_method"
                  rules="required"
                  type="select-server"
                  :label="$t('fields.samplingMethod')"
                  :view="view"
                  :binds="{
                    apiUrl: 'audit/task-debit-non-bias-sampling-method/'
                  }"
                  v-model="editingDebitSumNonBias.sampling_method"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onEditDebitSumNonBiasClose">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onEditDebitSumNonBiasSave">
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card flat>
      <v-card-text>
        <app-table
          server-side
          model="task"
          app="audit"
          :headers="debitSummaryHeaders"
          :hide-edit="mode !== 'edit'"
          :hide-delete="mode !== 'edit'"
          :is-selecteable="true"
          v-model="debitSummarySelected"
          :server-items="debitSummaries"
          :server-items-length="debitSummariesCount"
          @server="getDebitSummaries"
          @edit="onEditDebitSummary"
          @delete="onDeleteDebitSummary"
        >
          <template v-slot:item.subtask="{ item }">
            <template v-if="item.subtask">
              <router-link
                :to="{
                  name: 'taskEdit',
                  params: { id: item.subtask.id },
                  query: {
                    task_debit_summary_id: item.id,
                    task_type: 'DCD',
                    audit_plan_id: $route.query.audit_plan_id,
                    audit_id: $route.query.audit_id,
                    from: $route.query.from
                  }
                }"
                target="_blank"
              >
                <v-icon color="primary">mdi-link</v-icon>
              </router-link>
            </template>
            <template v-else>
              <router-link
                :to="{
                  name: 'taskCreate',
                  query: {
                    task_debit_summary_id: item.id,
                    task_type: 'DCD',
                    audit_plan_id: $route.query.audit_plan_id,
                    audit_id: $route.query.audit_id,
                    from: $route.query.from,
                    parent_task_id: $route.params.id
                  }
                }"
              >
                <v-icon color="green">mdi-link</v-icon>
              </router-link>
            </template>
          </template>
          <template v-slot:foot>
            <tfoot>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style="text-align: right">
                  <strong>Total</strong>
                </td>
                <td></td>
                <td>{{ accountingFormat(debitSummariesSumBalance) }}</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{ accountingFormat(debitSummariesSumActual) }}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tfoot>
          </template>
        </app-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import AppTable from '@components/AppTable.vue'
import AppInput from '@components/AppInput.vue'
import AppXlsxImport from '@components/AppXlsxImport.vue'
import { accounting } from '@utils/number-format'
import Papa from 'papaparse'
import readXlsxFile from 'read-excel-file'
import { defaultTableParams } from '@utils/app-table'
import _ from 'lodash'

export default {
  name: 'app-debit-sum',
  components: {
    AppTable,
    AppInput,
    AppXlsxImport
  },
  props: {
    mode: {
      type: String,
      required: true
    },
    view: {
      type: Boolean,
      default: false
    },
    contactId: {
      type: Object
    }
  },
  data() {
    return {
      dialogDebitSum: false,
      dialogDebitSumImport: false,
      dialogDebitSumBias: false,
      dialogDebitSumNonBias: false,

      editingDebitSum: {},
      editingDebitSumBias: {},
      editingDebitSumNonBias: {},

      debitSummarySelected: [],
      debitSummaryBiases: [],
      debitSummaryBiasesCount: 0,
      debitSummaryNonBiases: [],
      debitSummaryNonBiasesCount: 0,

      dialogEditDebitSumBias: false,
      dialogEditDebitSumNonBias: false,

      debitSummaries: [],
      debitSummariesCount: 0,
      debitSummariesSumBalance: 0,
      debitSummariesSumActual: 0
    }
  },
  computed: {
    tempDebitSumImportStatus() {
      if (!this.tempDebitSumImports || !this.tempDebitSumImports.length) {
        return null
      }
      return `${this.tempDebitSumImports.length} records will be imported`
    },
    debitSummaryBiasHeaders() {
      return [
        {
          text: this.$t('fields.field'),
          value: 'field',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.operator'),
          value: 'operator',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.value1'),
          value: 'value1',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.value2'),
          value: 'value2',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.reason'),
          value: 'reason',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    debitSummaryNonBiasHeaders() {
      return [
        {
          text: this.$t('fields.samplingSize'),
          value: 'sampling_size',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.samplingMethod'),
          value: 'sampling_method',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    debitSumTemplateUrl() {
      return `${process.env.VUE_APP_BASE_API}static/template/DEBIT%20SUM%20Template.xlsx`
    },
    debitSummaryHeaders() {
      return [
        {
          text: this.$t('fields.partnerCode'),
          value: 'partner_code'
        },
        {
          text: this.$t('fields.partnerName'),
          value: 'partner_name'
        },
        {
          text: this.$t('fields.partnerBranch'),
          value: 'partner_branch'
        },
        {
          text: this.$t('fields.partnerAddress'),
          value: 'partner_address'
        },
        {
          text: this.$t('fields.partnerAttnPerson'),
          value: 'partner_attn_person'
        },
        {
          text: this.$t('fields.balance'),
          value: 'balance',
          accountFormat: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note'
        },
        {
          text: this.$t('fields.branch'),
          value: 'branch'
        },
        {
          text: this.$t('fields.businessUnit'),
          value: 'business_unit.name'
        },
        {
          text: this.$t('fields.actual'),
          value: 'actual',
          accountFormat: true
        },
        {
          text: this.$t('fields.status'),
          value: 'status'
        },
        {
          text: this.$t('fields.samplingType'),
          value: 'sampling_type'
        },
        {
          text: this.$t('fields.dummy'),
          value: 'dummy',
          check: true,
          filterMode: 'exact',
          filterType: 'boolean'
        },
        {
          text: 'Subtask',
          value: 'subtask',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    }
  },
  methods: {
    accountingFormat: accounting,
    onCloseDebitSummary() {
      this.dialogDebitSum = false
      this.$nextTick(() => {
        this.editingDebitSum = {}
      })
      this.loading = false
    },
    async onSaveDebitSummary() {
      try {
        this.loading = true
        const data = {
          partner_code: this.editingDebitSum.partner_code,
          partner_name: this.editingDebitSum.partner_name,
          partner_branch: this.editingDebitSum.partner_branch,
          partner_address: this.editingDebitSum.partner_address,
          partner_attn_person: this.editingDebitSum.partner_attn_person,
          balance: this.editingDebitSum.balance,
          note: this.editingDebitSum.note,
          branch: this.editingDebitSum.branch,
          business_unit:
            this.editingDebitSum.business_unit?.id ??
            this.editingDebitSum.business_unit,
          actual: this.editingDebitSum.actual,
          status: this.editingDebitSum.status,
          sampling_type: this.editingDebitSum.sampling_type,
          dummy: this.editingDebitSum.dummy
        }

        if (this.editingDebitSum.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/debit-summary/${this.editingDebitSum.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/debit-summary/`,
            data
          })
        }

        this.onCloseDebitSummary()
        await this.getDebitSummaries()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onDebitSumImportFileChange(val) {
      if (val) {
        if (val.type === 'text/csv') {
          this.tempDebitSumImports = []
          Papa.parse(val, {
            complete: (results) => {
              for (let i = 1; i < results.data.length; i++) {
                const row = results.data[i]
                if (row.length !== 12) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 12. It has ${row.length} columns.`
                  })
                  return
                }
                this.tempDebitSumImports.push({
                  partner_code: row[0],
                  partner_name: row[1],
                  partner_branch: row[2],
                  partner_address: row[3],
                  partner_attn_person: row[4],
                  balance: row[5]?.trim().replace(/[,-]/g, ''),
                  note: row[6],
                  branch: row[7],
                  business_unit: row[8],
                  actual: row[9].trim().replace(/[,-]/g, ''),
                  status: row[10].trim(),
                  sampling_type: null,
                  dummy: row[11]
                })
              }
            }
          })
        } else {
          this.tempDebitSumImports = []
          let i = 0
          readXlsxFile(val)
            .then((rows) => {
              for (i = 1; i < rows.length; i++) {
                if (rows[i].length !== 12) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 12. It has ${rows[i].length} columns.`
                  })
                  return
                }
                const row = rows[i]
                this.tempDebitSumImports.push({
                  partner_code: row[0],
                  partner_name: row[1],
                  partner_branch: row[2],
                  partner_address: row[3],
                  partner_attn_person: row[4],
                  balance: row[5],
                  note: row[6],
                  branch: row[7],
                  business_unit: row[8],
                  actual: row[9],
                  status: row[10],
                  sampling_type: null,
                  dummy: row[11]
                })
              }
            })
            .catch((e) => {
              this.$alert('custom', {
                icon: 'error',
                title: this.$t('alert.validationError'),
                text: e.message
              })
            })
        }
      }
    },
    onCloseImportDebitSummary() {
      this.debitSumImportForm = {}
      this.dialogDebitSumImport = false
    },
    async onImportDebitSummaryDo() {
      try {
        this.loading = true
        const data = this.tempDebitSumImports
        await this.$api({
          method: 'post',
          url: `audit/task/${this.$route.params.id}/debit-summary/import_xlsx/`,
          data,
          hideErrorAlert: true
        })

        this.onCloseImportDebitSummary()
        await this.getDebitSummaries()
      } catch (e) {
        console.log(e)
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: _.truncate(
            _.filter(
              _.map(e.data, (row, i) =>
                _.map(row, (value, key) => `Row ${i + 1} ${key}: ${value}\n`)
              ),
              (row) => row.length > 0
            ),
            { length: 100 }
          )
        })
      } finally {
        this.loading = false
      }
    },
    onCloseDebitSumBias() {
      this.dialogDebitSumBias = false
    },
    async onSaveDebitSumBias() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear all Sampling Type on Debit Summary. You must also run Non-bias again after this. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/debit-summary/compute_bias/`,
            data: {}
          })
          this.onDebitSumBiasClose()
          await this.getDebitSummaries()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    onEditDebitSummary(item) {
      this.editingDebitSum = Object.assign({}, item)
      this.dialogDebitSum = true
    },
    onImportDebitSummary() {
      this.debitSumImportForm = {}
      this.dialogDebitSumImport = true
    },
    onEditDebitSumBias(item) {
      this.editingDebitSumBias = Object.assign({}, item)
      this.dialogEditDebitSumBias = true
    },
    onEditDebitSumNonBias(item) {
      this.editingDebitSumNonBias = Object.assign({}, item)
      this.dialogEditDebitSumNonBias = true
    },
    async onDeleteDebitSumBias(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/debit-summary-bias/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getDebitSumBiases()
        })
      })
      this.loading = false
    },
    async onDeleteDebitSumNonBias(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/debit-summary-non-bias/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getDebitSumNonBiases()
        })
      })
      this.loading = false
    },
    async getDebitSumBiases(options) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/debit-summary-bias/`,
          params: params,
          hideSuccessAlert: true
        })
        this.debitSummaryBiases = data.results
        this.debitSummaryBiasesCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getDebitSumNonBiases(options) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/debit-summary-non-bias/`,
          params: params,
          hideSuccessAlert: true
        })
        this.debitSummaryNonBiases = data.results
        this.debitSummaryNonBiasesCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditDebitSumBiasClose() {
      this.dialogEditDebitSumBias = false
    },
    async onEditDebitSumBiasSave() {
      try {
        this.loading = true
        const data = {
          field: this.editingDebitSumBias.field,
          operator: this.editingDebitSumBias.operator,
          value1: this.editingDebitSumBias.value1,
          reason: this.editingDebitSumBias.reason
        }
        if (
          this.editingDebitSumBias.operator === 'Between' ||
          this.editingDebitSumBias.operator === 'Not Between'
        ) {
          data.value2 = this.editingDebitSumBias.value2
        }

        if (this.editingDebitSumBias.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/debit-summary-bias/${this.editingDebitSumBias.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/debit-summary-bias/`,
            data
          })
        }

        this.onEditDebitSumBiasClose()
        await this.getDebitSumBiases()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onDebitSumNonBiasClose() {
      this.dialogDebitSumNonBias = false
    },
    async onDebitSumNonBiasSave() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear Non-Bias Sampling Type on Debit Summary and re-assign them. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/debit-summary/compute_non_bias/`,
            data: {}
          })
          this.onDebitSumNonBiasClose()
          await this.getDebitSummaries()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    onEditDebitSumNonBiasClose() {
      this.dialogEditDebitSumNonBias = false
    },
    async onEditDebitSumNonBiasSave() {
      try {
        this.loading = true
        const data = {
          sampling_size: this.editingDebitSumNonBias.sampling_size,
          sampling_method: this.editingDebitSumNonBias.sampling_method
        }

        if (this.editingDebitSumNonBias.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/debit-summary-non-bias/${this.editingDebitSumNonBias.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/debit-summary-non-bias/`,
            data
          })
        }

        this.onEditDebitSumNonBiasClose()
        await this.getDebitSumNonBiases()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getDebitSummaries(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/debit-summary/`,
          params: params,
          hideSuccessAlert: true
        })
        this.debitSummaries = data.results
        this.debitSummariesCount = data.count
        this.debitSummariesSumBalance = data.sum_balance ?? 0
        this.debitSummariesSumActual = data.sum_actual ?? 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onDeleteDebitSummary(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/debit-summary/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.debitSummarySelected = []
          this.getDebitSummaries()
        })
      })
      this.loading = false
    },
    async onSumDetailFromDetailClick() {
      console.log(this.editingDebitSum.subtask)
      if (!this.editingDebitSum.subtask) {
        return
      }
      try {
        this.loading = true

        const response = await this.$api({
          method: 'get',
          url: `audit/task/${this.editingDebitSum.subtask.id}/debit-detail/`,
          hideSuccessAlert: true
        })
        console.log(response)

        this.editingDebitSum.actual =
          response.data.sum_actual ?? this.editingDebitSum.actual
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async onResetSumBiasClick() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear all Sampling Type on Debit Summary. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/debit-summary/reset/`,
            data: {}
          })
          await this.getDebitSummaries()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    onDebitSumNonBiasClick() {
      this.dialogDebitSumNonBias = true
    },
    onDebitSumBiasClick() {
      this.dialogDebitSumBias = true
    }
  }
}
</script>
