<template>
  <app-menu-form
    :headers="sumRelatedCOAItemHeader"
    :loading="loading"
    :is-selecteable="false"
    :server-items="sumRelatedCOAItemItems"
    :server-items-length="sumRelatedCOAItemServerItemsLength"
    :client-items="sumRelatedCOAItemItems"
    hide-edit
    hide-delete
    :is-expand-server="true"
    :single-expand="true"
    :expand-server-headers="subSumRelatedCOAItemHeader"
    :expand-items="subSumRelatedCOAItemItems"
    :expand-items-length="subSumRelatedCOAItemServerItemsLength"
    sub-item-key="id"
    item-key="id"
    :events="eventHandlers"
  >
    <template v-slot:top>
      <app-menu-form-top title="Summarize Related CoA" :is-activator="false">
      </app-menu-form-top>
    </template>
  </app-menu-form>
</template>

<script>
import AppMenuForm from '@components/AppMenuForm.vue'
import AppMenuFormTop from '@components/view/AppMenuFormTop.vue'
import { defaultTableParams } from '@utils/app-table'

export default {
  name: 'app-sum-related-coa',
  components: {
    AppMenuForm,
    AppMenuFormTop
  },
  props: {
    mode: {
      type: String,
      required: true
    },
    view: {
      type: Boolean
    }
  },
  data() {
    return {
      sumRelatedCOAItemItems: [],
      sumRelatedCOAItemServerItemsLength: 0,
      subSumRelatedCOAItemItems: {},
      subSumRelatedCOAItemServerItemsLength: {},
      loading: false
    }
  },
  computed: {
    sumRelatedCOAItemHeader() {
      return [
        {
          text: this.$t('fields.accountCode'),
          value: 'chart_of_account_detail_id.code',
          groupable: false,
          hideFilter: true,
          sortable: false
        },
        {
          text: this.$t('fields.accountName'),
          value: 'chart_of_account_detail_id.name',
          groupable: false,
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.dr'),
          value: 'dr',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.cr'),
          value: 'cr',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.representAuditAmount'),
          value: 'total_respressent_audit_amount',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.targetSamplingAmount'),
          value: 'total_target_sampling_amount',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.samplingRespresent'),
          value: 'total_sampling_resoresebt',
          groupable: false,
          sortable: false,
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.actual'),
          value: 'total_actual',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.actualTargetSampling'),
          value: 'total_actual_target_sampling',
          groupable: false,
          sortable: false,
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.representLeft'),
          value: 'total_represent_left',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        }
      ]
    },
    subSumRelatedCOAItemHeader() {
      return [
        {
          text: this.$t('fields.task'),
          value: 'task.name',
          groupable: false,
          hideFilter: true,
          sortable: false
        },
        {
          text: this.$t('fields.representAuditAmount'),
          value: 'respressent_audit_amount',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.targetSamplingAmount'),
          value: 'target_sampling_amount',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.samplingRespresent'),
          value: 'sampling_resoresebt',
          groupable: false,
          sortable: false,
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.actual'),
          value: 'actual',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.actualTargetSampling'),
          value: 'actual_target_sampling',
          groupable: false,
          sortable: false,
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.representLeft'),
          value: 'represent_left',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: 'Actions',
          value: 'actions_view',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    eventHandlers() {
      return {
        server: this.getSumRelatedCOAItemItems,
        itemExpanded: this.getSubSumRelatedCOAItemItems,
        expandView: this.onEditTask
      }
    }
  },
  methods: {
    async getSumRelatedCOAItemItems(options = null) {
      this.loading = true
      if (
        this.$route.params.id === null ||
        this.$route.params.id === undefined
      ) {
        return
      }
      try {
        options.sortBy = ['sequence', 'reference']
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/audit-header/${this.$route.query.audit_id}/task/${this.$route.params.id}/related-coa/summarise`,
          params: params,
          hideSuccessAlert: true
        })
        this.sumRelatedCOAItemItems = data.results
        this.sumRelatedCOAItemServerItemsLength = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getSubSumRelatedCOAItemItems(item, value) {
      if (!value) return
      this.loading = true
      try {
        const options = {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['sequence', 'reference']
        }
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `audit/audit-header/${this.$route.query.audit_id}/task/${this.$route.params.id}/related-coa/summarise_detail?id=${item.id}`,
          params: params,
          hideSuccessAlert: true
        })
        this.subSumRelatedCOAItemItems[item.id] = data.results
        this.subSumRelatedCOAItemServerItemsLength[item.id] = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditTask(item) {
      this.$router.push({
        name: 'taskEdit',
        params: { id: item.id },
        query: {
          audit_plan_id: this.$route.query.audit_plan_id,
          audit_id: this.$route.query.audit_id,
          from: this.$route.query.from
        }
      })
    }
  }
}
</script>
