import { render, staticRenderFns } from "./AppStockDetailOutstanding.vue?vue&type=template&id=fa03c1fc"
import script from "./AppStockDetailOutstanding.vue?vue&type=script&lang=js"
export * from "./AppStockDetailOutstanding.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports