<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Stock Detail Outstanding</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-btn
        color="primary"
        dark
        v-show="mode === 'edit'"
        @click="onStockDetailBiasClick"
        >Bias
      </v-btn>
      <v-btn
        color="primary"
        dark
        v-show="mode === 'edit'"
        @click="onStockDetailNonBiasClick"
        >Non-bias
      </v-btn>
      <v-btn
        color="error"
        dark
        v-show="mode === 'edit'"
        @click="onResetStockDetailBiasClick"
        >Reset
      </v-btn>
      <v-spacer></v-spacer>
      <slot name="actions">
        <slot name="prefix-actions" />
        <v-btn
          color="primary"
          dark
          v-show="mode === 'edit'"
          @click="onEditStockDetail()"
        >
          {{ $t('btn.newItem') }}
        </v-btn>
        <v-btn
          color="primary"
          dark
          v-show="mode === 'edit'"
          @click="onImportStockDetail()"
        >
          {{ $t('btn.import') }}
        </v-btn>
        <v-btn
          v-show="stockDetailSelected.length && mode === 'edit'"
          color="error"
          @click="onDeleteStockDetail(stockDetailSelected)"
        >
          {{ $t('btn.delete') }}
        </v-btn>
      </slot>
    </v-toolbar>
    <v-card flat>
      <v-card-text>
        <app-table
          server-side
          model="task"
          app="audit"
          :headers="stockDetailHeaders"
          :hide-edit="mode !== 'edit'"
          :hide-delete="mode !== 'edit'"
          v-model="stockDetailSelected"
          :server-items="stockDetailItemItems"
          :server-items-length="stockDetailItemServerItemsLength"
          @server="getStockDetail"
          @edit="onEditStockDetail"
          @delete="onDeleteStockDetail"
        >
          <template v-slot:[`item.subtask`]="{ item }">
            <template v-if="item.subtask">
              <router-link
                :to="{
                  name: 'taskEdit',
                  params: { id: item.subtask.id },
                  query: {
                    task_stock_detail_id: item.id,
                    task_type: formData.task_type,
                    audit_plan_id: $route.query.audit_plan_id,
                    audit_id: $route.query.audit_id,
                    from: $route.query.from
                  }
                }"
                target="_blank"
              >
                <v-icon color="primary">mdi-link</v-icon>
              </router-link>
            </template>
            <template v-else>
              <router-link
                :to="{
                  name: 'taskCreate',
                  query: {
                    task_stock_detail_id: item.id,
                    task_type: formData.task_type,
                    audit_plan_id: $route.query.audit_plan_id,
                    audit_id: $route.query.audit_id,
                    from: $route.query.from,
                    parent_task_id: $route.params.id
                  }
                }"
              >
                <v-icon color="green">mdi-link</v-icon>
              </router-link>
            </template>
          </template>
          <template v-slot:foot>
            <tfoot>
              <tr>
                <td style="text-align: right">
                  <strong>Total</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {{ accountingFormat(stockDetailSumProductBalance) }}
                </td>
                <td>
                  {{ accountingFormat(stockDetailSumProductValue) }}
                </td>
                <td></td>
                <td></td>
                <td>
                  {{ accountingFormat(stockDetailSumActualProductBalance) }}
                </td>
                <td>
                  {{ accountingFormat(stockDetailSumActualProductValue) }}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tfoot>
          </template>
        </app-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialogStockDetail" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="product_code"
                  type="text"
                  :label="$t('fields.productCode')"
                  v-model="editedStockDetail.product_code"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="product_name"
                  type="text"
                  :label="$t('fields.productName')"
                  v-model="editedStockDetail.product_name"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="product_barcode"
                  type="text"
                  :label="$t('fields.productBarcode')"
                  v-model="editedStockDetail.product_barcode"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="product_category"
                  type="text"
                  :label="$t('fields.productCategory')"
                  v-model="editedStockDetail.product_category"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="product_unit"
                  type="text"
                  :label="$t('fields.productUnit')"
                  v-model="editedStockDetail.product_unit"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="product_cost"
                  type="number"
                  :label="$t('fields.productCostPerUnit')"
                  v-model="editedStockDetail.product_cost_per_unit"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="product_balance"
                  type="number"
                  :label="$t('fields.productBalance')"
                  v-model="editedStockDetail.product_balance"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="product_value"
                  type="number"
                  :label="$t('fields.productValue')"
                  v-model="editedStockDetail.product_value"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="actual_product_unit"
                  type="text"
                  :label="$t('fields.actualProductUnit')"
                  v-model="editedStockDetail.actual_product_unit"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="actual_product_cost"
                  type="number"
                  :label="$t('fields.actualProductCostPerUnit')"
                  v-model="editedStockDetail.actual_product_cost_per_unit"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="actual_product_balance"
                  type="number"
                  :label="$t('fields.actualProductBalance')"
                  v-model="editedStockDetail.actual_product_balance"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="actual_product_value"
                  type="number"
                  :label="$t('fields.actualProductValue')"
                  v-model="editedStockDetail.actual_product_value"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="warehouse"
                  type="text"
                  :label="$t('fields.warehouse')"
                  v-model="editedStockDetail.warehouse"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="branch"
                  type="text"
                  :label="$t('fields.branch')"
                  v-model="editedStockDetail.branch"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  v-model="editedStockDetail.business_unit"
                  name="business_unit"
                  type="select-server"
                  :label="$t('fields.bu')"
                  :view="view"
                  :binds="{
                    apiUrl: `contact/business-unit/?active=true&contact_id=${formData.contact_id.id}`
                  }"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="note"
                  type="text"
                  :label="$t('fields.note')"
                  v-model="editedStockDetail.note"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  v-model="editedStockDetail.status"
                  name="status"
                  type="select-server"
                  :label="$t('fields.status')"
                  :view="view"
                  :binds="{
                    apiUrl: 'audit/task-stock-detail-outstanding-status'
                  }"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  v-model="editedStockDetail.dummy"
                  name="dummy"
                  type="switch"
                  :label="$t('fields.dummy')"
                  :view="view"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCloseStockDetail">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onSaveStockDetail">
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogStockDetailImport" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <app-xlsx-import @change="onStockDetailImportFileChange" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <v-btn
                  color="blue darken-1"
                  text
                  :href="stockDetailTemplateUrl"
                >
                  Download Template
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                {{ tempStockDetailImportStatus }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCloseImportStockDetail">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onImportStockDetailDo">
            {{ $t('btn.import') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogStockDetailBias" max-width="700px">
      <v-card>
        <v-toolbar flat>
          <slot name="actions">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              v-show="mode === 'edit'"
              @click="onEditStockDetailBiasCondition()"
            >
              {{ $t('btn.newItem') }}
            </v-btn>
          </slot>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <app-table
                  server-side
                  model="task"
                  app="audit"
                  :is-selecteable="false"
                  :headers="stockDetailBiasHeaders"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :server-items="stockDetailBiasItemItems"
                  :server-items-length="stockDetailBiasItemServerItemsLength"
                  @server="getStockDetailBiasItems"
                  @edit="onEditStockDetailBiasCondition"
                  @delete="onDeleteStockDetailBiasCondition"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onStockDetailBiasClose">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onStockDetailBiasSave">
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogStockDetailBiasCondition" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="field"
                  rules="required"
                  type="select-server"
                  :label="$t('fields.field')"
                  :view="view"
                  :binds="{
                    apiUrl: 'audit/task-stock-detail-outstanding-bias-field/'
                  }"
                  v-model="editedStockDetailBiasCondition.field"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="operator"
                  rules="required"
                  type="select-server"
                  :label="$t('fields.operator')"
                  :view="view"
                  :binds="{
                    apiUrl: 'audit/task-stock-detail-outstanding-bias-operator/'
                  }"
                  v-model="editedStockDetailBiasCondition.operator"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5">
                <app-input
                  name="value1"
                  type="text"
                  :label="$t('fields.value1')"
                  :view="view"
                  v-model="editedStockDetailBiasCondition.value1"
                />
              </v-col>
              <v-col cols="5">
                <app-input
                  name="value2"
                  type="text"
                  :label="$t('fields.value2')"
                  :disabled="
                    editedStockDetailBiasCondition.operator !== 'Between'
                  "
                  :rules="{
                    required: (value) => {
                      if (editedStockDetailBiasCondition.operator !== 'Between')
                        return true
                      if (value) return true

                      return 'You must enter a value2.'
                    }
                  }"
                  v-model="editedStockDetailBiasCondition.value2"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="reason"
                  type="text"
                  :label="$t('fields.reason')"
                  :view="view"
                  v-model="editedStockDetailBiasCondition.reason"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="onStockDetailBiasConditionClose"
          >
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="onStockDetailBiasConditionSave"
          >
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogStockDetailNonBias" max-width="600px">
      <v-card>
        <v-toolbar flat>
          <slot name="actions">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              v-show="mode === 'edit'"
              @click="onEditStockDetailNonBiasCondition()"
            >
              {{ $t('btn.newItem') }}
            </v-btn>
          </slot>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <app-table
                  server-side
                  model="task"
                  app="audit"
                  :headers="stockDetailNonBiasHeaders"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :is-selecteable="false"
                  :server-items="stockDetailNonBiasItemItems"
                  :server-items-length="stockDetailNonBiasItemServerItemsLength"
                  @server="getStockDetailNonBiasItems"
                  @edit="onEditStockDetailNonBiasCondition"
                  @delete="onDeleteStockDetailNonBiasCondition"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onStockDetailNonBiasClose">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onStockDetailNonBiasSave">
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogStockDetailNonBiasCondition" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="sampling_size"
                  rules="required"
                  type="number"
                  :label="$t('fields.samplingSize')"
                  :view="view"
                  v-model="editedStockDetailNonBiasCondition.sampling_size"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="sampling_method"
                  rules="required"
                  type="select-server"
                  :label="$t('fields.method')"
                  :view="view"
                  :binds="{
                    apiUrl:
                      'audit/task-stock-detail-outstanding-non-bias-sampling-method/'
                  }"
                  v-model="editedStockDetailNonBiasCondition.sampling_method"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="onStockDetailNonBiasConditionClose"
          >
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="onStockDetailNonBiasConditionSave"
          >
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppTable from '@components/AppTable.vue'
import AppInput from '@components/AppInput.vue'
import AppXlsxImport from '@components/AppXlsxImport.vue'
import Papa from 'papaparse'
import readXlsxFile from 'read-excel-file'
import { defaultTableParams } from '@utils/app-table'
import _ from 'lodash'
import { accounting } from '@utils/number-format'

export default {
  name: 'app-stock-detail-outstanding',
  components: {
    AppTable,
    AppInput,
    AppXlsxImport
  },
  props: {
    mode: {
      type: String,
      required: true
    },
    view: {
      type: Boolean
    },
    formData: {
      type: Object
    }
  },
  data() {
    return {
      stockDetailSelected: [],
      editedStockDetail: [],
      editedStockDetailBiasCondition: [],
      editedStockDetailNonBiasCondition: [],
      dialogStockDetail: false,
      dialogStockDetailImport: false,
      dialogStockDetailBias: false,
      dialogStockDetailBiasCondition: false,
      dialogStockDetailNonBias: false,
      dialogStockDetailNonBiasCondition: false,
      stockDetailItemItems: [],
      stockDetailItemServerItemsLength: 0,
      stockDetailSumProductBalance: 0,
      stockDetailSumProductValue: 0,
      stockDetailSumActualProductBalance: 0,
      stockDetailSumActualProductValue: 0,
      tempStockDetailImports: [],
      stockDetailImportForm: {},
      stockDetailBiasItemItems: [],
      stockDetailBiasItemServerItemsLength: 0,
      stockDetailNonBiasItemItems: [],
      stockDetailNonBiasItemServerItemsLength: 0,
      loading: false
    }
  },
  computed: {
    stockDetailHeaders() {
      return [
        {
          text: this.$t('fields.productCode'),
          value: 'product_code'
        },
        {
          text: this.$t('fields.productName'),
          value: 'product_name'
        },
        {
          text: this.$t('fields.productBarcode'),
          value: 'product_barcode'
        },
        {
          text: this.$t('fields.productCategory'),
          value: 'product_category'
        },
        {
          text: this.$t('fields.productUnit'),
          value: 'product_unit'
        },
        {
          text: this.$t('fields.productCostPerUnit'),
          value: 'product_cost_per_unit',
          accountFormat: true
        },
        {
          text: this.$t('fields.productBalance'),
          value: 'product_balance',
          accountFormat: true
        },
        {
          text: this.$t('fields.productValue'),
          value: 'product_value',
          accountFormat: true
        },
        {
          text: this.$t('fields.actualProductUnit'),
          value: 'actual_product_unit'
        },
        {
          text: this.$t('fields.actualProductCostPerUnit'),
          value: 'actual_product_cost_per_unit',
          accountFormat: true
        },
        {
          text: this.$t('fields.actualProductBalance'),
          value: 'actual_product_balance',
          accountFormat: true
        },
        {
          text: this.$t('fields.actualProductValue'),
          value: 'actual_product_value',
          accountFormat: true
        },
        {
          text: this.$t('fields.warehouse'),
          value: 'warehouse'
        },
        {
          text: this.$t('fields.branch'),
          value: 'branch'
        },
        {
          text: this.$t('fields.bu'),
          value: 'business_unit.name'
        },
        {
          text: this.$t('fields.note'),
          value: 'note'
        },
        {
          text: this.$t('fields.status'),
          value: 'status'
        },
        {
          text: this.$t('fields.samplingType'),
          value: 'sampling_type'
        },
        {
          text: this.$t('fields.dummy'),
          value: 'dummy',
          check: true,
          filterMode: 'exact',
          filterType: 'boolean'
        },
        {
          text: 'Subtask',
          value: 'subtask',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    tempStockDetailImportStatus() {
      if (!this.tempStockDetailImports || !this.tempStockDetailImports.length) {
        return null
      }
      return `${this.tempStockDetailImports.length} records will be imported`
    },
    stockDetailTemplateUrl() {
      return `${process.env.VUE_APP_BASE_API}static/template/STOCK%20DETAIL%20OUTSTANDING%20Template.xlsx`
    },
    stockDetailBiasHeaders() {
      return [
        {
          text: this.$t('fields.field'),
          value: 'field',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.operator'),
          value: 'operator',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.value1'),
          value: 'value1',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.value2'),
          value: 'value2',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.reason'),
          value: 'reason',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    stockDetailNonBiasHeaders() {
      return [
        {
          text: this.$t('fields.samplingSize'),
          value: 'sampling_size',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.method'),
          value: 'sampling_method',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    }
  },
  methods: {
    accountingFormat: accounting,
    async getStockDetail(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/stock-detail-outstanding/`,
          params: params,
          hideSuccessAlert: true
        })
        this.stockDetailItemItems = data.results
        this.stockDetailItemServerItemsLength = data.count
        this.stockDetailSumProductBalance = data.sum_product_balance ?? 0
        this.stockDetailSumProductValue = data.sum_product_value ?? 0
        this.stockDetailSumActualProductBalance =
          data.sum_actual_product_balance ?? 0
        this.stockDetailSumActualProductValue =
          data.sum_actual_product_value ?? 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditStockDetail(item) {
      this.editedStockDetail = Object.assign({}, item)
      this.dialogStockDetail = true
    },
    onCloseStockDetail() {
      this.dialogStockDetail = false
      this.$nextTick(() => {
        this.editedStockDetail = {}
      })
      this.loading = false
    },
    onDeleteStockDetail(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/stock-detail-outstanding/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.stockDetailSelected = []
          this.getStockDetail()
        })
      })
      this.loading = false
    },
    async onSaveStockDetail() {
      try {
        this.loading = true
        const data = {
          product_code: this.editedStockDetail.product_code,
          product_name: this.editedStockDetail.product_name,
          product_barcode: this.editedStockDetail.product_barcode,
          product_category: this.editedStockDetail.product_category,
          product_unit: this.editedStockDetail.product_unit,
          product_cost_per_unit: this.editedStockDetail.product_cost_per_unit,
          product_balance: this.editedStockDetail.product_balance,
          product_value: this.editedStockDetail.product_value,
          actual_product_unit: this.editedStockDetail.actual_product_unit,
          actual_product_cost_per_unit:
            this.editedStockDetail.actual_product_cost_per_unit,
          actual_product_balance: this.editedStockDetail.actual_product_balance,
          actual_product_value: this.editedStockDetail.actual_product_value,
          warehouse: this.editedStockDetail.warehouse,
          branch: this.editedStockDetail.branch,
          business_unit:
            this.editedStockDetail.business_unit?.id ??
            this.editedStockDetail.business_unit,
          note: this.editedStockDetail.note,
          status: this.editedStockDetail.status,
          dummy: this.editedStockDetail.dummy
        }

        if (this.editedStockDetail.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/stock-detail-outstanding/${this.editedStockDetail.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/stock-detail-outstanding/`,
            data
          })
        }
        this.onCloseStockDetail()
        await this.getStockDetail()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    onImportStockDetail() {
      this.stockDetailImportForm = {}
      this.dialogStockDetailImport = true
    },
    onStockDetailImportFileChange(val) {
      if (val) {
        if (val.type === 'text/csv') {
          this.tempStockDetailImports = []
          Papa.parse(val, {
            complete: (results) => {
              for (let i = 1; i < results.data.length; i++) {
                const row = results.data[i]
                if (row.length !== 18) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 18. It has ${row.length} columns.`
                  })
                  return
                }
                this.tempStockDetailImports.push({
                  product_code: row[0],
                  product_name: row[1],
                  product_barcode: row[2],
                  product_category: row[3],
                  product_unit: row[4],
                  product_cost: row[5]?.trim().replace(/[,-]/g, ''),
                  product_balance: row[6]?.trim().replace(/[,-]/g, ''),
                  product_value: row[7]?.trim().replace(/[,-]/g, ''),
                  actual_product_unit: row[8],
                  actual_product_cost: row[9]?.trim().replace(/[,-]/g, ''),
                  actual_product_balance: row[10]?.trim().replace(/[,-]/g, ''),
                  actual_product_value: row[11]?.trim().replace(/[,-]/g, ''),
                  warehouse: row[12],
                  branch: row[13],
                  business_unit: row[14],
                  note: row[15],
                  status: row[16].trim(),
                  sampling_type: null,
                  dummy: row[17]
                })
              }
            }
          })
        } else {
          this.tempStockDetailImports = []
          let i = 0
          readXlsxFile(val)
            .then((rows) => {
              for (i = 1; i < rows.length; i++) {
                if (rows[i].length !== 18) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 18. It has ${rows[i].length} columns.`
                  })
                  return
                }
                const row = rows[i]
                this.tempStockDetailImports.push({
                  product_code: row[0],
                  product_name: row[1],
                  product_barcode: row[2],
                  product_category: row[3],
                  product_unit: row[4],
                  product_cost: row[5],
                  product_balance: row[6],
                  product_value: row[7],
                  actual_product_unit: row[8],
                  actual_product_cost: row[9],
                  actual_product_balance: row[10],
                  actual_product_value: row[11],
                  warehouse: row[12],
                  branch: row[13],
                  business_unit: row[14],
                  note: row[15],
                  status: row[16],
                  sampling_type: null,
                  dummy: row[17]
                })
              }
            })
            .catch((e) => {
              this.$alert('custom', {
                icon: 'error',
                title: this.$t('alert.validationError'),
                text: e.message
              })
            })
        }
      }
    },
    async onImportStockDetailDo() {
      try {
        this.loading = true
        const data = this.tempStockDetailImports
        await this.$api({
          method: 'post',
          url: `audit/task/${this.$route.params.id}/stock-detail-outstanding/import_xlsx/`,
          data,
          hideErrorAlert: true
        })

        this.onCloseImportStockDetail()
        await this.getStockDetail()
      } catch (e) {
        console.log(e)
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: _.truncate(
            _.filter(
              _.map(e.data, (row, i) =>
                _.map(row, (value, key) => `Row ${i + 1} ${key}: ${value}\n`)
              ),
              (row) => row.length > 0
            ),
            { length: 100 }
          )
        })
      } finally {
        this.loading = false
      }
    },
    onCloseImportStockDetail() {
      this.stockDetailImportForm = {}
      this.dialogStockDetailImport = false
    },
    onStockDetailBiasClick() {
      this.dialogStockDetailBias = true
    },
    async getStockDetailBiasItems(options) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/stock-detail-outstanding-bias/`,
          params: params,
          hideSuccessAlert: true
        })
        this.stockDetailBiasItemItems = data.results
        this.stockDetailBiasItemServerItemsLength = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditStockDetailBiasCondition(item) {
      this.editedStockDetailBiasCondition = Object.assign({}, item)
      this.dialogStockDetailBiasCondition = true
    },
    async onDeleteStockDetailBiasCondition(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/stock-detail-outstanding-bias/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getStockDetailBiasItems()
        })
      })
      this.loading = false
    },
    onStockDetailBiasConditionClose() {
      this.dialogStockDetailBiasCondition = false
    },
    async onStockDetailBiasConditionSave() {
      try {
        this.loading = true
        const data = {
          field: this.editedStockDetailBiasCondition.field,
          operator: this.editedStockDetailBiasCondition.operator,
          value1: this.editedStockDetailBiasCondition.value1,
          reason: this.editedStockDetailBiasCondition.reason
        }
        if (
          this.editedStockDetailBiasCondition.operator === 'Between' ||
          this.editedStockDetailBiasCondition.operator === 'Not Between'
        ) {
          data.value2 = this.editedStockDetailBiasCondition.value2
        }
        if (this.editedStockDetailBiasCondition.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/stock-detail-outstanding-bias/${this.editedStockDetailBiasCondition.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/stock-detail-outstanding-bias/`,
            data
          })
        }
        this.onStockDetailBiasConditionClose()
        await this.getStockDetailBiasItems()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onStockDetailBiasClose() {
      this.dialogStockDetailBias = false
    },
    async onStockDetailBiasSave() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear all Sampling Type on Stock Detail Outstanding. You must also run Non-bias again after this. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/stock-detail-outstanding/compute_bias/`,
            data: {}
          })
          this.onStockDetailBiasClose()
          await this.getStockDetail()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },

    onStockDetailNonBiasClick() {
      this.dialogStockDetailNonBias = true
    },
    async getStockDetailNonBiasItems(options) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/stock-detail-outstanding-non-bias/`,
          params: params,
          hideSuccessAlert: true
        })
        this.stockDetailNonBiasItemItems = data.results
        this.stockDetailNonBiasItemServerItemsLength = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditStockDetailNonBiasCondition(item) {
      this.editedStockDetailNonBiasCondition = Object.assign({}, item)
      this.dialogStockDetailNonBiasCondition = true
    },
    async onDeleteStockDetailNonBiasCondition(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/stock-detail-outstanding-non-bias/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getStockDetailNonBiasItems()
        })
      })
      this.loading = false
    },
    onStockDetailNonBiasConditionClose() {
      this.dialogStockDetailNonBiasCondition = false
    },
    async onStockDetailNonBiasConditionSave() {
      try {
        this.loading = true
        const data = {
          sampling_size: this.editedStockDetailNonBiasCondition.sampling_size,
          sampling_method:
            this.editedStockDetailNonBiasCondition.sampling_method
        }

        if (this.editedStockDetailNonBiasCondition.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/stock-detail-outstanding-non-bias/${this.editedStockDetailNonBiasCondition.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/stock-detail-outstanding-non-bias/`,
            data
          })
        }

        this.onStockDetailNonBiasConditionClose()
        await this.getStockDetailNonBiasItems()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onStockDetailNonBiasClose() {
      this.dialogStockDetailNonBias = false
    },
    async onStockDetailNonBiasSave() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear Non-Bias Sampling Type on Stock Detail Outstanding and re-assign them. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/stock-detail-outstanding/compute_non_bias/`,
            data: {}
          })
          this.onStockDetailNonBiasClose()
          await this.getStockDetail()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    async onResetStockDetailBiasClick() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear all Sampling Type on Stock Detail Outstanding. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/stock-detail-outstanding/reset/`,
            data: {}
          })
          await this.getStockDetail()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>
