<template>
  <app-menu-form
    :title="$t('menu.requiredDocument')"
    :mode="mode"
    :dialog="dialogRequiredDocument"
    :hide-edit="mode !== 'edit'"
    :hide-delete="mode !== 'edit'"
    :loading="loading"
    :headers="requiredDocumentHeader"
    :server-items="requiredDocumentItems"
    :client-items="requiredDocumentItems"
    :server-items-length="requiredDocumentItemsLength"
    :events="eventHandlers"
  >
    <template v-slot:item.personal_document_id="{ item }">
      <template v-if="item.personal_document_id">
        <router-link
          :to="{
            name:
              item.personal_document_id.type === 'company'
                ? 'otherDocumentEdit'
                : 'personalDocumentEdit',
            params: { id: item.personal_document_id.id }
          }"
          target="_blank"
        >
          <v-icon color="primary">mdi-link</v-icon>
        </router-link>
      </template>
    </template>

    <template v-slot:top>
      <app-menu-form-top
        :title="$t('menu.requiredDocument')"
        :mode="mode"
        :dialog="dialogRequiredDocument"
        :selectedLength="requiredDocumentSelected.length"
        :onDelete="onDelete"
        @show-dialog="dialogRequiredDocument = $event"
      >
        <template v-slot:dialogForm>
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <app-input
                      v-model="editedItem.document_template_id"
                      name="documentTemplate"
                      rules="required"
                      type="select-server"
                      :label="$t('fields.requiredDocument')"
                      :view="view"
                      :binds="{
                        apiUrl: 'doc-template/document/?active=true'
                      }"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <app-input
                      v-model="editedItem.personal_document_id"
                      name="personal_document"
                      type="select-server"
                      :label="$t('label.selectRequiredDocument')"
                      :view="view"
                      :binds="{
                        apiUrl:
                          editedItem.document_template_id && contactId
                            ? `contact/personal-doc/?document_template_id=${editedItem.document_template_id.id}&contact_ids=${contactId.id}&state=approved&active=true`
                            : '',
                        itemText: 'document_name'
                      }"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <app-input
                      v-model="editedItem.contact_business_unit_id"
                      name="contact_business_unit"
                      type="select-server"
                      :label="$t('fields.businessUnit')"
                      :view="view"
                      :binds="{
                        apiUrl: contactId
                          ? `contact/contact-business-unit/?contact_id=${contactId.id}`
                          : '',
                        itemText: 'business_unit_id.name'
                      }"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <app-input
                      v-model="editedItem.contact_branch_id"
                      name="contact_branch"
                      type="select-server"
                      :label="$t('fields.branch')"
                      :view="view"
                      :binds="{
                        apiUrl: contactId
                          ? `contact/contact-branch?contact_id=${contactId.id}`
                          : ''
                      }"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeRequiredDocument">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="saveRequiredDocument">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </app-menu-form-top>
    </template>
  </app-menu-form>
</template>

<script>
import AppInput from '@components/AppInput.vue'
import AppMenuForm from '@components/AppMenuForm.vue'
import AppMenuFormTop from '@components/view/AppMenuFormTop.vue'

export default {
  name: 'app-required-document',
  components: {
    AppInput,
    AppMenuForm,
    AppMenuFormTop
  },
  props: {
    mode: {
      type: String,
      required: true
    },
    view: {
      type: Boolean
    },
    contactId: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      requiredDocumentItems: [],
      requiredDocumentItemsLength: 0,
      requiredDocumentSelected: [],
      dialogRequiredDocument: false,
      editedItem: {},
      apiMethod: 'post'
    }
  },
  computed: {
    requiredDocumentHeader() {
      return [
        {
          text: this.$t('fields.requiredDocument'),
          value: 'document_template_id.name'
        },
        {
          text: this.$t('fields.name'),
          value: 'personal_document_id.document_name'
        },
        {
          text: this.$t('fields.link'),
          value: 'personal_document_id',
          hideFilter: true
        },
        {
          text: this.$t('fields.businessUnit'),
          value: 'contact_business_unit_id.business_unit_id.name'
        },
        {
          text: this.$t('fields.branchName'),
          value: 'contact_branch_id.name'
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    eventHandlers() {
      return {
        server: this.getRequiredDocumentItems,
        client: this.getRequiredDocumentClientItems,
        delete: this.onDeleteItem,
        edit: this.onEdit
      }
    }
  },
  methods: {
    getRequiredDocumentItems(options = null) {
      this.loading = true

      if (options) {
        const offset = (options.page - 1) * options.itemsPerPage
        var ordering = options.sortBy
          .reduce(
            (acc, value, index) => [
              ...acc,
              `${options.sortDesc[index] ? '-' : ''}${value.replace('.', '__')}`
            ],
            []
          )
          .join(',')

        if (options.sortBy.length == 0) {
          ordering = 'document_template_id'
        }

        this.params = {
          limit: options.itemsPerPage,
          offset: offset,
          search: options.search,
          ordering: ordering,
          ...options.multiSearch,
          task_id: this.$route.params.id
        }
      } else {
        this.params = {
          task_id: this.$route.params.id,
          ordering: 'document_template_id'
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: 'task/task-required-document/',
          params: this.params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.requiredDocumentItemsLength = data.count
          this.requiredDocumentItems = data.results
          this.requiredDocumentClientItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getRequiredDocumentClientItems(multiSearch) {
      this.requiredDocumentClientItems = this.requiredDocumentItems.filter(
        (item) => {
          return Object.entries(multiSearch).every(([key, value]) => {
            if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
              return item[key.slice(0, key.lastIndexOf('.'))][
                key.slice(key.lastIndexOf('.') + 1)
              ]
                .toString()
                .toUpperCase()
                .includes(value.toString().toUpperCase())
            } else {
              return item[key]
                .toString()
                .toUpperCase()
                .includes(value.toString().toUpperCase())
            }
          })
        }
      )
    },
    closeRequiredDocument() {
      this.editedItem = {}
      this.dialogRequiredDocument = false
    },
    saveRequiredDocument() {
      this.loading = true
      this.editedItem.task_id = this.$route.params.id

      if (this.editedItem.document_template_id) {
        this.editedItem.document_template_id =
          this.editedItem.document_template_id.id
      }

      if (this.editedItem.personal_document_id) {
        this.editedItem.personal_document_id =
          this.editedItem.personal_document_id.id
      }

      if (this.editedItem.contact_business_unit_id) {
        this.editedItem.contact_business_unit_id =
          this.editedItem.contact_business_unit_id.id
      }

      if (this.editedItem.contact_branch_id) {
        this.editedItem.contact_branch_id = this.editedItem.contact_branch_id.id
      }
      const data = this.editedItem

      this.$api({
        method: this.apiMethod,
        url: `task/task-required-document/${
          this.editId ? this.editId + '/' : ''
        }`,
        data
      })

      this.loading = false
      this.apiMethod = 'post'
      this.editId = null
      this.closeRequiredDocument()
      this.getRequiredDocumentItems()
    },
    onEdit(item) {
      this.editedItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialogRequiredDocument = true
    },
    onDeleteItem(item) {
      this.onDelete(item)
    },
    onDelete(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task-required-document/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getRequiredDocumentItems()
        })
      })
      this.loading = false
    }
  }
}
</script>
