<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Debit Detail</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-btn
        color="primary"
        dark
        v-show="mode === 'edit'"
        @click="onDebitDetailBiasClick"
        >Bias</v-btn
      >
      <v-btn
        color="primary"
        dark
        v-show="mode === 'edit'"
        @click="onDebitDetailNonBiasClick"
        >Non-bias</v-btn
      >
      <v-btn
        color="error"
        dark
        v-show="mode === 'edit'"
        @click="onResetDetailBiasClick"
        >Reset</v-btn
      >
      <v-spacer></v-spacer>
      <slot name="actions">
        <slot name="prefix-actions" />
        <v-btn
          color="primary"
          dark
          v-show="mode === 'edit'"
          @click="onEditDebitDetail"
          >{{ $t('btn.newItem') }}</v-btn
        >
        <v-btn
          color="primary"
          dark
          v-show="mode === 'edit'"
          @click="onImportDebitDetail"
          >{{ $t('btn.import') }}</v-btn
        >
        <v-btn
          v-show="debitDetailSelected.length && mode === 'edit'"
          color="error"
          @click="onDeleteDebitDetail(debitDetailSelected)"
          >{{ $t('btn.delete') }}</v-btn
        >
      </slot>
    </v-toolbar>

    <v-dialog v-model="dialogDebitDetail" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="partner_code"
                  type="text"
                  :label="$t('fields.partnerCode')"
                  v-model="editingDebitDetail.partner_code"
                  :view="view"
                  :binds="{}"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="partner_name"
                  type="text"
                  :label="$t('fields.partnerName')"
                  v-model="editingDebitDetail.partner_name"
                  :view="view"
                  :binds="{}"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="partner_branch"
                  type="text"
                  :label="$t('fields.partnerBranch')"
                  v-model="editingDebitDetail.partner_branch"
                  :view="view"
                  :binds="{}"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="doc_no"
                  type="text"
                  :label="$t('fields.docNo')"
                  v-model="editingDebitDetail.doc_no"
                  :view="view"
                  :binds="{}"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="doc_date"
                  type="datepicker"
                  :label="$t('fields.docDate')"
                  v-model="editingDebitDetail.doc_date"
                  :view="view"
                  :binds="{}"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="doc_amount"
                  type="number"
                  :label="$t('fields.docAmount')"
                  v-model="editingDebitDetail.doc_amount"
                  :view="view"
                  :binds="{}"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="paid_amount"
                  type="number"
                  :label="$t('fields.paidAmount')"
                  v-model="editingDebitDetail.paid_amount"
                  :view="view"
                  :binds="{}"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="remaining"
                  type="number"
                  :label="$t('fields.remaining')"
                  v-model="editingDebitDetail.remaining"
                  :view="view"
                  :binds="{}"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="note"
                  type="text"
                  :label="$t('fields.note')"
                  v-model="editingDebitDetail.note"
                  :view="view"
                  :binds="{}"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="branch"
                  type="text"
                  :label="$t('fields.branch')"
                  v-model="editingDebitDetail.branch"
                  :view="view"
                  :binds="{}"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="business_unit"
                  type="select-server"
                  :label="$t('fields.businessUnit')"
                  v-model="editingDebitDetail.business_unit"
                  :view="view"
                  :binds="{
                    apiUrl: `contact/business-unit/?active=true&contact_id=${contactId.id}`
                  }"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="actual"
                  type="number"
                  :label="$t('fields.actual')"
                  v-model="editingDebitDetail.actual"
                  :view="view"
                  :binds="{}"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="status"
                  type="select-server"
                  :label="$t('fields.status')"
                  v-model="editingDebitDetail.status"
                  :view="view"
                  :binds="{
                    apiUrl: 'audit/task-debit-status/'
                  }"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="dummy"
                  type="switch"
                  :label="$t('fields.dummy')"
                  v-model="editingDebitDetail.dummy"
                  :view="view"
                  :binds="{}"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCloseDebitDetail">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onSaveDebitDetail">
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDebitDetailImport" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <app-xlsx-import @change="onDebitDetailImportFileChange" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <v-btn
                  color="blue darken-1"
                  text
                  :href="debitDetailTemplateUrl"
                >
                  Download Template
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                {{ tempDebitDetailImportStatus }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCloseImportDebitDetail">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onImportDebitDetailDo">
            {{ $t('btn.import') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDebitDetailBias" max-width="700px">
      <v-card>
        <v-toolbar flat>
          <slot name="actions">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              v-show="mode === 'edit'"
              @click="onEditDebitDetailBias()"
            >
              {{ $t('btn.newItem') }}
            </v-btn>
          </slot>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <app-table
                  server-side
                  model="task"
                  app="audit"
                  :headers="debitDetailBiasHeaders"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :is-selecteable="true"
                  :server-items="debitDetailBiases"
                  :server-items-length="debitDetailBiasesCount"
                  @server="getDebitDetailBiases"
                  @edit="onEditDebitDetailBias"
                  @delete="onDeleteDebitDetailBias"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onDebitDetailBiasClose">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onDebitDetailBiasSave">
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditDebitDetailBias" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="field"
                  rules="required"
                  type="select-server"
                  :label="$t('fields.field')"
                  :view="view"
                  :binds="{
                    apiUrl: 'audit/task-debit-detail-bias-field/'
                  }"
                  v-model="editingDebitDetailBias.field"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="operator"
                  rules="required"
                  type="select-server"
                  :label="$t('fields.operator')"
                  :view="view"
                  :binds="{
                    apiUrl: 'audit/task-debit-bias-operator/'
                  }"
                  v-model="editingDebitDetailBias.operator"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5">
                <app-input
                  name="value1"
                  type="text"
                  :label="$t('fields.value1')"
                  :view="view"
                  v-model="editingDebitDetailBias.value1"
                />
              </v-col>
              <v-col cols="5">
                <app-input
                  name="value2"
                  type="text"
                  :label="$t('fields.value2')"
                  :disabled="
                    editingDebitDetailBias.operator !== 'Between' &&
                    editingDebitDetailBias.operator !== 'Not Between'
                  "
                  :rules="{
                    required: (value) => {
                      if (
                        editingDebitDetailBias.operator !== 'Between' &&
                        editingDebitDetailBias.operator !== 'Not Between'
                      )
                        return true
                      if (value) return true

                      return 'You must enter a value2.'
                    }
                  }"
                  v-model="editingDebitDetailBias.value2"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="reason"
                  type="text"
                  :label="$t('fields.reason')"
                  :view="view"
                  v-model="editingDebitDetailBias.reason"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onEditDebitDetailBiasClose">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onEditDebitDetailBiasSave">
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDebitDetailNonBias" max-width="600px">
      <v-card>
        <v-toolbar flat>
          <slot name="actions">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              v-show="mode === 'edit'"
              @click="onEditDebitDetailNonBias"
            >
              {{ $t('btn.newItem') }}
            </v-btn>
          </slot>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <app-table
                  server-side
                  model="task"
                  app="audit"
                  :headers="debitDetailNonBiasHeaders"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :is-selecteable="true"
                  :server-items="debitDetailNonBiases"
                  :server-items-length="debitDetailNonBiasesCount"
                  @server="getDebitDetailNonBiases"
                  @edit="onEditDebitDetailNonBias"
                  @delete="onDeleteDebitDetailNonBias"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onDebitDetailNonBiasClose">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onDebitDetailNonBiasSave">
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditDebitDetailNonBias" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="sampling_size"
                  rules="required"
                  type="number"
                  :label="$t('fields.samplingSize')"
                  :view="view"
                  v-model="editingDebitDetailNonBias.sampling_size"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="sampling_method"
                  rules="required"
                  type="select-server"
                  :label="$t('fields.samplingMethod')"
                  :view="view"
                  :binds="{
                    apiUrl: 'audit/task-debit-non-bias-sampling-method/'
                  }"
                  v-model="editingDebitDetailNonBias.sampling_method"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="onEditDebitDetailNonBiasClose"
          >
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="onEditDebitDetailNonBiasSave"
          >
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card flat>
      <v-card-text>
        <app-table
          server-side
          model="task"
          app="audit"
          :headers="debitDetailHeaders"
          :hide-edit="mode !== 'edit'"
          :hide-delete="mode !== 'edit'"
          :is-selecteable="true"
          v-model="debitDetailSelected"
          :server-items="debitDetails"
          :server-items-length="debitDetailsCount"
          @server="getDebitDetails"
          @edit="onEditDebitDetail"
          @delete="onDeleteDebitDetail"
        >
          <template v-slot:item.subtask="{ item }">
            <template v-if="item.subtask">
              <router-link
                :to="{
                  name: 'taskEdit',
                  params: { id: item.subtask.id },
                  query: {
                    task_debit_detail_id: item.id,
                    task_type: 'DCD',
                    audit_plan_id: $route.query.audit_plan_id,
                    audit_id: $route.query.audit_id,
                    from: $route.query.from
                  }
                }"
                target="_blank"
              >
                <v-icon color="primary">mdi-link</v-icon>
              </router-link>
            </template>
            <template v-else>
              <router-link
                :to="{
                  name: 'taskCreate',
                  query: {
                    task_debit_detail_id: item.id,
                    task_type: 'DCD',
                    audit_plan_id: $route.query.audit_plan_id,
                    audit_id: $route.query.audit_id,
                    from: $route.query.from,
                    parent_task_id: $route.params.id
                  }
                }"
              >
                <v-icon color="green">mdi-link</v-icon>
              </router-link>
            </template>
          </template>
          <template v-slot:foot>
            <tfoot>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style="text-align: right">
                  <strong>Total</strong>
                </td>
                <td></td>
                <td>
                  {{ accountingFormat(debitDetailsSumDocAmount) }}
                </td>
                <td>
                  {{ accountingFormat(debitDetailsSumPaidAmount) }}
                </td>
                <td>
                  {{ accountingFormat(debitDetailsSumRemaining) }}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {{ accountingFormat(debitDetailsSumActual) }}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tfoot>
          </template>
        </app-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import AppTable from '@components/AppTable.vue'
import AppInput from '@components/AppInput.vue'
import AppXlsxImport from '@components/AppXlsxImport.vue'
import { accounting } from '@utils/number-format'
import Papa from 'papaparse'
import readXlsxFile from 'read-excel-file'
import { defaultTableParams } from '@utils/app-table'
import _ from 'lodash'
import { DateTime } from 'luxon'

export default {
  name: 'app-debit-detail',
  components: {
    AppTable,
    AppInput,
    AppXlsxImport
  },
  props: {
    mode: {
      type: String,
      required: true
    },
    view: {
      type: Boolean
    },
    contactId: {
      type: Object
    }
  },
  data() {
    return {
      dialogDebitDetail: false,
      dialogDebitDetailImport: false,
      dialogDebitDetailNonBias: false,
      dialogDebitDetailBias: false,

      debitDetailSelected: [],

      editingDebitDetail: {},
      editingDebitDetailBias: {},
      editingDebitDetailNonBias: {},

      dialogEditDebitDetailBias: false,
      dialogEditDebitDetailNonBias: false,

      debitDetailBiases: [],
      debitDetailBiasesCount: 0,
      debitDetailNonBiases: [],
      debitDetailNonBiasesCount: 0,
      debitDetails: [],
      debitDetailsCount: 0,

      debitDetailsSumActual: 0,
      debitDetailsSumRemaining: 0,
      debitDetailsSumDocAmount: 0,
      debitDetailsSumPaidAmount: 0
    }
  },
  computed: {
    tempDebitDetailImportStatus() {
      if (!this.tempDebitDetailImports || !this.tempDebitDetailImports.length) {
        return null
      }
      return `${this.tempDebitDetailImports.length} records will be imported`
    },
    debitDetailTemplateUrl() {
      return `${process.env.VUE_APP_BASE_API}static/template/DEBIT%20DETAIL%20Template.xlsx`
    },
    debitDetailBiasHeaders() {
      return [
        {
          text: this.$t('fields.field'),
          value: 'field',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.operator'),
          value: 'operator',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.value1'),
          value: 'value1',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.value2'),
          value: 'value2',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.reason'),
          value: 'reason',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    debitDetailNonBiasHeaders() {
      return [
        {
          text: this.$t('fields.samplingSize'),
          value: 'sampling_size',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.samplingMethod'),
          value: 'sampling_method',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    debitDetailHeaders() {
      return [
        {
          text: this.$t('fields.partnerCode'),
          value: 'partner_code'
        },
        {
          text: this.$t('fields.partnerName'),
          value: 'partner_name'
        },
        {
          text: this.$t('fields.partnerBranch'),
          value: 'partner_branch'
        },
        {
          text: this.$t('fields.docNo'),
          value: 'doc_no'
        },
        {
          text: this.$t('fields.docDate'),
          value: 'doc_date'
        },
        {
          text: this.$t('fields.docAmount'),
          value: 'doc_amount',
          accountFormat: true
        },
        {
          text: this.$t('fields.paidAmount'),
          value: 'paid_amount',
          accountFormat: true
        },
        {
          text: this.$t('fields.remaining'),
          value: 'remaining',
          accountFormat: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note'
        },
        {
          text: this.$t('fields.branch'),
          value: 'branch'
        },
        {
          text: this.$t('fields.businessUnit'),
          value: 'business_unit.name'
        },
        {
          text: this.$t('fields.actual'),
          value: 'actual',
          accountFormat: true
        },
        {
          text: this.$t('fields.status'),
          value: 'status'
        },
        {
          text: this.$t('fields.samplingType'),
          value: 'sampling_type'
        },
        {
          text: this.$t('fields.dummy'),
          value: 'dummy',
          check: true,
          filterMode: 'exact',
          filterType: 'boolean'
        },
        {
          text: 'Subtask',
          value: 'subtask',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    }
  },
  methods: {
    accountingFormat: accounting,
    onDebitDetailBiasClick() {
      this.dialogDebitDetailBias = true
    },
    onDebitDetailNonBiasClick() {
      this.dialogDebitDetailNonBias = true
    },
    async onResetDetailBiasClick() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear all Sampling Type on Debit Detail. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/debit-detail/reset/`,
            data: {}
          })
          await this.getDebitDetails()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    onEditDebitDetailNonBias(item) {
      this.editingDebitDetailNonBias = Object.assign({}, item)
      this.dialogEditDebitDetailNonBias = true
    },
    onImportDebitDetail() {
      this.debitSumImportForm = {}
      this.dialogDebitDetailImport = true
    },
    onDeleteDebitDetail(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/debit-detail/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.debitDetailSelected = []
          this.getDebitDetails()
        })
      })
      this.loading = false
    },
    onCloseDebitDetail() {
      this.dialogDebitDetail = false
      this.$nextTick(() => {
        this.editingDebitDetail = {}
      })
      this.loading = false
    },
    async onSaveDebitDetail() {
      try {
        this.loading = true
        const data = {
          partner_code: this.editingDebitDetail.partner_code,
          partner_name: this.editingDebitDetail.partner_name,
          partner_branch: this.editingDebitDetail.partner_branch,
          doc_no: this.editingDebitDetail.doc_no,
          doc_date: this.editingDebitDetail.doc_date,
          doc_amount: this.editingDebitDetail.doc_amount,
          paid_amount: this.editingDebitDetail.paid_amount,
          remaining: this.editingDebitDetail.remaining,
          note: this.editingDebitDetail.note,
          branch: this.editingDebitDetail.branch,
          business_unit:
            this.editingDebitDetail.business_unit?.id ??
            this.editingDebitDetail.business_unit,
          actual: this.editingDebitDetail.actual,
          status: this.editingDebitDetail.status,
          sampling_type: this.editingDebitDetail.sampling_type,
          dummy: this.editingDebitDetail.dummy
        }

        if (this.editingDebitDetail.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/debit-detail/${this.editingDebitDetail.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/debit-detail/`,
            data
          })
        }

        this.onCloseDebitDetail()
        await this.getDebitDetails()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onCloseImportDebitDetail() {
      this.debitSumImportForm = {}
      this.dialogDebitDetailImport = false
    },
    onDebitDetailImportFileChange(val) {
      if (val) {
        if (val.type === 'text/csv') {
          this.tempDebitDetailImports = []
          Papa.parse(val, {
            complete: (results) => {
              for (let i = 1; i < results.data.length; i++) {
                const row = results.data[i]
                if (row.length !== 14) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 14. It has ${row.length} columns.`
                  })
                  return
                }
                this.tempDebitDetailImports.push({
                  partner_code: row[0],
                  partner_name: row[1],
                  partner_branch: row[2],
                  doc_no: row[3],
                  doc_date: DateTime.fromFormat(row[4], 'dd/LL/yyyy').toFormat(
                    'yyyy-LL-dd'
                  ),
                  doc_amount: row[5]?.trim().replace(/[,-]/g, ''),
                  paid_amount: row[6]?.trim().replace(/[,-]/g, ''),
                  remaining: row[7]?.trim().replace(/[,-]/g, ''),
                  note: row[8],
                  branch: row[9],
                  business_unit: row[10],
                  actual: row[11].trim().replace(/[,-]/g, ''),
                  status: row[12].trim(),
                  sampling_type: null,
                  dummy: row[13]
                })
              }
            }
          })
        } else {
          this.tempDebitDetailImports = []
          let i = 0
          readXlsxFile(val)
            .then((rows) => {
              for (i = 1; i < rows.length; i++) {
                if (rows[i].length !== 14) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 14. It has ${rows[i].length} columns.`
                  })
                  return
                }
                const row = rows[i]
                this.tempDebitDetailImports.push({
                  partner_code: row[0],
                  partner_name: row[1],
                  partner_branch: row[2],
                  doc_no: row[3],
                  doc_date: row[4]
                    ? DateTime.fromJSDate(row[4]).toFormat('yyyy-LL-dd')
                    : null,
                  doc_amount: row[5],
                  paid_amount: row[6],
                  remaining: row[7],
                  note: row[8],
                  branch: row[9],
                  business_unit: row[10],
                  actual: row[11],
                  status: row[12],
                  sampling_type: null,
                  dummy: row[13]
                })
              }
            })
            .catch((e) => {
              this.$alert('custom', {
                icon: 'error',
                title: this.$t('alert.validationError'),
                text: e.message
              })
            })
        }
      }
    },
    async onImportDebitDetailDo() {
      try {
        this.loading = true
        const data = this.tempDebitDetailImports
        await this.$api({
          method: 'post',
          url: `audit/task/${this.$route.params.id}/debit-detail/import_xlsx/`,
          data,
          hideErrorAlert: true
        })

        this.onCloseImportDebitDetail()
        await this.getDebitDetails()
      } catch (e) {
        console.log(e)
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: _.truncate(
            _.filter(
              _.map(e.data, (row, i) =>
                _.map(row, (value, key) => `Row ${i + 1} ${key}: ${value}\n`)
              ),
              (row) => row.length > 0
            ),
            { length: 100 }
          )
        })
      } finally {
        this.loading = false
      }
    },
    onEditDebitDetailBiasClose() {
      this.dialogEditDebitDetailBias = false
    },
    onDebitDetailBiasClose() {
      this.dialogDebitDetailBias = false
    },
    async onDebitDetailBiasSave() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear all Sampling Type on Debit Detail. You must also run Non-bias again after this. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/debit-detail/compute_bias/`,
            data: {}
          })
          this.onDebitDetailBiasClose()
          await this.getDebitDetails()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    async onEditDebitDetailBiasSave() {
      try {
        this.loading = true
        const data = {
          field: this.editingDebitDetailBias.field,
          operator: this.editingDebitDetailBias.operator,
          value1: this.editingDebitDetailBias.value1,
          reason: this.editingDebitDetailBias.reason
        }
        if (
          this.editingDebitDetailBias.operator === 'Between' ||
          this.editingDebitDetailBias.operator === 'Not Between'
        ) {
          data.value2 = this.editingDebitDetailBias.value2
        }

        if (this.editingDebitDetailBias.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/debit-detail-bias/${this.editingDebitDetailBias.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/debit-detail-bias/`,
            data
          })
        }

        this.onEditDebitDetailBiasClose()
        await this.getDebitDetailBiases()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getDebitDetailBiases(options) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/debit-detail-bias/`,
          params: params,
          hideSuccessAlert: true
        })
        this.debitDetailBiases = data.results
        this.debitDetailBiasesCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async onDeleteDebitDetailBias(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/debit-detail-bias/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getDebitDetailBiases()
        })
      })
      this.loading = false
    },
    async onDebitDetailNonBiasSave() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear Non-Bias Sampling Type on Debit Detail and re-assign them. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/debit-detail/compute_non_bias/`,
            data: {}
          })
          this.onDebitDetailNonBiasClose()
          await this.getDebitDetails()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    onEditDebitDetailNonBiasClose() {
      this.dialogEditDebitDetailNonBias = false
    },
    async onEditDebitDetailNonBiasSave() {
      try {
        this.loading = true
        const data = {
          sampling_size: this.editingDebitDetailNonBias.sampling_size,
          sampling_method: this.editingDebitDetailNonBias.sampling_method
        }

        if (this.editingDebitDetailNonBias.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/debit-detail-non-bias/${this.editingDebitDetailNonBias.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/debit-detail-non-bias/`,
            data
          })
        }

        this.onEditDebitDetailNonBiasClose()
        await this.getDebitDetailNonBiases()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getDebitDetailNonBiases(options) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/debit-detail-non-bias/`,
          params: params,
          hideSuccessAlert: true
        })
        this.debitDetailNonBiases = data.results
        this.debitDetailNonBiasesCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async onDeleteDebitDetailNonBias(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/debit-detail-non-bias/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getDebitDetailNonBiases()
        })
      })
      this.loading = false
    },
    async getDebitDetails(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/debit-detail/`,
          params: params,
          hideSuccessAlert: true
        })
        this.debitDetails = data.results
        this.debitDetailsCount = data.count
        this.debitDetailsSumDocAmount = data.sum_doc_amount ?? 0
        this.debitDetailsSumPaidAmount = data.sum_paid_amount ?? 0
        this.debitDetailsSumRemaining = data.sum_remaining ?? 0
        this.debitDetailsSumActual = data.sum_actual ?? 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditDebitDetail(item) {
      this.editingDebitDetail = Object.assign({}, item)
      this.dialogDebitDetail = true
    },
    onDebitDetailNonBiasClose() {
      this.dialogDebitDetailNonBias = false
    },
    onEditDebitDetailBias(item) {
      this.editingDebitDetailBias = Object.assign({}, item)
      this.dialogEditDebitDetailBias = true
    }
  }
}
</script>
