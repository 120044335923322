var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-menu-form',{attrs:{"headers":_vm.timesheetItemHeader,"server-items":_vm.timesheetItemItems,"client-items":_vm.timesheetItemItems,"loading":_vm.loading,"server-items-length":_vm.timesheetItemServerItemsLength,"hide-edit":_vm.mode !== 'edit',"hide-delete":_vm.mode !== 'edit',"events":_vm.eventHandlers},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('app-menu-form-top',{attrs:{"title":"Timesheet","mode":_vm.mode,"dialog":_vm.dialogTimesheetItem,"selected-length":_vm.selected.length,"dialog-max-width":"900px"},on:{"show-dialog":function($event){_vm.dialogTimesheetItem = $event},"on-delete":_vm.onDeleteTimesheetItemItem},scopedSlots:_vm._u([{key:"dialogForm",fn:function(){return [_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('app-input',{ref:"timesheet_audit_plan_member",attrs:{"name":"name","type":"select","label":_vm.$t('fields.name'),"view":_vm.view,"items":_vm.memberTimesheetItems},model:{value:(_vm.editedTimesheetItemItem.audit_plan_member),callback:function ($$v) {_vm.$set(_vm.editedTimesheetItemItem, "audit_plan_member", $$v)},expression:"editedTimesheetItemItem.audit_plan_member"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('app-input',{ref:"timesheet_assigned_by",attrs:{"name":"assigned_by","type":"select","label":_vm.$t('fields.assignedBy'),"view":_vm.view,"items":_vm.memberTimesheetItems},model:{value:(_vm.editedTimesheetItemItem.assigned_by),callback:function ($$v) {_vm.$set(_vm.editedTimesheetItemItem, "assigned_by", $$v)},expression:"editedTimesheetItemItem.assigned_by"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('app-input',{ref:"timesheet_task_standard_man_day",attrs:{"name":"task_standard_man_day","type":"select-server","label":_vm.$t('fields.taskPosition'),"view":_vm.view,"binds":{
                      apiUrl: `task/task/${_vm.$route.params.id}/standard-man-day/?active=true`,
                      itemText: 'position.name'
                    }},model:{value:(_vm.editedTimesheetItemItem.task_standard_man_day),callback:function ($$v) {_vm.$set(_vm.editedTimesheetItemItem, "task_standard_man_day", $$v)},expression:"editedTimesheetItemItem.task_standard_man_day"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('app-input',{attrs:{"name":"expectedManDay","label":_vm.$t('fields.assignedManDay'),"view":_vm.view,"handleBlur":() =>
                        _vm.onTimeHrsBlur(
                          'editedTimesheetItemItem',
                          'expected_man_day',
                          _vm.editedTimesheetItemItem.expected_man_day
                        ),"handleKeyDown":_vm.onTimeHrsKeyDow},model:{value:(_vm.editedTimesheetItemItem.expected_man_day),callback:function ($$v) {_vm.$set(_vm.editedTimesheetItemItem, "expected_man_day", $$v)},expression:"editedTimesheetItemItem.expected_man_day"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('app-date-time-picker',{attrs:{"name":"date","label":_vm.$t('fields.assignedDate')},model:{value:(_vm.editedTimesheetItemItem.date),callback:function ($$v) {_vm.$set(_vm.editedTimesheetItemItem, "date", $$v)},expression:"editedTimesheetItemItem.date"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('app-input',{attrs:{"name":"actualManDay","label":_vm.$t('fields.actualManDay'),"view":_vm.view,"handleBlur":() =>
                        _vm.onTimeHrsBlur(
                          'editedTimesheetItemItem',
                          'actual_man_day',
                          _vm.editedTimesheetItemItem.actual_man_day
                        ),"handleKeyDown":_vm.onTimeHrsKeyDow},model:{value:(_vm.editedTimesheetItemItem.actual_man_day),callback:function ($$v) {_vm.$set(_vm.editedTimesheetItemItem, "actual_man_day", $$v)},expression:"editedTimesheetItemItem.actual_man_day"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('app-date-time-picker',{attrs:{"name":"actual_start_date","label":_vm.$t('fields.actualStartDate')},model:{value:(_vm.editedTimesheetItemItem.actual_start_date),callback:function ($$v) {_vm.$set(_vm.editedTimesheetItemItem, "actual_start_date", $$v)},expression:"editedTimesheetItemItem.actual_start_date"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('app-input',{attrs:{"name":"note","label":_vm.$t('fields.note'),"view":_vm.view},model:{value:(_vm.editedTimesheetItemItem.note),callback:function ($$v) {_vm.$set(_vm.editedTimesheetItemItem, "note", $$v)},expression:"editedTimesheetItemItem.note"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeTimesheetItem}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.saveTimesheetItem}},[_vm._v(" Save ")])],1)],1)]},proxy:true},{key:"foot",fn:function(){return [_c('tfoot',[_c('tr',[_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td',[_vm._v(" "+_vm._s(_vm.hoursMinutesFormat(_vm.timesheetSumAssignedManDay))+" ")]),_c('td'),_c('td'),_c('td',[_vm._v(" "+_vm._s(_vm.hoursMinutesFormat(_vm.timesheetSumActualManDay))+" ")]),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td')])])]},proxy:true}])})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }