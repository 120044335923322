<template>
  <app-menu-form
    :headers="conclusionHeaders"
    :hide-edit="mode !== 'edit'"
    :hide-delete="mode !== 'edit'"
    :loading="loading"
    :is_selecteable="false"
    :server-items="conclusionItems"
    :server-items-length="conclusionItemsCount"
    :client-items="conclusionItems"
    :events="eventHandlers"
  >
    <template v-slot:top>
      <app-menu-form-top
        title="Conclusion"
        :mode="mode"
        :dialog="dialogConclusion"
        @show-dialog="dialogConclusion = $event"
      >
        <template v-slot:dialogForm>
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" class="app-input--approve-wrapper">
                    <app-input
                      v-model="editingConclusion.report_type"
                      name="conclusion_report_type"
                      type="select"
                      :label="$t('fields.report')"
                      :view="view"
                      :items="conclusionReportTypes"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="app-input--approve-wrapper">
                    <app-input
                      v-model="editingConclusion.conclusion"
                      name="conclusion_conclusion"
                      type="html-editor"
                      :label="$t('fields.conclusion')"
                      :view="view"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="onCloseConclusion"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="onSaveConclusion"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </app-menu-form-top>
    </template>
  </app-menu-form>
</template>

<script>
import AppInput from '@components/AppInput.vue'
import AppMenuForm from '@components/AppMenuForm.vue'
import AppMenuFormTop from '@components/view/AppMenuFormTop.vue'
import { stripHtml } from '@utils/string-format'
import { defaultTableParams } from '@utils/app-table'

export default {
  name: 'app-conclusion',
  components: {
    AppInput,
    AppMenuForm,
    AppMenuFormTop
  },
  props: {
    mode: {
      type: String,
      required: true
    },
    view: {
      type: Boolean
    }
  },
  data() {
    return {
      dialogConclusion: false,
      editingConclusion: {},
      conclusionItems: [],
      conclusionItemsCount: 0,
      loading: false
    }
  },
  computed: {
    conclusionHeaders() {
      return [
        {
          text: this.$t('fields.report'),
          value: 'report_type',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.conclusion'),
          value: 'conclusion',
          groupable: false,
          sortable: true
        },
        {
          text: this.$t('fields.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    conclusionReportTypes() {
      return [
        'Auditor Report',
        'Note to Financial Statement',
        'Management Report',
        'Follow Up Report'
      ]
    },
    eventHandlers() {
      return {
        server: this.getConclusions,
        edit: this.onEditConclusion,
        delete: this.onDeleteConclusion
      }
    }
  },
  methods: {
    stripHtml: stripHtml,
    async getConclusions(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `task/task/${this.$route.params.id}/conclusion/`,
          params: params,
          hideSuccessAlert: true
        })
        this.conclusionItems = data.results
        this.conclusionItemsCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditConclusion(item) {
      this.editingConclusion = Object.assign({}, item)
      this.dialogConclusion = true
    },
    onDeleteConclusion(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task/${this.$route.params.id}/conclusion/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getConclusions()
        })
      })
      this.loading = false
    },
    onCloseConclusion() {
      this.dialogConclusion = false
      this.$nextTick(() => {
        this.editingConclusion = {}
      })
      this.loading = false
    },
    async onSaveConclusion() {
      if (
        !this.editingConclusion.report_type ||
        !this.editingConclusion.conclusion
      ) {
        return
      }
      try {
        this.loading = true
        const data = {
          report_type: this.editingConclusion.report_type,
          conclusion: this.editingConclusion.conclusion
        }

        if (this.editingConclusion.id) {
          await this.$api({
            method: 'put',
            url: `task/task/${this.$route.params.id}/conclusion/${this.editingConclusion.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `task/task/${this.$route.params.id}/conclusion/`,
            data
          })
        }
        this.onCloseConclusion()
        await this.getConclusions()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
