var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-menu-form',{attrs:{"headers":_vm.manDayHeaders,"hide-edit":_vm.mode !== 'edit',"hide-delete":_vm.mode !== 'edit',"is-selecteable":true,"server-items":_vm.manDaysItems,"server-items-length":_vm.manDaysItemsCount,"client-items":_vm.manDaysItems,"events":_vm.eventHandlers},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('app-menu-form-top',{attrs:{"mode":_vm.mode,"title":_vm.$t('menu.standardManDay'),"dialog":_vm.dialogManDay,"selected-length":_vm.manDaySelected.length,"dialog-max-width":"600px","on-delete":_vm.onDeleteManDay,"on-clicked":_vm.onEditManDay},on:{"show-dialog":function($event){_vm.dialogManDay = $event}},scopedSlots:_vm._u([{key:"dialogForm",fn:function(){return [_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('app-input',{attrs:{"name":"position","label":_vm.$t('fields.position'),"view":_vm.view,"type":"select-server","binds":{
                      apiUrl:
                        'audit/master-position/?active=true&state=approved'
                    }},model:{value:(_vm.editingManDay.position),callback:function ($$v) {_vm.$set(_vm.editingManDay, "position", $$v)},expression:"editingManDay.position"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('app-input',{attrs:{"name":"man_day","rules":"required","label":_vm.$t('fields.manDay'),"view":_vm.view,"handleBlur":() =>
                        _vm.onTimeHrsBlur(
                          'editingManDay',
                          'man_day',
                          _vm.editingManDay.man_day
                        ),"handleKeyDown":_vm.onTimeHrsKeyDow},model:{value:(_vm.editingManDay.man_day),callback:function ($$v) {_vm.$set(_vm.editingManDay, "man_day", $$v)},expression:"editingManDay.man_day"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('app-input',{attrs:{"name":"note","type":"text","label":_vm.$t('fields.note'),"view":_vm.view},model:{value:(_vm.editingManDay.note),callback:function ($$v) {_vm.$set(_vm.editingManDay, "note", $$v)},expression:"editingManDay.note"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onCloseManDay}},[_vm._v(" "+_vm._s(_vm.$t('btn.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onSaveManDay}},[_vm._v(" "+_vm._s(_vm.$t('btn.save'))+" ")])],1)],1)]},proxy:true}])})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }