<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Subsidiary Account</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-btn
        color="primary"
        dark
        v-show="mode === 'edit'"
        @click="onSubsidiaryBiasClick"
        >Bias
      </v-btn>
      <v-btn
        color="primary"
        dark
        v-show="mode === 'edit'"
        @click="onSubsidiaryNonBiasClick"
        >Non-bias
      </v-btn>
      <v-btn
        color="error"
        dark
        v-show="mode === 'edit'"
        @click="onResetSubsidiaryBiasClick"
        >Reset
      </v-btn>
      <v-spacer></v-spacer>
      <slot name="actions">
        <slot name="prefix-actions" />
        <v-btn
          color="primary"
          dark
          v-show="mode === 'edit'"
          @click="onEditSubsidiary()"
        >
          {{ $t('btn.newItem') }}
        </v-btn>
        <v-btn
          color="primary"
          dark
          v-show="mode === 'edit'"
          @click="onImportSubsidiary()"
        >
          {{ $t('btn.import') }}
        </v-btn>
        <v-btn
          v-show="subsidiarySelected.length && mode === 'edit'"
          color="error"
          @click="onDeleteSubsidiary(subsidiarySelected)"
        >
          {{ $t('btn.delete') }}
        </v-btn>
      </slot>
    </v-toolbar>
    <v-card flat>
      <v-card-text>
        <app-table
          server-side
          model="task"
          app="audit"
          :headers="subsidiaryHeaders"
          :hide-edit="mode !== 'edit'"
          :hide-delete="mode !== 'edit'"
          v-model="subsidiarySelected"
          :server-items="subsidiaryItemItems"
          :server-items-length="subsidiaryItemServerItemsLength"
          @server="getSubsidiary"
          @edit="onEditSubsidiary"
          @delete="onDeleteSubsidiary"
        >
          <template v-slot:[`item.subtask`]="{ item }">
            <template v-if="item.subtask">
              <router-link
                :to="{
                  name: 'taskEdit',
                  params: { id: item.subtask.id },
                  query: {
                    task_subsidiary_id: item.id,
                    task_type: formData.task_type,
                    audit_plan_id: $route.query.audit_plan_id,
                    audit_id: $route.query.audit_id,
                    from: $route.query.from
                  }
                }"
                target="_blank"
              >
                <v-icon color="primary">mdi-link</v-icon>
              </router-link>
            </template>
            <template v-else>
              <router-link
                :to="{
                  name: 'taskCreate',
                  query: {
                    task_subsidiary_id: item.id,
                    task_type: formData.task_type,
                    audit_plan_id: $route.query.audit_plan_id,
                    audit_id: $route.query.audit_id,
                    from: $route.query.from,
                    parent_task_id: $route.params.id
                  }
                }"
              >
                <v-icon color="green">mdi-link</v-icon>
              </router-link>
            </template>
          </template>
          <template v-slot:foot>
            <tfoot>
              <tr>
                <td style="text-align: right">
                  <strong>Total</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {{ accountingFormat(subsidiarySumBeginningBalance) }}
                </td>
                <td>
                  {{ accountingFormat(subsidiarySumDebit) }}
                </td>
                <td>
                  {{ accountingFormat(subsidiarySumCredit) }}
                </td>
                <td>
                  {{ accountingFormat(subsidiarySumActualBeginningBalance) }}
                </td>
                <td>
                  {{ accountingFormat(subsidiarySumActualDebit) }}
                </td>
                <td>
                  {{ accountingFormat(subsidiarySumActualCredit) }}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tfoot>
          </template>
        </app-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialogSubsidiary" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="account_code"
                  type="select-server"
                  :label="$t('fields.accountCode')"
                  v-model="editedSubsidiary.account_code"
                  :view="view"
                  :binds="{
                    apiUrl: `chart-of-account/chart-of-account-detail/?state=approved&active=true&audit_id=${$route.query.audit_id}`,
                    itemText: (item) => `${item.code} ${item.name}`
                  }"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="date"
                  type="datepicker"
                  :label="$t('fields.date')"
                  v-model="editedSubsidiary.date"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="journal"
                  type="text"
                  :label="$t('fields.journal')"
                  v-model="editedSubsidiary.journal"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="document_no"
                  type="text"
                  :label="$t('fields.docNo')"
                  v-model="editedSubsidiary.document_no"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="partner_code"
                  type="text"
                  :label="$t('fields.partnerCode')"
                  v-model="editedSubsidiary.partner_code"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="partner_name"
                  type="text"
                  :label="$t('fields.partnerName')"
                  v-model="editedSubsidiary.partner_name"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="partner_branch"
                  type="text"
                  :label="$t('fields.partnerBranch')"
                  v-model="editedSubsidiary.partner_branch"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="detail"
                  type="text"
                  :label="$t('fields.detail')"
                  v-model="editedSubsidiary.detail"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="note"
                  type="text"
                  :label="$t('fields.note')"
                  v-model="editedSubsidiary.note"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="beginning_balance"
                  type="number"
                  :label="$t('fields.beginningBalance')"
                  v-model="editedSubsidiary.beginning_balance"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="debit"
                  type="number"
                  :label="$t('fields.debit')"
                  v-model="editedSubsidiary.debit"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="credit"
                  type="number"
                  :label="$t('fields.credit')"
                  v-model="editedSubsidiary.credit"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="actual_beginning_balance"
                  type="number"
                  :label="$t('fields.actualBeginningBalance')"
                  v-model="editedSubsidiary.actual_beginning_balance"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="actual_debit"
                  type="number"
                  :label="$t('fields.actualDebit')"
                  v-model="editedSubsidiary.actual_debit"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="actual_credit"
                  type="number"
                  :label="$t('fields.actualCredit')"
                  v-model="editedSubsidiary.actual_credit"
                  :view="view"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="10">
                <app-input
                  name="branch"
                  type="text"
                  :label="$t('fields.branch')"
                  v-model="editedSubsidiary.branch"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  v-model="editedSubsidiary.business_unit"
                  name="business_unit"
                  type="select-server"
                  :label="$t('fields.businessUnit')"
                  :view="view"
                  :binds="{
                    apiUrl: `contact/business-unit/?active=true&contact_id=${formData.contact_id.id}`
                  }"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  v-model="editedSubsidiary.status"
                  name="status"
                  type="select-server"
                  :label="$t('fields.status')"
                  :view="view"
                  :binds="{
                    apiUrl: 'audit/task-subsidiary-account-status'
                  }"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  v-model="editedSubsidiary.dummy"
                  name="dummy"
                  type="switch"
                  :label="$t('fields.dummy')"
                  :view="view"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCloseSubsidiary">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onSaveSubsidiary">
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSubsidiaryImport" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <app-xlsx-import @change="onSubsidiaryImportFileChange" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <v-btn color="blue darken-1" text :href="subsidiaryTemplateUrl">
                  Download Template
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                {{ tempSubsidiaryImportStatus }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCloseImportSubsidiary">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onImportSubsidiaryDo">
            {{ $t('btn.import') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSubsidiaryBias" max-width="700px">
      <v-card>
        <v-toolbar flat>
          <slot name="actions">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              v-show="mode === 'edit'"
              @click="onEditSubsidiaryBiasCondition()"
            >
              {{ $t('btn.newItem') }}
            </v-btn>
          </slot>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <app-table
                  server-side
                  model="task"
                  app="audit"
                  :is-selecteable="false"
                  :headers="subsidiaryBiasHeaders"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :server-items="subsidiaryBiasItemItems"
                  :server-items-length="subsidiaryBiasItemServerItemsLength"
                  @server="getSubsidiaryBiasItems"
                  @edit="onEditSubsidiaryBiasCondition"
                  @delete="onDeleteSubsidiaryBiasCondition"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onSubsidiaryBiasClose">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onSubsidiaryBiasSave">
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSubsidiaryBiasCondition" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="field"
                  rules="required"
                  type="select-server"
                  :label="$t('fields.field')"
                  :view="view"
                  :binds="{
                    apiUrl: 'audit/task-subsidiary-account-bias-field/'
                  }"
                  v-model="editedSubsidiaryBiasCondition.field"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="operator"
                  rules="required"
                  type="select-server"
                  :label="$t('fields.operator')"
                  :view="view"
                  :binds="{
                    apiUrl: 'audit/task-subsidiary-account-bias-operator/'
                  }"
                  v-model="editedSubsidiaryBiasCondition.operator"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5">
                <app-input
                  name="value1"
                  type="text"
                  :label="$t('fields.value1')"
                  :view="view"
                  v-model="editedSubsidiaryBiasCondition.value1"
                />
              </v-col>
              <v-col cols="5">
                <app-input
                  name="value2"
                  type="text"
                  :label="$t('fields.value2')"
                  :disabled="
                    editedSubsidiaryBiasCondition.operator !== 'Between' &&
                    editedSubsidiaryBiasCondition.operator !== 'Not Between'
                  "
                  :rules="{
                    required: (value) => {
                      if (
                        editedSubsidiaryBiasCondition.operator !== 'Between' &&
                        editedSubsidiaryBiasCondition.operator !== 'Not Between'
                      )
                        return true
                      if (value) return true

                      return 'You must enter a value2.'
                    }
                  }"
                  v-model="editedSubsidiaryBiasCondition.value2"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="reason"
                  type="text"
                  :label="$t('fields.reason')"
                  :view="view"
                  v-model="editedSubsidiaryBiasCondition.reason"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="onSubsidiaryBiasConditionClose"
          >
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="onSubsidiaryBiasConditionSave"
          >
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSubsidiaryNonBias" max-width="600px">
      <v-card>
        <v-toolbar flat>
          <slot name="actions">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              v-show="mode === 'edit'"
              @click="onEditSubsidiaryNonBiasCondition()"
            >
              {{ $t('btn.newItem') }}
            </v-btn>
          </slot>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <app-table
                  server-side
                  model="task"
                  app="audit"
                  :headers="subsidiaryNonBiasHeaders"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :is-selecteable="false"
                  :server-items="subsidiaryNonBiasItemItems"
                  :server-items-length="subsidiaryNonBiasItemServerItemsLength"
                  @server="getSubsidiaryNonBiasItems"
                  @edit="onEditSubsidiaryNonBiasCondition"
                  @delete="onDeleteSubsidiaryNonBiasCondition"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onSubsidiaryNonBiasClose">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onSubsidiaryNonBiasSave">
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSubsidiaryNonBiasCondition" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="sampling_size"
                  rules="required"
                  type="number"
                  :label="$t('fields.samplingSize')"
                  :view="view"
                  v-model="editedSubsidiaryNonBiasCondition.sampling_size"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="sampling_method"
                  rules="required"
                  type="select-server"
                  :label="$t('fields.samplingMethod')"
                  :view="view"
                  :binds="{
                    apiUrl:
                      'audit/task-subsidiary-account-non-bias-sampling-method/'
                  }"
                  v-model="editedSubsidiaryNonBiasCondition.sampling_method"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="onSubsidiaryNonBiasConditionClose"
          >
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="onSubsidiaryNonBiasConditionSave"
          >
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppTable from '@components/AppTable.vue'
import AppInput from '@components/AppInput.vue'
import AppXlsxImport from '@components/AppXlsxImport.vue'
import { defaultTableParams } from '@utils/app-table'
import _ from 'lodash'
import Papa from 'papaparse'
import readXlsxFile from 'read-excel-file'
import { DateTime } from 'luxon'
import { accounting } from '@utils/number-format'

export default {
  name: 'app-questionnaire',
  components: {
    AppTable,
    AppInput,
    AppXlsxImport
  },
  props: {
    mode: {
      type: String,
      required: true
    },
    view: {
      type: Boolean,
      default: false
    },
    formData: {
      type: Object
    }
  },
  data() {
    return {
      subsidiarySelected: [],
      editedSubsidiary: [],
      editedSubsidiaryBiasCondition: [],
      editedSubsidiaryNonBiasCondition: [],
      dialogSubsidiary: false,
      dialogSubsidiaryImport: false,
      dialogSubsidiaryBias: false,
      dialogSubsidiaryBiasCondition: false,
      dialogSubsidiaryNonBias: false,
      dialogSubsidiaryNonBiasCondition: false,
      subsidiaryItemItems: [],
      subsidiaryItemServerItemsLength: 0,
      subsidiarySumDebit: 0,
      subsidiarySumCredit: 0,
      subsidiarySumActualDebit: 0,
      subsidiarySumActualCredit: 0,
      subsidiarySumBeginningBalance: 0,
      subsidiarySumActualBeginningBalance: 0,
      tempSubsidiaryImports: [],
      subsidiaryImportForm: {},
      subsidiaryBiasItemItems: [],
      subsidiaryBiasItemServerItemsLength: 0,
      subsidiaryNonBiasItemItems: [],
      subsidiaryNonBiasItemServerItemsLength: 0,
      loading: false
    }
  },
  computed: {
    subsidiaryHeaders() {
      return [
        {
          text: this.$t('fields.accountCode'),
          value: 'account_code'
        },
        {
          text: this.$t('fields.date'),
          value: 'date'
        },
        {
          text: this.$t('fields.journal'),
          value: 'journal'
        },
        {
          text: this.$t('fields.docNo'),
          value: 'document_no'
        },
        {
          text: this.$t('fields.partnerCode'),
          value: 'partner_code'
        },
        {
          text: this.$t('fields.partnerName'),
          value: 'partner_name'
        },
        {
          text: this.$t('fields.partnerBranch'),
          value: 'partner_branch'
        },
        {
          text: this.$t('fields.detail'),
          value: 'detail'
        },
        {
          text: this.$t('fields.note'),
          value: 'note'
        },
        {
          text: this.$t('fields.beginningBalance'),
          value: 'beginning_balance',
          accountFormat: true
        },
        {
          text: this.$t('fields.debit'),
          value: 'debit',
          accountFormat: true
        },
        {
          text: this.$t('fields.credit'),
          value: 'credit',
          accountFormat: true
        },
        {
          text: this.$t('fields.actualBeginningBalance'),
          value: 'actual_beginning_balance',
          accountFormat: true
        },
        {
          text: this.$t('fields.actualDebit'),
          value: 'actual_debit',
          accountFormat: true
        },
        {
          text: this.$t('fields.actualCredit'),
          value: 'actual_credit',
          accountFormat: true
        },
        {
          text: this.$t('fields.businessUnit'),
          value: 'business_unit.name'
        },
        {
          text: this.$t('fields.branch'),
          value: 'branch'
        },
        {
          text: this.$t('fields.status'),
          value: 'status'
        },
        {
          text: this.$t('fields.samplingType'),
          value: 'sampling_type'
        },
        {
          text: this.$t('fields.dummy'),
          value: 'dummy',
          check: true,
          filterMode: 'exact',
          filterType: 'boolean'
        },
        {
          text: 'Subtask',
          value: 'subtask',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    tempSubsidiaryImportStatus() {
      if (!this.tempSubsidiaryImports || !this.tempSubsidiaryImports.length) {
        return null
      }
      return `${this.tempSubsidiaryImports.length} records will be imported`
    },
    subsidiaryTemplateUrl() {
      return `${process.env.VUE_APP_BASE_API}static/template/SUBSIDIARY%20ACCOUNT%20Template.xlsx`
    },
    subsidiaryBiasHeaders() {
      return [
        {
          text: this.$t('fields.field'),
          value: 'field',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.operator'),
          value: 'operator',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.value1'),
          value: 'value1',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.value2'),
          value: 'value2',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.reason'),
          value: 'reason',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    subsidiaryNonBiasHeaders() {
      return [
        {
          text: this.$t('fields.samplingSize'),
          value: 'sampling_size',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.samplingMethod'),
          value: 'sampling_method',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    }
  },
  methods: {
    accountingFormat: accounting,
    onSubsidiaryBiasClick() {
      this.dialogSubsidiaryBias = true
    },
    onSubsidiaryNonBiasClick() {
      this.dialogSubsidiaryNonBias = true
    },
    async onResetSubsidiaryBiasClick() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear all Sampling Type on Subsidiary Account. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/subsidiary-account/reset/`,
            data: {}
          })
          await this.getSubsidiary()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    onEditSubsidiary(item) {
      this.editedSubsidiary = Object.assign({}, item)
      this.dialogSubsidiary = true
    },
    onImportSubsidiary() {
      this.subsidiaryImportForm = {}
      this.dialogSubsidiaryImport = true
    },
    onDeleteSubsidiary(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/subsidiary-account/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.subsidiarySelected = []
          this.getSubsidiary()
        })
      })
      this.loading = false
    },
    async getSubsidiary(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/subsidiary-account/`,
          params: params,
          hideSuccessAlert: true
        })
        this.subsidiaryItemItems = data.results
        this.subsidiaryItemServerItemsLength = data.count
        this.subsidiarySumDebit = data.sum_debit ?? 0
        this.subsidiarySumCredit = data.sum_credit ?? 0
        this.subsidiarySumActualDebit = data.sum_actual_debit ?? 0
        this.subsidiarySumActualCredit = data.sum_actual_credit ?? 0
        this.subsidiarySumBeginningBalance = data.sum_beginning_balance ?? 0
        this.subsidiarySumActualBeginningBalance =
          data.sum_actual_beginning_balance ?? 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async onSaveSubsidiary() {
      try {
        this.loading = true
        const data = {
          account_code: this.editedSubsidiary.account_code.code,
          date: this.editedSubsidiary.date,
          journal: this.editedSubsidiary.journal,
          document_no: this.editedSubsidiary.document_no,
          partner_code: this.editedSubsidiary.partner_code,
          partner_name: this.editedSubsidiary.partner_name,
          partner_branch: this.editedSubsidiary.partner_branch,
          detail: this.editedSubsidiary.detail,
          note: this.editedSubsidiary.note,
          beginning_balance: this.editedSubsidiary.beginning_balance,
          debit: this.editedSubsidiary.debit,
          credit: this.editedSubsidiary.credit,
          actual_beginning_balance:
            this.editedSubsidiary.actual_beginning_balance,
          actual_debit: this.editedSubsidiary.actual_debit,
          actual_credit: this.editedSubsidiary.actual_credit,
          business_unit:
            this.editedSubsidiary.business_unit?.id ??
            this.editedSubsidiary.business_unit,
          branch: this.editedSubsidiary.branch,
          status: this.editedSubsidiary.status,
          dummy: this.editedSubsidiary.dummy
        }

        if (this.editedSubsidiary.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/subsidiary-account/${this.editedSubsidiary.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/subsidiary-account/`,
            data
          })
        }
        this.onCloseSubsidiary()
        await this.getSubsidiary()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onSubsidiaryImportFileChange(val) {
      if (val) {
        if (val.type === 'text/csv') {
          this.tempSubsidiaryImports = []
          Papa.parse(val, {
            complete: (results) => {
              for (let i = 1; i < results.data.length; i++) {
                const row = results.data[i]
                if (row.length !== 19) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 14. It has ${row.length} columns.`
                  })
                  return
                }
                this.tempSubsidiaryImports.push({
                  account_code: row[0],
                  date: DateTime.fromFormat(row[1], 'dd/LL/yyyy').toFormat(
                    'yyyy-LL-dd'
                  ),
                  journal: row[2],
                  document_no: row[3],
                  partner_code: row[4],
                  partner_name: row[5],
                  partner_branch: row[6],
                  detail: row[7],
                  note: row[8],
                  beginning_balance: row[9]?.trim().replace(/[,-]/g, ''),
                  debit: row[10]?.trim().replace(/[,-]/g, ''),
                  credit: row[11]?.trim().replace(/[,-]/g, ''),
                  actual_beginning_balance: row[12]
                    ?.trim()
                    .replace(/[,-]/g, ''),
                  actual_debit: row[13]?.trim().replace(/[,-]/g, ''),
                  actual_credit: row[14]?.trim().replace(/[,-]/g, ''),
                  business_unit: row[15],
                  branch: row[16],
                  status: row[17].trim(),
                  sampling_type: null,
                  dummy: row[18]
                })
              }
            }
          })
        } else {
          this.tempSubsidiaryImports = []
          let i = 0
          readXlsxFile(val)
            .then((rows) => {
              for (i = 1; i < rows.length; i++) {
                if (rows[i].length !== 19) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 14. It has ${rows[i].length} columns.`
                  })
                  return
                }
                const row = rows[i]
                this.tempSubsidiaryImports.push({
                  account_code: row[0],
                  date: row[1]
                    ? DateTime.fromJSDate(row[1]).toFormat('yyyy-LL-dd')
                    : null,
                  journal: row[2],
                  document_no: row[3],
                  partner_code: row[4],
                  partner_name: row[5],
                  partner_branch: row[6],
                  detail: row[7],
                  note: row[8],
                  beginning_balance: row[9],
                  debit: row[10],
                  credit: row[11],
                  actual_beginning_balance: row[12],
                  actual_debit: row[13],
                  actual_credit: row[14],
                  business_unit: row[15],
                  branch: row[16],
                  status: row[17],
                  sampling_type: null,
                  dummy: row[18]
                })
              }
            })
            .catch((e) => {
              this.$alert('custom', {
                icon: 'error',
                title: this.$t('alert.validationError'),
                text: e.message
              })
            })
        }
      }
    },
    onCloseImportSubsidiary() {
      this.subsidiaryImportForm = {}
      this.dialogSubsidiaryImport = false
    },
    async onImportSubsidiaryDo() {
      try {
        this.loading = true
        const data = this.tempSubsidiaryImports
        await this.$api({
          method: 'post',
          url: `audit/task/${this.$route.params.id}/subsidiary-account/import_xlsx/`,
          data,
          hideErrorAlert: true
        })

        this.onCloseImportSubsidiary()
        await this.getSubsidiary()
      } catch (e) {
        console.log(e)
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: _.truncate(
            _.filter(
              _.map(e.data, (row, i) =>
                _.map(row, (value, key) => `Row ${i + 1} ${key}: ${value}\n`)
              ),
              (row) => row.length > 0
            ),
            { length: 100 }
          )
        })
      } finally {
        this.loading = false
      }
    },
    onEditSubsidiaryBiasCondition(item) {
      this.editedSubsidiaryBiasCondition = Object.assign({}, item)
      this.dialogSubsidiaryBiasCondition = true
    },
    onSubsidiaryBiasClose() {
      this.dialogSubsidiaryBias = false
    },
    async onSubsidiaryBiasSave() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear all Sampling Type on Subsidiary Account. You must also run Non-bias again after this. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/subsidiary-account/compute_bias/`,
            data: {}
          })
          this.onSubsidiaryBiasClose()
          await this.getSubsidiary()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    onSubsidiaryBiasConditionClose() {
      this.dialogSubsidiaryBiasCondition = false
    },
    async onSubsidiaryBiasConditionSave() {
      try {
        this.loading = true
        const data = {
          field: this.editedSubsidiaryBiasCondition.field,
          operator: this.editedSubsidiaryBiasCondition.operator,
          value1: this.editedSubsidiaryBiasCondition.value1,
          reason: this.editedSubsidiaryBiasCondition.reason
        }
        if (
          this.editedSubsidiaryBiasCondition.operator === 'Between' ||
          this.editedSubsidiaryBiasCondition.operator === 'Not Between'
        ) {
          data.value2 = this.editedSubsidiaryBiasCondition.value2
        }
        if (this.editedSubsidiaryBiasCondition.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/subsidiary-account-bias/${this.editedSubsidiaryBiasCondition.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/subsidiary-account-bias/`,
            data
          })
        }
        this.onSubsidiaryBiasConditionClose()
        await this.getSubsidiaryBiasItems()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getSubsidiaryBiasItems(options) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/subsidiary-account-bias/`,
          params: params,
          hideSuccessAlert: true
        })
        this.subsidiaryBiasItemItems = data.results
        this.subsidiaryBiasItemServerItemsLength = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async onDeleteSubsidiaryBiasCondition(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/subsidiary-account-bias/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getSubsidiaryBiasItems()
        })
      })
      this.loading = false
    },
    async getSubsidiaryNonBiasItems(options) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/subsidiary-account-non-bias/`,
          params: params,
          hideSuccessAlert: true
        })
        this.subsidiaryNonBiasItemItems = data.results
        this.subsidiaryNonBiasItemServerItemsLength = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditSubsidiaryNonBiasCondition(item) {
      this.editedSubsidiaryNonBiasCondition = Object.assign({}, item)
      this.dialogSubsidiaryNonBiasCondition = true
    },
    async onDeleteSubsidiaryNonBiasCondition(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/subsidiary-account-non-bias/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getSubsidiaryNonBiasItems()
        })
      })
      this.loading = false
    },
    onSubsidiaryNonBiasClose() {
      this.dialogSubsidiaryNonBias = false
    },
    async onSubsidiaryNonBiasSave() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear Non-Bias Sampling Type on Subsidiary Account and re-assign them. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/subsidiary-account/compute_non_bias/`,
            data: {}
          })
          this.onSubsidiaryNonBiasClose()
          await this.getSubsidiary()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    onSubsidiaryNonBiasConditionClose() {
      this.dialogSubsidiaryNonBiasCondition = false
    },
    async onSubsidiaryNonBiasConditionSave() {
      try {
        this.loading = true
        const data = {
          sampling_size: this.editedSubsidiaryNonBiasCondition.sampling_size,
          sampling_method: this.editedSubsidiaryNonBiasCondition.sampling_method
        }

        if (this.editedSubsidiaryNonBiasCondition.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/subsidiary-account-non-bias/${this.editedSubsidiaryNonBiasCondition.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/subsidiary-account-non-bias/`,
            data
          })
        }

        this.onSubsidiaryNonBiasConditionClose()
        await this.getSubsidiaryNonBiasItems()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onCloseSubsidiary() {
      this.dialogSubsidiary = false
      this.$nextTick(() => {
        this.editedSubsidiary = {}
      })
      this.loading = false
    }
  }
}
</script>
