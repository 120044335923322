<template>
  <v-card flat>
    <v-card-text>
      <app-input
        name="Description"
        type="html-editor"
        :label="$t('fields.description')"
        :view="view"
        v-model="description"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import AppInput from '@components/AppInput'

export default {
  name: 'app-task-description',
  components: {
    AppInput
  },
  props: {
    description: String,
    view: Boolean
  }
}
</script>
