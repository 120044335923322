var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-menu-form',{attrs:{"title":_vm.$t('menu.requiredDocument'),"mode":_vm.mode,"dialog":_vm.dialogRequiredDocument,"hide-edit":_vm.mode !== 'edit',"hide-delete":_vm.mode !== 'edit',"loading":_vm.loading,"headers":_vm.requiredDocumentHeader,"server-items":_vm.requiredDocumentItems,"client-items":_vm.requiredDocumentItems,"server-items-length":_vm.requiredDocumentItemsLength,"events":_vm.eventHandlers},scopedSlots:_vm._u([{key:"item.personal_document_id",fn:function({ item }){return [(item.personal_document_id)?[_c('router-link',{attrs:{"to":{
          name:
            item.personal_document_id.type === 'company'
              ? 'otherDocumentEdit'
              : 'personalDocumentEdit',
          params: { id: item.personal_document_id.id }
        },"target":"_blank"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-link")])],1)]:_vm._e()]}},{key:"top",fn:function(){return [_c('app-menu-form-top',{attrs:{"title":_vm.$t('menu.requiredDocument'),"mode":_vm.mode,"dialog":_vm.dialogRequiredDocument,"selectedLength":_vm.requiredDocumentSelected.length,"onDelete":_vm.onDelete},on:{"show-dialog":function($event){_vm.dialogRequiredDocument = $event}},scopedSlots:_vm._u([{key:"dialogForm",fn:function(){return [_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('app-input',{attrs:{"name":"documentTemplate","rules":"required","type":"select-server","label":_vm.$t('fields.requiredDocument'),"view":_vm.view,"binds":{
                      apiUrl: 'doc-template/document/?active=true'
                    }},model:{value:(_vm.editedItem.document_template_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "document_template_id", $$v)},expression:"editedItem.document_template_id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('app-input',{attrs:{"name":"personal_document","type":"select-server","label":_vm.$t('label.selectRequiredDocument'),"view":_vm.view,"binds":{
                      apiUrl:
                        _vm.editedItem.document_template_id && _vm.contactId
                          ? `contact/personal-doc/?document_template_id=${_vm.editedItem.document_template_id.id}&contact_ids=${_vm.contactId.id}&state=approved&active=true`
                          : '',
                      itemText: 'document_name'
                    }},model:{value:(_vm.editedItem.personal_document_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "personal_document_id", $$v)},expression:"editedItem.personal_document_id"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('app-input',{attrs:{"name":"contact_business_unit","type":"select-server","label":_vm.$t('fields.businessUnit'),"view":_vm.view,"binds":{
                      apiUrl: _vm.contactId
                        ? `contact/contact-business-unit/?contact_id=${_vm.contactId.id}`
                        : '',
                      itemText: 'business_unit_id.name'
                    }},model:{value:(_vm.editedItem.contact_business_unit_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "contact_business_unit_id", $$v)},expression:"editedItem.contact_business_unit_id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('app-input',{attrs:{"name":"contact_branch","type":"select-server","label":_vm.$t('fields.branch'),"view":_vm.view,"binds":{
                      apiUrl: _vm.contactId
                        ? `contact/contact-branch?contact_id=${_vm.contactId.id}`
                        : ''
                    }},model:{value:(_vm.editedItem.contact_branch_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "contact_branch_id", $$v)},expression:"editedItem.contact_branch_id"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeRequiredDocument}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.saveRequiredDocument}},[_vm._v(" Save ")])],1)],1)]},proxy:true}])})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }