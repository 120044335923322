<template>
  <app-menu-form
    :headers="manDayHeaders"
    :hide-edit="mode !== 'edit'"
    :hide-delete="mode !== 'edit'"
    :is-selecteable="true"
    :server-items="manDaysItems"
    :server-items-length="manDaysItemsCount"
    :client-items="manDaysItems"
    :events="eventHandlers"
  >
    <template v-slot:top>
      <app-menu-form-top
        :mode="mode"
        :title="$t('menu.standardManDay')"
        :dialog="dialogManDay"
        :selected-length="manDaySelected.length"
        dialog-max-width="600px"
        @show-dialog="dialogManDay = $event"
        :on-delete="onDeleteManDay"
        :on-clicked="onEditManDay"
      >
        <template v-slot:dialogForm>
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <app-input
                      name="position"
                      :label="$t('fields.position')"
                      :view="view"
                      type="select-server"
                      v-model="editingManDay.position"
                      :binds="{
                        apiUrl:
                          'audit/master-position/?active=true&state=approved'
                      }"
                    />
                  </v-col>
                  <v-col cols="6">
                    <app-input
                      name="man_day"
                      rules="required"
                      :label="$t('fields.manDay')"
                      :view="view"
                      :handleBlur="
                        () =>
                          onTimeHrsBlur(
                            'editingManDay',
                            'man_day',
                            editingManDay.man_day
                          )
                      "
                      :handleKeyDown="onTimeHrsKeyDow"
                      v-model="editingManDay.man_day"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <app-input
                      v-model="editingManDay.note"
                      name="note"
                      type="text"
                      :label="$t('fields.note')"
                      :view="view"
                  /></v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="onCloseManDay">
                {{ $t('btn.cancel') }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="onSaveManDay">
                {{ $t('btn.save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </app-menu-form-top>
    </template>
  </app-menu-form>
</template>

<script>
import AppInput from '@components/AppInput.vue'
import AppMenuForm from '@components/AppMenuForm.vue'
import AppMenuFormTop from '@components/view/AppMenuFormTop.vue'
import { defaultTableParams } from '@utils/app-table'
import { onTimeHrsKeyDow, onTimeHrsBlur, timeHrsAutoConvert } from '@utils/time'

export default {
  name: 'app-standard-manday',
  components: {
    AppInput,
    AppMenuForm,
    AppMenuFormTop
  },
  props: {
    mode: {
      type: String,
      required: true
    },
    view: {
      type: Boolean
    }
  },
  data() {
    return {
      timesheetSumAssignedManDay: 0,
      timesheetSumActualManDay: 0,
      editingManDay: {},
      dialogManDay: false,
      manDaySelected: [],
      manDaysItems: [],
      manDaysItemsCount: 0,
      loading: false
    }
  },
  computed: {
    manDayHeaders() {
      return [
        {
          text: this.$t('fields.position'),
          value: 'position.name'
        },
        {
          text: this.$t('fields.manDay'),
          value: 'man_day',
          hourFormat: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    eventHandlers() {
      return {
        server: this.getManDays,
        edit: this.onEditManDay,
        delete: this.onDeleteManDay
      }
    }
  },
  methods: {
    onTimeHrsKeyDow: onTimeHrsKeyDow,
    onTimeHrsBlur: onTimeHrsBlur,
    timeHrsAutoConvert: timeHrsAutoConvert,
    async getManDays(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `task/task/${this.$route.params.id}/standard-man-day/`,
          params: params,
          hideSuccessAlert: true
        })
        this.manDaysItemsCount = data.count
        this.manDaysItems = data.results
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditManDay(item) {
      this.editingManDay = Object.assign({}, item)
      if (this.editingManDay.man_day) {
        this.editingManDay.man_day = this.timeHrsAutoConvert(
          this.editingManDay.man_day
        )
      }
      this.dialogManDay = true
    },
    onCloseManDay() {
      this.dialogManDay = false
      this.$nextTick(() => {
        this.editingManDay = {}
      })
      this.loading = false
    },
    onDeleteManDay(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task/${this.$route.params.id}/standard-man-day/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.manDaySelected = []
          this.getManDays()
        })
      })
      this.loading = false
    },
    async onSaveManDay() {
      if (this.editingManDay.position) {
        this.loading = true
        const data = {}
        data.task = this.$route.params.id
        data.position =
          this.editingManDay.position.id ?? this.editingManDay.position
        data.man_day = this.timeHrsAutoConvert(this.editingManDay.man_day, true)
        data.note = this.editingManDay.note

        await this.$api({
          method: this.editingManDay.id ? 'put' : 'post',
          url: this.editingManDay.id
            ? `task/task/${this.$route.params.id}/standard-man-day/${this.editingManDay.id}/`
            : `task/task/${this.$route.params.id}/standard-man-day/`,
          data
        })
        this.editingManDay = {}
        this.onCloseManDay()
        this.getManDays()
      }
    }
  }
}
</script>
