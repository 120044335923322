<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Stock Value Outstanding</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-btn
        color="primary"
        dark
        v-show="mode === 'edit'"
        @click="onStockValueBiasClick"
        >Bias
      </v-btn>
      <v-btn
        color="primary"
        dark
        v-show="mode === 'edit'"
        @click="onStockValueNonBiasClick"
        >Non-bias
      </v-btn>
      <v-btn
        color="error"
        dark
        v-show="mode === 'edit'"
        @click="onResetStockValueBiasClick"
        >Reset
      </v-btn>
      <v-spacer></v-spacer>
      <slot name="actions">
        <slot name="prefix-actions" />
        <v-btn
          color="primary"
          dark
          v-show="mode === 'edit'"
          @click="onEditStockValue()"
        >
          {{ $t('btn.newItem') }}
        </v-btn>
        <v-btn
          color="primary"
          dark
          v-show="mode === 'edit'"
          @click="onImportStockValue()"
        >
          {{ $t('btn.import') }}
        </v-btn>
        <v-btn
          v-show="stockValueSelected.length && mode === 'edit'"
          color="error"
          @click="onDeleteStockValue(stockValueSelected)"
        >
          {{ $t('btn.delete') }}
        </v-btn>
      </slot>
    </v-toolbar>
    <v-card flat>
      <v-card-text>
        <app-table
          server-side
          model="task"
          app="audit"
          :headers="stockValueHeaders"
          :hide-edit="mode !== 'edit'"
          :hide-delete="mode !== 'edit'"
          v-model="stockValueSelected"
          :server-items="stockValueItemItems"
          :server-items-length="stockValueItemServerItemsLength"
          @server="getStockValue"
          @edit="onEditStockValue"
          @delete="onDeleteStockValue"
        >
          <template v-slot:[`item.subtask`]="{ item }">
            <template v-if="item.subtask">
              <router-link
                :to="{
                  name: 'taskEdit',
                  params: { id: item.subtask.id },
                  query: {
                    task_stock_value_id: item.id,
                    task_type: formData.task_type,
                    audit_plan_id: $route.query.audit_plan_id,
                    audit_id: $route.query.audit_id,
                    from: $route.query.from
                  }
                }"
                target="_blank"
              >
                <v-icon color="primary">mdi-link</v-icon>
              </router-link>
            </template>
            <template v-else>
              <router-link
                :to="{
                  name: 'taskCreate',
                  query: {
                    task_stock_value_id: item.id,
                    task_type: formData.task_type,
                    audit_plan_id: $route.query.audit_plan_id,
                    audit_id: $route.query.audit_id,
                    from: $route.query.from,
                    parent_task_id: $route.params.id
                  }
                }"
              >
                <v-icon color="green">mdi-link</v-icon>
              </router-link>
            </template>
          </template>
          <template v-slot:foot>
            <tfoot>
              <tr>
                <td style="text-align: right">
                  <strong>Total</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  {{ accountingFormat(stockValueSumProductValue) }}
                </td>
                <td>
                  {{ accountingFormat(stockValueSumActualProductValue) }}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tfoot>
          </template>
        </app-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialogStockValue" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="warehouse"
                  type="text"
                  :label="$t('fields.warehouse')"
                  v-model="editedStockValue.warehouse"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  v-model="editedStockValue.branch"
                  name="branch"
                  type="select-server"
                  :label="$t('fields.branch')"
                  :view="view"
                  :binds="{
                    apiUrl: `contact/contact-branch/?active=true&contact_id=${formData.contact_id.id}`
                  }"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  v-model="editedStockValue.business_unit"
                  name="business_unit"
                  type="select-server"
                  :label="$t('fields.bu')"
                  :view="view"
                  :binds="{
                    apiUrl: `contact/business-unit/?active=true&contact_id=${formData.contact_id.id}`
                  }"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="product_value"
                  type="number"
                  :label="$t('fields.productValue')"
                  v-model="editedStockValue.product_value"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="actual_product_value"
                  type="number"
                  :label="$t('fields.actual')"
                  v-model="editedStockValue.actual_product_value"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="note"
                  type="text"
                  :label="$t('fields.note')"
                  v-model="editedStockValue.note"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  v-model="editedStockValue.status"
                  name="status"
                  type="select-server"
                  :label="$t('fields.status')"
                  :view="view"
                  :binds="{
                    apiUrl: 'audit/task-stock-value-outstanding-status'
                  }"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  v-model="editedStockValue.dummy"
                  name="dummy"
                  type="switch"
                  :label="$t('fields.dummy')"
                  :view="view"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCloseStockValue">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onSaveStockValue">
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogStockValueImport" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <app-xlsx-import @change="onStockValueImportFileChange" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <v-btn color="blue darken-1" text :href="stockValueTemplateUrl">
                  Download Template
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                {{ tempStockValueImportStatus }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCloseImportStockValue">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onImportStockValueDo">
            {{ $t('btn.import') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogStockValueBias" max-width="700px">
      <v-card>
        <v-toolbar flat>
          <slot name="actions">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              v-show="mode === 'edit'"
              @click="onEditStockValueBiasCondition()"
            >
              {{ $t('btn.newItem') }}
            </v-btn>
          </slot>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <app-table
                  server-side
                  model="task"
                  app="audit"
                  :is-selecteable="false"
                  :headers="stockValueBiasHeaders"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :server-items="stockValueBiasItemItems"
                  :server-items-length="stockValueBiasItemServerItemsLength"
                  @server="getStockValueBiasItems"
                  @edit="onEditStockValueBiasCondition"
                  @delete="onDeleteStockValueBiasCondition"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onStockValueBiasClose">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onStockValueBiasSave">
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogStockValueBiasCondition" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="field"
                  rules="required"
                  type="select-server"
                  :label="$t('fields.field')"
                  :view="view"
                  :binds="{
                    apiUrl: 'audit/task-stock-value-outstanding-bias-field/'
                  }"
                  v-model="editedStockValueBiasCondition.field"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="operator"
                  rules="required"
                  type="select-server"
                  :label="$t('fields.operator')"
                  :view="view"
                  :binds="{
                    apiUrl: 'audit/task-stock-value-outstanding-bias-operator/'
                  }"
                  v-model="editedStockValueBiasCondition.operator"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5">
                <app-input
                  name="value1"
                  type="text"
                  :label="$t('fields.value1')"
                  :view="view"
                  v-model="editedStockValueBiasCondition.value1"
                />
              </v-col>
              <v-col cols="5">
                <app-input
                  name="value2"
                  type="text"
                  :label="$t('fields.value2')"
                  :disabled="
                    editedStockValueBiasCondition.operator !== 'Between' &&
                    editedStockValueBiasCondition.operator !== 'Not Between'
                  "
                  :rules="{
                    required: (value) => {
                      if (
                        editedStockValueBiasCondition.operator !== 'Between' &&
                        editedStockValueBiasCondition.operator !== 'Not Between'
                      )
                        return true
                      if (value) return true

                      return 'You must enter a value2.'
                    }
                  }"
                  v-model="editedStockValueBiasCondition.value2"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="reason"
                  type="text"
                  :label="$t('fields.reason')"
                  :view="view"
                  v-model="editedStockValueBiasCondition.reason"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="onStockValueBiasConditionClose"
          >
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="onStockValueBiasConditionSave"
          >
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogStockValueNonBias" max-width="600px">
      <v-card>
        <v-toolbar flat>
          <slot name="actions">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              v-show="mode === 'edit'"
              @click="onEditStockValueNonBiasCondition()"
            >
              {{ $t('btn.newItem') }}
            </v-btn>
          </slot>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <app-table
                  server-side
                  model="task"
                  app="audit"
                  :headers="stockValueNonBiasHeaders"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :is-selecteable="false"
                  :server-items="stockValueNonBiasItemItems"
                  :server-items-length="stockValueNonBiasItemServerItemsLength"
                  @server="getStockValueNonBiasItems"
                  @edit="onEditStockValueNonBiasCondition"
                  @delete="onDeleteStockValueNonBiasCondition"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onStockValueNonBiasClose">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onStockValueNonBiasSave">
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogStockValueNonBiasCondition" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="sampling_size"
                  rules="required"
                  type="number"
                  :label="$t('fields.samplingSize')"
                  :view="view"
                  v-model="editedStockValueNonBiasCondition.sampling_size"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="sampling_method"
                  rules="required"
                  type="select-server"
                  :label="$t('fields.method')"
                  :view="view"
                  :binds="{
                    apiUrl:
                      'audit/task-stock-value-outstanding-non-bias-sampling-method/'
                  }"
                  v-model="editedStockValueNonBiasCondition.sampling_method"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="onStockValueNonBiasConditionClose"
          >
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="onStockValueNonBiasConditionSave"
          >
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppTable from '@components/AppTable.vue'
import AppInput from '@components/AppInput.vue'
import AppXlsxImport from '@components/AppXlsxImport.vue'
import Papa from 'papaparse'
import readXlsxFile from 'read-excel-file'
import { defaultTableParams } from '@utils/app-table'
import _ from 'lodash'
import { accounting } from '@utils/number-format'

export default {
  name: 'app-stock-value-outstanding',
  components: {
    AppTable,
    AppInput,
    AppXlsxImport
  },
  props: {
    mode: {
      type: String,
      required: true
    },
    view: {
      type: Boolean
    },
    formData: {
      type: Object
    }
  },
  data() {
    return {
      stockValueSelected: [],
      editedStockValue: [],
      editedStockValueBiasCondition: [],
      editedStockValueNonBiasCondition: [],
      dialogStockValue: false,
      dialogStockValueImport: false,
      dialogStockValueBias: false,
      dialogStockValueBiasCondition: false,
      dialogStockValueNonBias: false,
      dialogStockValueNonBiasCondition: false,
      stockValueItemItems: [],
      stockValueItemServerItemsLength: 0,
      tempStockValueImports: [],
      stockValueImportForm: {},
      stockValueBiasItemItems: [],
      stockValueBiasItemServerItemsLength: 0,
      stockValueNonBiasItemItems: [],
      stockValueNonBiasItemServerItemsLength: 0,
      stockValueSumProductValue: 0,
      stockValueSumActualProductValue: 0,
      loading: false
    }
  },
  computed: {
    stockValueHeaders() {
      return [
        {
          text: this.$t('fields.warehouse'),
          value: 'warehouse'
        },
        {
          text: this.$t('fields.branch'),
          value: 'branch.name'
        },
        {
          text: this.$t('fields.bu'),
          value: 'business_unit.name'
        },
        {
          text: this.$t('fields.productValue'),
          value: 'product_value',
          accountFormat: true
        },
        {
          text: this.$t('fields.actual'),
          value: 'actual_product_value',
          accountFormat: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note'
        },
        {
          text: this.$t('fields.status'),
          value: 'status'
        },
        {
          text: this.$t('fields.samplingType'),
          value: 'sampling_type'
        },
        {
          text: this.$t('fields.dummy'),
          value: 'dummy',
          check: true,
          filterMode: 'exact',
          filterType: 'boolean'
        },
        {
          text: 'Subtask',
          value: 'subtask',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    tempStockValueImportStatus() {
      if (!this.tempStockValueImports || !this.tempStockValueImports.length) {
        return null
      }
      return `${this.tempStockValueImports.length} records will be imported`
    },
    stockValueTemplateUrl() {
      return `${process.env.VUE_APP_BASE_API}static/template/STOCK%20VALUE%20OUTSTANDING%20Template.xlsx`
    },
    stockValueBiasHeaders() {
      return [
        {
          text: this.$t('fields.field'),
          value: 'field',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.operator'),
          value: 'operator',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.value1'),
          value: 'value1',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.value2'),
          value: 'value2',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.reason'),
          value: 'reason',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    stockValueNonBiasHeaders() {
      return [
        {
          text: this.$t('fields.samplingSize'),
          value: 'sampling_size',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.method'),
          value: 'sampling_method',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    }
  },
  methods: {
    accountingFormat: accounting,
    async getStockValue(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/stock-value-outstanding/`,
          params: params,
          hideSuccessAlert: true
        })
        this.stockValueItemItems = data.results
        this.stockValueItemServerItemsLength = data.count
        this.stockValueSumProductValue = data.sum_product_value ?? 0
        this.stockValueSumActualProductValue =
          data.sum_actual_product_value ?? 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditStockValue(item) {
      this.editedStockValue = Object.assign({}, item)
      this.dialogStockValue = true
    },
    onCloseStockValue() {
      this.dialogStockValue = false
      this.$nextTick(() => {
        this.editedStockValue = {}
      })
      this.loading = false
    },
    onDeleteStockValue(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/stock-value-outstanding/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.stockValueSelected = []
          this.getStockValue()
        })
      })
      this.loading = false
    },
    async onSaveStockValue() {
      try {
        this.loading = true
        const data = {
          warehouse: this.editedStockValue.warehouse,
          branch:
            this.editedStockValue.branch?.id ?? this.editedStockValue.branch,
          business_unit:
            this.editedStockValue.business_unit?.id ??
            this.editedStockValue.business_unit,
          product_value: this.editedStockValue.product_value,
          actual_product_value: this.editedStockValue.actual_product_value,
          note: this.editedStockValue.note,
          status: this.editedStockValue.status,
          sampling_type: this.editedStockValue.sampling_type,
          dummy: this.editedStockValue.dummy
        }

        if (this.editedStockValue.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/stock-value-outstanding/${this.editedStockValue.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/stock-value-outstanding/`,
            data
          })
        }
        this.onCloseStockValue()
        await this.getStockValue()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onImportStockValue() {
      this.stockValueImportForm = {}
      this.dialogStockValueImport = true
    },
    onStockValueImportFileChange(val) {
      if (val) {
        if (val.type === 'text/csv') {
          this.tempStockValueImports = []
          Papa.parse(val, {
            complete: (results) => {
              for (let i = 1; i < results.data.length; i++) {
                const row = results.data[i]
                if (row.length !== 8) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 8. It has ${row.length} columns.`
                  })
                  return
                }
                this.tempStockValueImports.push({
                  warehouse: row[0],
                  branch: row[1],
                  business_unit: row[2],
                  product_value: row[3]?.trim().replace(/[,-]/g, ''),
                  actual_product_value: row[4]?.trim().replace(/[,-]/g, ''),
                  note: row[5],
                  status: row[6]?.trim(),
                  sampling_type: null,
                  dummy: row[7]
                })
              }
            }
          })
        } else {
          this.tempStockValueImports = []
          let i = 0
          readXlsxFile(val)
            .then((rows) => {
              for (i = 1; i < rows.length; i++) {
                if (rows[i].length !== 8) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 8. It has ${rows[i].length} columns.`
                  })
                  return
                }
                const row = rows[i]
                this.tempStockValueImports.push({
                  warehouse: row[0],
                  branch: row[1],
                  business_unit: row[2],
                  product_value: row[3],
                  actual_product_value: row[4],
                  note: row[5],
                  status: row[6],
                  sampling_type: null,
                  dummy: row[7]
                })
              }
            })
            .catch((e) => {
              this.$alert('custom', {
                icon: 'error',
                title: this.$t('alert.validationError'),
                text: e.message
              })
            })
        }
      }
    },
    async onImportStockValueDo() {
      try {
        this.loading = true
        const data = this.tempStockValueImports
        await this.$api({
          method: 'post',
          url: `audit/task/${this.$route.params.id}/stock-value-outstanding/import_xlsx/`,
          data,
          hideErrorAlert: true
        })

        this.onCloseImportStockValue()
        await this.getStockValue()
      } catch (e) {
        console.log(e)
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: _.truncate(
            _.filter(
              _.map(e.data, (row, i) =>
                _.map(row, (value, key) => `Row ${i + 1} ${key}: ${value}\n`)
              ),
              (row) => row.length > 0
            ),
            { length: 100 }
          )
        })
      } finally {
        this.loading = false
      }
    },
    onCloseImportStockValue() {
      this.stockValueImportForm = {}
      this.dialogStockValueImport = false
    },
    onStockValueBiasClick() {
      this.dialogStockValueBias = true
    },
    async getStockValueBiasItems(options) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/stock-value-outstanding-bias/`,
          params: params,
          hideSuccessAlert: true
        })
        this.stockValueBiasItemItems = data.results
        this.stockValueBiasItemServerItemsLength = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditStockValueBiasCondition(item) {
      this.editedStockValueBiasCondition = Object.assign({}, item)
      this.dialogStockValueBiasCondition = true
    },
    async onDeleteStockValueBiasCondition(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/stock-value-outstanding-bias/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getStockValueBiasItems()
        })
      })
      this.loading = false
    },
    onStockValueBiasConditionClose() {
      this.dialogStockValueBiasCondition = false
    },
    async onStockValueBiasConditionSave() {
      try {
        this.loading = true
        const data = {
          field: this.editedStockValueBiasCondition.field,
          operator: this.editedStockValueBiasCondition.operator,
          value1: this.editedStockValueBiasCondition.value1,
          reason: this.editedStockValueBiasCondition.reason
        }
        if (this.editedStockValueBiasCondition.operator === 'Between') {
          data.value2 = this.editedStockValueBiasCondition.value2
        }
        if (this.editedStockValueBiasCondition.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/stock-value-outstanding-bias/${this.editedStockValueBiasCondition.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/stock-value-outstanding-bias/`,
            data
          })
        }
        this.onStockValueBiasConditionClose()
        await this.getStockValueBiasItems()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onStockValueBiasClose() {
      this.dialogStockValueBias = false
    },
    async onStockValueBiasSave() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear all Sampling Type on Stock Value Outstanding. You must also run Non-bias again after this. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/stock-value-outstanding/compute_bias/`,
            data: {}
          })
          this.onStockValueBiasClose()
          await this.getStockValue()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    onStockValueNonBiasClick() {
      this.dialogStockValueNonBias = true
    },
    async getStockValueNonBiasItems(options) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/stock-value-outstanding-non-bias/`,
          params: params,
          hideSuccessAlert: true
        })
        this.stockValueNonBiasItemItems = data.results
        this.stockValueNonBiasItemServerItemsLength = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditStockValueNonBiasCondition(item) {
      this.editedStockValueNonBiasCondition = Object.assign({}, item)
      this.dialogStockValueNonBiasCondition = true
    },
    async onDeleteStockValueNonBiasCondition(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/stock-value-outstanding-non-bias/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getStockValueNonBiasItems()
        })
      })
      this.loading = false
    },
    onStockValueNonBiasConditionClose() {
      this.dialogStockValueNonBiasCondition = false
    },
    async onStockValueNonBiasConditionSave() {
      try {
        this.loading = true
        const data = {
          sampling_size: this.editedStockValueNonBiasCondition.sampling_size,
          sampling_method: this.editedStockValueNonBiasCondition.sampling_method
        }

        if (this.editedStockValueNonBiasCondition.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/stock-value-outstanding-non-bias/${this.editedStockValueNonBiasCondition.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/stock-value-outstanding-non-bias/`,
            data
          })
        }

        this.onStockValueNonBiasConditionClose()
        await this.getStockValueNonBiasItems()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onStockValueNonBiasClose() {
      this.dialogStockValueNonBias = false
    },
    async onStockValueNonBiasSave() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear Non-Bias Sampling Type on Stock Value Outstanding and re-assign them. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/stock-value-outstanding/compute_non_bias/`,
            data: {}
          })
          this.onStockValueNonBiasClose()
          await this.getStockValue()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    async onResetStockValueBiasClick() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear all Sampling Type on Stock Value Outstanding. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/stock-value-outstanding/reset/`,
            data: {}
          })
          await this.getStockValue()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>
