<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Combined In and Out</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-btn
        color="primary"
        dark
        v-show="mode === 'edit'"
        @click="onCombinedInOutBiasClick"
        >Bias
      </v-btn>
      <v-btn
        color="primary"
        dark
        v-show="mode === 'edit'"
        @click="onCombinedInOutNonBiasClick"
        >Non-bias
      </v-btn>
      <v-btn
        color="error"
        dark
        v-show="mode === 'edit'"
        @click="onResetCombinedInOutBiasClick"
        >Reset
      </v-btn>
      <v-spacer></v-spacer>
      <slot name="actions">
        <slot name="prefix-actions" />
        <v-btn
          color="primary"
          dark
          v-show="mode === 'edit'"
          @click="onEditCombinedInOut"
        >
          {{ $t('btn.newItem') }}
        </v-btn>
        <v-btn
          color="primary"
          dark
          v-show="mode === 'edit'"
          @click="onImportCombinedInOut()"
        >
          {{ $t('btn.import') }}
        </v-btn>
        <v-btn
          v-show="combinedInOutSelected.length && mode === 'edit'"
          color="error"
          @click="onDeleteCombinedInOut(combinedInOutSelected)"
        >
          {{ $t('btn.delete') }}
        </v-btn>
      </slot>
    </v-toolbar>
    <v-dialog v-model="dialogCombinedInOut" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="product_code"
                  type="text"
                  :label="$t('fields.productCode')"
                  v-model="editingCombinedInOut.product_code"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="product_name"
                  type="text"
                  :label="$t('fields.productName')"
                  v-model="editingCombinedInOut.product_name"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="product_barcode"
                  type="text"
                  :label="$t('fields.productBarcode')"
                  v-model="editingCombinedInOut.product_barcode"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="product_category"
                  type="text"
                  :label="$t('fields.productCategory')"
                  v-model="editingCombinedInOut.product_category"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="product_unit"
                  type="text"
                  :label="$t('fields.productUnit')"
                  v-model="editingCombinedInOut.product_unit"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="doc_no"
                  type="text"
                  :label="$t('fields.docNo')"
                  v-model="editingCombinedInOut.doc_no"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="doc_date"
                  type="datepicker"
                  :label="$t('fields.docDate')"
                  v-model="editingCombinedInOut.doc_date"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="doc_description"
                  type="text"
                  :label="$t('fields.docDescription')"
                  v-model="editingCombinedInOut.doc_description"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="vendor_code"
                  type="text"
                  :label="$t('fields.vendorCode')"
                  v-model="editingCombinedInOut.vendor_code"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="vendor_name"
                  type="text"
                  :label="$t('fields.vendorName')"
                  v-model="editingCombinedInOut.vendor_name"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="vendor_branch"
                  type="text"
                  :label="$t('fields.vendorBranch')"
                  v-model="editingCombinedInOut.vendor_branch"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="product_price"
                  type="number"
                  :label="$t('fields.productPrice')"
                  v-model="editingCombinedInOut.product_price"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="product_qty"
                  type="number"
                  :label="$t('fields.productQty')"
                  v-model="editingCombinedInOut.product_qty"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="product_value"
                  type="number"
                  :label="$t('fields.productValue')"
                  v-model="editingCombinedInOut.product_value"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="initial_value"
                  type="number"
                  :label="$t('fields.initialValue')"
                  v-model="editingCombinedInOut.initial_value"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="actual_product_price"
                  type="number"
                  :label="$t('fields.actualProductPrice')"
                  v-model="editingCombinedInOut.actual_product_price"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="actual_product_qty"
                  type="number"
                  :label="$t('fields.actualProductQty')"
                  v-model="editingCombinedInOut.actual_product_qty"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="actual_product_value"
                  type="number"
                  :label="$t('fields.actualProductValue')"
                  v-model="editingCombinedInOut.actual_product_value"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="actual_initial_value"
                  type="number"
                  :label="$t('fields.actualInitialValue')"
                  v-model="editingCombinedInOut.actual_initial_value"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="warehouse"
                  type="text"
                  :label="$t('fields.warehouse')"
                  v-model="editingCombinedInOut.warehouse"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="branch"
                  type="text"
                  :label="$t('fields.branch')"
                  v-model="editingCombinedInOut.branch"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  v-model="editingCombinedInOut.business_unit"
                  name="business_unit"
                  type="select-server"
                  :label="$t('fields.businessUnit')"
                  :view="view"
                  :binds="{
                    apiUrl: `contact/business-unit/?active=true&contact_id=${formData.contact_id.id}`
                  }"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="note"
                  type="text"
                  :label="$t('fields.note')"
                  v-model="editingCombinedInOut.note"
                  :view="view"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  v-model="editingCombinedInOut.status"
                  name="status"
                  type="select-server"
                  :label="$t('fields.status')"
                  :view="view"
                  :binds="{
                    apiUrl: 'audit/task-debit-status/'
                  }"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  v-model="editingCombinedInOut.dummy"
                  name="dummy"
                  type="switch"
                  :label="$t('fields.dummy')"
                  :view="view"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCloseCombinedInOut">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onSaveCombinedInOut">
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCombinedInOutImport" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <app-xlsx-import @change="onCombinedInOutImportFileChange" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <v-btn
                  color="blue darken-1"
                  text
                  :href="combinedInOutTemplateUrl"
                >
                  Download Template
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                {{ tempCombinedInOutImportStatus }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCloseImportCombinedInOut">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onImportCombinedInOutDo">
            {{ $t('btn.import') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCombinedInOutBias" max-width="700px">
      <v-card>
        <v-toolbar flat>
          <slot name="actions">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              v-show="mode === 'edit'"
              @click="onEditCombinedInOutBias()"
            >
              {{ $t('btn.newItem') }}
            </v-btn>
          </slot>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <app-table
                  server-side
                  model="task"
                  app="audit"
                  :headers="combinedInOutBiasHeaders"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :is-selecteable="true"
                  :server-items="combinedInOutBiases"
                  :server-items-length="combinedInOutBiasesCount"
                  @server="getCombinedInOutBiases"
                  @edit="onEditCombinedInOutBias"
                  @delete="onDeleteCombinedInOutBias"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCombinedInOutBiasClose">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onCombinedInOutBiasSave">
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditCombinedInOutBias" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="field"
                  rules="required"
                  type="select-server"
                  :label="$t('fields.field')"
                  :view="view"
                  :binds="{
                    apiUrl: 'audit/task-combined-in-out-bias-field/'
                  }"
                  v-model="editingCombinedInOutBias.field"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="operator"
                  rules="required"
                  type="select-server"
                  :label="$t('fields.operator')"
                  :view="view"
                  :binds="{
                    apiUrl: 'audit/task-debit-bias-operator/'
                  }"
                  v-model="editingCombinedInOutBias.operator"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5">
                <app-input
                  name="value1"
                  type="text"
                  :label="$t('fields.value1')"
                  :view="view"
                  v-model="editingCombinedInOutBias.value1"
                />
              </v-col>
              <v-col cols="5">
                <app-input
                  name="value2"
                  type="text"
                  :label="$t('fields.value2')"
                  :disabled="
                    editingCombinedInOutBias.operator !== 'Between' &&
                    editingCombinedInOutBias.operator !== 'Not Between'
                  "
                  :rules="{
                    required: (value) => {
                      if (
                        editingCombinedInOutBias.operator !== 'Between' &&
                        editingCombinedInOutBias.operator !== 'Not Between'
                      )
                        return true
                      if (value) return true

                      return 'You must enter a value2.'
                    }
                  }"
                  v-model="editingCombinedInOutBias.value2"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="reason"
                  type="text"
                  :label="$t('fields.reason')"
                  :view="view"
                  v-model="editingCombinedInOutBias.reason"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="onEditCombinedInOutBiasClose"
          >
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="onEditCombinedInOutBiasSave"
          >
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCombinedInOutNonBias" max-width="600px">
      <v-card>
        <v-toolbar flat>
          <slot name="actions">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              v-show="mode === 'edit'"
              @click="onEditCombinedInOutNonBias"
            >
              {{ $t('btn.newItem') }}
            </v-btn>
          </slot>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <app-table
                  server-side
                  model="task"
                  app="audit"
                  :headers="combinedInOutNonBiasHeaders"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :is-selecteable="true"
                  :server-items="combinedInOutNonBiases"
                  :server-items-length="combinedInOutNonBiasesCount"
                  @server="getCombinedInOutNonBiases"
                  @edit="onEditCombinedInOutNonBias"
                  @delete="onDeleteCombinedInOutNonBias"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="onCombinedInOutNonBiasClose"
          >
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onCombinedInOutNonBiasSave">
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditCombinedInOutNonBias" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="sampling_size"
                  rules="required"
                  type="number"
                  :label="$t('fields.samplingSize')"
                  :view="view"
                  v-model="editingCombinedInOutNonBias.sampling_size"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <app-input
                  name="sampling_method"
                  rules="required"
                  type="select-server"
                  :label="$t('fields.samplingMethod')"
                  :view="view"
                  :binds="{
                    apiUrl: 'audit/task-debit-non-bias-sampling-method/'
                  }"
                  v-model="editingCombinedInOutNonBias.sampling_method"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="onEditCombinedInOutNonBiasClose"
          >
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="onEditCombinedInOutNonBiasSave"
          >
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card flat>
      <v-card-text>
        <app-table
          server-side
          model="task"
          app="audit"
          :headers="combinedInOutHeaders"
          :hide-edit="mode !== 'edit'"
          :hide-delete="mode !== 'edit'"
          :is-selecteable="true"
          v-model="combinedInOutSelected"
          :server-items="combinedInOuts"
          :server-items-length="combinedInOutsCount"
          @server="getCombinedInOuts"
          @edit="onEditCombinedInOut"
          @delete="onDeleteCombinedInOut"
        >
          <template v-slot:item.subtask="{ item }">
            <template v-if="item.subtask">
              <router-link
                :to="{
                  name: 'taskEdit',
                  params: { id: item.subtask.id },
                  query: {
                    task_combined_in_out_id: item.id,
                    task_type: 'DCD',
                    audit_plan_id: $route.query.audit_plan_id,
                    audit_id: $route.query.audit_id,
                    from: $route.query.from
                  }
                }"
                target="_blank"
              >
                <v-icon color="primary">mdi-link</v-icon>
              </router-link>
            </template>
            <template v-else>
              <router-link
                :to="{
                  name: 'taskCreate',
                  query: {
                    task_combined_in_out_id: item.id,
                    task_type: 'DCD',
                    audit_plan_id: $route.query.audit_plan_id,
                    audit_id: $route.query.audit_id,
                    from: $route.query.from,
                    parent_task_id: $route.params.id
                  }
                }"
              >
                <v-icon color="green">mdi-link</v-icon>
              </router-link>
            </template>
          </template>
          <template v-slot:foot>
            <tfoot>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style="text-align: right">
                  <strong>Total</strong>
                </td>
                <td></td>
                <td>
                  {{ accountingFormat(combinedInOutsSumProductValue) }}
                </td>
                <td>
                  {{ accountingFormat(combinedInOutsSumInitialValue) }}
                </td>
                <td></td>
                <td></td>
                <td>
                  {{ accountingFormat(combinedInOutsSumActualProductValue) }}
                </td>
                <td>
                  {{ accountingFormat(combinedInOutsSumActualInitialValue) }}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tfoot>
          </template>
        </app-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import AppTable from '@components/AppTable.vue'
import AppInput from '@components/AppInput.vue'
import AppXlsxImport from '@components/AppXlsxImport.vue'
import Papa from 'papaparse'
import readXlsxFile from 'read-excel-file'
import { defaultTableParams } from '@utils/app-table'
import _ from 'lodash'
import { accounting } from '@utils/number-format'
import { DateTime } from 'luxon'

export default {
  name: 'app-combined-in-out',
  components: {
    AppTable,
    AppInput,
    AppXlsxImport
  },
  props: {
    mode: {
      type: String,
      required: true
    },
    view: {
      type: Boolean
    },
    formData: {
      type: Object
    }
  },
  data() {
    return {
      dialogCombinedInOut: false,
      combinedInOuts: [],
      combinedInOutsCount: 0,
      combinedInOutSelected: [],
      combinedInOutsSumProductValue: 0,
      combinedInOutsSumInitialValue: 0,
      combinedInOutsSumActualProductValue: 0,
      combinedInOutsSumActualInitialValue: 0,
      editingCombinedInOut: {},
      dialogCombinedInOutImport: false,
      combinedInOutImportForm: {},
      tempCombinedInOutImports: [],
      combinedInOutBiases: [],
      combinedInOutBiasesCount: 0,
      dialogCombinedInOutBias: false,
      dialogEditCombinedInOutBias: false,
      editingCombinedInOutBias: {},
      dialogEditCombinedInOutNonBias: false,
      combinedInOutNonBiases: [],
      combinedInOutNonBiasesCount: 0,
      dialogCombinedInOutNonBias: false,
      editingCombinedInOutNonBias: {},
      loading: false
    }
  },
  computed: {
    combinedInOutHeaders() {
      return [
        {
          text: this.$t('fields.productCode'),
          value: 'product_code'
        },
        {
          text: this.$t('fields.productName'),
          value: 'product_name'
        },
        {
          text: this.$t('fields.productBarcode'),
          value: 'product_barcode'
        },
        {
          text: this.$t('fields.productCategory'),
          value: 'product_category'
        },
        {
          text: this.$t('fields.productUnit'),
          value: 'product_unit'
        },
        {
          text: this.$t('fields.docNo'),
          value: 'doc_no'
        },
        {
          text: this.$t('fields.docDate'),
          value: 'doc_date'
        },
        {
          text: this.$t('fields.docDescription'),
          value: 'doc_description'
        },
        {
          text: this.$t('fields.vendorCode'),
          value: 'vendor_code'
        },
        {
          text: this.$t('fields.vendorName'),
          value: 'vendor_name'
        },
        {
          text: this.$t('fields.vendorBranch'),
          value: 'vendor_branch'
        },
        {
          text: this.$t('fields.productPrice'),
          value: 'product_price',
          accountFormat: true
        },
        {
          text: this.$t('fields.productQty'),
          value: 'product_qty',
          accountFormat: true
        },
        {
          text: this.$t('fields.productValue'),
          value: 'product_value',
          accountFormat: true
        },
        {
          text: this.$t('fields.initialValue'),
          value: 'initial_value',
          accountFormat: true
        },
        {
          text: this.$t('fields.actualProductPrice'),
          value: 'actual_product_price',
          accountFormat: true
        },
        {
          text: this.$t('fields.actualProductQty'),
          value: 'actual_product_qty',
          accountFormat: true
        },
        {
          text: this.$t('fields.actualProductValue'),
          value: 'actual_product_value',
          accountFormat: true
        },
        {
          text: this.$t('fields.actualInitialValue'),
          value: 'actual_initial_value',
          accountFormat: true
        },
        {
          text: this.$t('fields.warehouse'),
          value: 'warehouse'
        },
        {
          text: this.$t('fields.branch'),
          value: 'branch'
        },
        {
          text: this.$t('fields.businessUnit'),
          value: 'business_unit.name'
        },
        {
          text: this.$t('fields.note'),
          value: 'note'
        },
        {
          text: this.$t('fields.status'),
          value: 'status'
        },
        {
          text: this.$t('fields.samplingType'),
          value: 'sampling_type'
        },
        {
          text: this.$t('fields.dummy'),
          value: 'dummy',
          check: true,
          filterMode: 'exact',
          filterType: 'boolean'
        },
        {
          text: 'Subtask',
          value: 'subtask',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    combinedInOutTemplateUrl() {
      return `${process.env.VUE_APP_BASE_API}static/template/COMBINED%20IN%20AND%20OUT%20Template.xlsx`
    },
    tempCombinedInOutImportStatus() {
      if (
        !this.tempCombinedInOutImports ||
        !this.tempCombinedInOutImports.length
      ) {
        return null
      }
      return `${this.tempCombinedInOutImports.length} records will be imported`
    },
    combinedInOutBiasHeaders() {
      return [
        {
          text: this.$t('fields.field'),
          value: 'field',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.operator'),
          value: 'operator',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.value1'),
          value: 'value1',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.value2'),
          value: 'value2',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.reason'),
          value: 'reason',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    combinedInOutNonBiasHeaders() {
      return [
        {
          text: this.$t('fields.samplingSize'),
          value: 'sampling_size',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.samplingMethod'),
          value: 'sampling_method',
          sortable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    }
  },
  methods: {
    accountingFormat: accounting,
    async getCombinedInOuts(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/combined-in-out/`,
          params: params,
          hideSuccessAlert: true
        })
        this.combinedInOuts = data.results
        this.combinedInOutsCount = data.count
        this.combinedInOutsSumProductValue = data.sum_product_value ?? 0
        this.combinedInOutsSumInitialValue = data.sum_initial_value ?? 0
        this.combinedInOutsSumActualProductValue =
          data.sum_actual_product_value ?? 0
        this.combinedInOutsSumActualInitialValue =
          data.sum_actual_initial_value ?? 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditCombinedInOut(item) {
      this.editingCombinedInOut = Object.assign({}, item)
      this.dialogCombinedInOut = true
    },
    onCloseCombinedInOut() {
      this.dialogCombinedInOut = false
      this.$nextTick(() => {
        this.editingCombinedInOut = {}
      })
      this.loading = false
    },
    onDeleteCombinedInOut(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/combined-in-out/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.combinedInOutSelected = []
          this.getCombinedInOuts()
        })
      })
      this.loading = false
    },
    async onSaveCombinedInOut() {
      try {
        this.loading = true
        const data = {
          product_code: this.editingCombinedInOut.product_code,
          product_name: this.editingCombinedInOut.product_name,
          product_barcode: this.editingCombinedInOut.product_barcode,
          product_category: this.editingCombinedInOut.product_category,
          product_unit: this.editingCombinedInOut.product_unit,
          doc_no: this.editingCombinedInOut.doc_no,
          doc_date: this.editingCombinedInOut.doc_date,
          doc_description: this.editingCombinedInOut.doc_description,
          vendor_code: this.editingCombinedInOut.vendor_code,
          vendor_name: this.editingCombinedInOut.vendor_name,
          vendor_branch: this.editingCombinedInOut.vendor_branch,
          product_price: this.editingCombinedInOut.product_price,
          product_qty: this.editingCombinedInOut.product_qty,
          product_value: this.editingCombinedInOut.product_value,
          initial_value: this.editingCombinedInOut.initial_value,
          actual_product_price: this.editingCombinedInOut.actual_product_price,
          actual_product_qty: this.editingCombinedInOut.actual_product_qty,
          actual_product_value: this.editingCombinedInOut.actual_product_value,
          actual_initial_value: this.editingCombinedInOut.actual_initial_value,
          warehouse: this.editingCombinedInOut.warehouse,
          branch: this.editingCombinedInOut.branch,
          note: this.editingCombinedInOut.note,
          business_unit:
            this.editingCombinedInOut.business_unit?.id ??
            this.editingCombinedInOut.business_unit,
          status: this.editingCombinedInOut.status,
          sampling_type: this.editingCombinedInOut.sampling_type,
          dummy: this.editingCombinedInOut.dummy
        }

        if (this.editingCombinedInOut.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/combined-in-out/${this.editingCombinedInOut.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/combined-in-out/`,
            data
          })
        }

        this.onCloseCombinedInOut()
        await this.getCombinedInOuts()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    onImportCombinedInOut() {
      this.debitSumImportForm = {}
      this.dialogCombinedInOutImport = true
    },
    onCloseImportCombinedInOut() {
      this.debitSumImportForm = {}
      this.dialogCombinedInOutImport = false
    },
    onCombinedInOutImportFileChange(val) {
      if (val) {
        if (val.type === 'text/csv') {
          this.tempCombinedInOutImports = []
          Papa.parse(val, {
            complete: (results) => {
              for (let i = 1; i < results.data.length; i++) {
                const row = results.data[i]
                if (row.length !== 21) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 21. It has ${row.length} columns.`
                  })
                  return
                }
                this.tempCombinedInOutImports.push({
                  product_code: row[0],
                  product_name: row[1],
                  product_barcode: row[2],
                  product_category: row[3],
                  product_unit: row[4],
                  doc_no: row[5],
                  doc_date: DateTime.fromFormat(row[6], 'dd/LL/yyyy').toFormat(
                    'yyyy-LL-dd'
                  ),
                  doc_description: row[7],
                  vendor_code: row[8],
                  vendor_name: row[9],
                  vendor_branch: row[10],
                  product_price: row[11]?.trim().replace(/[,-]/g, ''),
                  product_qty: row[12]?.trim().replace(/[,-]/g, ''),
                  product_value: row[13]?.trim().replace(/[,-]/g, ''),
                  initial_value: row[14]?.trim().replace(/[,-]/g, ''),
                  warehouse: row[15],
                  branch: row[16],
                  business_unit: row[17],
                  note: row[18],
                  status: row[19].trim(),
                  sampling_type: null,
                  dummy: row[20]
                })
              }
            }
          })
        } else {
          this.tempCombinedInOutImports = []
          let i = 0
          readXlsxFile(val)
            .then((rows) => {
              for (i = 1; i < rows.length; i++) {
                if (rows[i].length !== 21) {
                  this.$alert('custom', {
                    icon: 'error',
                    title: this.$t('alert.validationError'),
                    text: `Invalid file format. Column count must be 21. It has ${rows[i].length} columns.`
                  })
                  return
                }
                const row = rows[i]
                this.tempCombinedInOutImports.push({
                  product_code: row[0],
                  product_name: row[1],
                  product_barcode: row[2],
                  product_category: row[3],
                  product_unit: row[4],
                  doc_no: row[5],
                  doc_date: row[6]
                    ? DateTime.fromJSDate(row[6]).toFormat('yyyy-LL-dd')
                    : null,
                  doc_description: row[7],
                  vendor_code: row[8],
                  vendor_name: row[9],
                  vendor_branch: row[10],
                  product_price: row[11],
                  product_qty: row[12],
                  product_value: row[13],
                  initial_value: row[14],
                  warehouse: row[15],
                  branch: row[16],
                  business_unit: row[17],
                  note: row[18],
                  status: row[19].trim(),
                  sampling_type: null,
                  dummy: row[20]
                })
              }
            })
            .catch((e) => {
              this.$alert('custom', {
                icon: 'error',
                title: this.$t('alert.validationError'),
                text: e.message
              })
            })
        }
      }
    },
    async onImportCombinedInOutDo() {
      try {
        this.loading = true
        const data = this.tempCombinedInOutImports
        await this.$api({
          method: 'post',
          url: `audit/task/${this.$route.params.id}/combined-in-out/import_xlsx/`,
          data,
          hideErrorAlert: true
        })

        this.onCloseImportCombinedInOut()
        await this.getCombinedInOuts()
      } catch (e) {
        console.log(e)
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: _.truncate(
            _.filter(
              _.map(e.data, (row, i) =>
                _.map(row, (value, key) => `Row ${i + 1} ${key}: ${value}\n`)
              ),
              (row) => row.length > 0
            ),
            { length: 100 }
          )
        })
      } finally {
        this.loading = false
      }
    },

    async getCombinedInOutBiases(options) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/combined-in-out-bias/`,
          params: params,
          hideSuccessAlert: true
        })
        this.combinedInOutBiases = data.results
        this.combinedInOutBiasesCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onCombinedInOutBiasClick() {
      this.dialogCombinedInOutBias = true
    },
    onEditCombinedInOutBias(item) {
      this.editingCombinedInOutBias = Object.assign({}, item)
      this.dialogEditCombinedInOutBias = true
    },
    async onDeleteCombinedInOutBias(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/combined-in-out-bias/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getCombinedInOutBiases()
        })
      })
      this.loading = false
    },
    onCombinedInOutBiasClose() {
      this.dialogCombinedInOutBias = false
    },
    async onCombinedInOutBiasSave() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear all Sampling Type on Debit Detail. You must also run Non-bias again after this. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/combined-in-out/compute_bias/`,
            data: {}
          })
          this.onCombinedInOutBiasClose()
          await this.getCombinedInOuts()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    onEditCombinedInOutBiasClose() {
      this.dialogEditCombinedInOutBias = false
    },
    async onEditCombinedInOutBiasSave() {
      try {
        this.loading = true
        const data = {
          field: this.editingCombinedInOutBias.field,
          operator: this.editingCombinedInOutBias.operator,
          value1: this.editingCombinedInOutBias.value1,
          reason: this.editingCombinedInOutBias.reason
        }
        if (
          this.editingCombinedInOutBias.operator === 'Between' ||
          this.editingCombinedInOutBias.operator === 'Not Between'
        ) {
          data.value2 = this.editingCombinedInOutBias.value2
        }

        if (this.editingCombinedInOutBias.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/combined-in-out-bias/${this.editingCombinedInOutBias.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/combined-in-out-bias/`,
            data
          })
        }

        this.onEditCombinedInOutBiasClose()
        await this.getCombinedInOutBiases()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async onResetCombinedInOutBiasClick() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear all Sampling Type on Combined In and Out. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/combined-in-out/reset/`,
            data: {}
          })
          await this.getCombinedInOuts()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },

    async getCombinedInOutNonBiases(options) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/combined-in-out-non-bias/`,
          params: params,
          hideSuccessAlert: true
        })
        this.combinedInOutNonBiases = data.results
        this.combinedInOutNonBiasesCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onCombinedInOutNonBiasClick() {
      this.dialogCombinedInOutNonBias = true
    },
    onEditCombinedInOutNonBias(item) {
      this.editingCombinedInOutNonBias = Object.assign({}, item)
      this.dialogEditCombinedInOutNonBias = true
    },
    async onDeleteCombinedInOutNonBias(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/task/${this.$route.params.id}/combined-in-out-non-bias/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.getCombinedInOutNonBiases()
        })
      })
      this.loading = false
    },
    onCombinedInOutNonBiasClose() {
      this.dialogCombinedInOutNonBias = false
    },
    async onCombinedInOutNonBiasSave() {
      const result = await this.$alert('custom', {
        icon: 'warning',
        title: 'Confirmation',
        text: 'This will clear Non-Bias Sampling Type on Combined In and Out and re-assign them. Are you sure?',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.submitDelete.confirmButtonText')
      })
      if (result.isConfirmed) {
        try {
          this.loading = true
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/combined-in-out/compute_non_bias/`,
            data: {}
          })
          this.onCombinedInOutNonBiasClose()
          await this.getCombinedInOuts()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    onEditCombinedInOutNonBiasClose() {
      this.dialogEditCombinedInOutNonBias = false
    },
    async onEditCombinedInOutNonBiasSave() {
      try {
        this.loading = true
        const data = {
          sampling_size: this.editingCombinedInOutNonBias.sampling_size,
          sampling_method: this.editingCombinedInOutNonBias.sampling_method
        }

        if (this.editingCombinedInOutNonBias.id) {
          await this.$api({
            method: 'put',
            url: `audit/task/${this.$route.params.id}/combined-in-out-non-bias/${this.editingCombinedInOutNonBias.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `audit/task/${this.$route.params.id}/combined-in-out-non-bias/`,
            data
          })
        }

        this.onEditCombinedInOutNonBiasClose()
        await this.getCombinedInOutNonBiases()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
